import CreateCourseMatchModalForm from '@/components/CourseMatch/CreateModalForm';
import CreateLessonModalForm from '@/components/Lesson/CreateModalForm';
import OnboardingTODOs from '@/components/Modal/OnboardingTODOs';
import Notice from '@/components/Notice';
import { SelectTheme } from '@/components/RightContent';
import { AvatarDropdown, AvatarName } from '@/components/RightContent/AvatarDropdown';
import { logout, queryCurrentUser } from '@/services/user';
import dayjs from '@/utils/dayjs.js';
import { getErrorMessageFromResponse } from '@/utils/utils';
import { CopyrightOutlined, SmileOutlined } from '@ant-design/icons';
import { Avatar, Space, Tag, notification, FloatButton } from 'antd';
import lodash from 'lodash';
import { getDvaApp, history, useModel } from 'umi';
import defaultSettings from '../config/defaultSettings';
import publicPathnames from '../config/publicPathnames';
import packageJson from '../package.json';
import TimezoneConverter from './components/Global/TimezoneConverter';
import TeachersSalaryIncreaseFloatButton from './pages/SalaryIncreaseApproval/components/TeachersSalaryIncreaseFloatButton';
import ChangeForgottenPasswordModal from './pages/Login/components/ChangeForgottenPasswordModal';
import ConnectWechatQrCodeModal from './pages/Public/components/ConnectWechatQrCodeModal';
import DSTDatesDisplay from './components/Global/DSTDatesDisplay';
const loginPath = '/login';
const isDev = process.env.NODE_ENV === 'development';

function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

export async function getInitialState() {
  const fetchInitialState = async () => {
    try {
      const currentUser = await queryCurrentUser();
      const permissions = {};
      if (currentUser.permissions?.length) {
        currentUser.permissions.forEach((p) => {
          permissions[p.route] = permissions[p.route] || [];
          permissions[p.route].push(p.function);
        });
      }
      const currentUserRoles = currentUser.roles?.map?.((r) => r.name) || [];
      const teamMembers = [];
      if (currentUser.ownedTeams?.length) {
        currentUser.ownedTeams.forEach((team) => {
          if (team.members?.length) {
            team.members.forEach((m) => {
              teamMembers.push(m);
            });
          }
        });
      }
      if (currentUser.permissionScopes?.length) {
        currentUser.permissionScopes = lodash.groupBy(
          currentUser.permissionScopes,
          (item) => item.permission.route,
        );
        for (const keyItem of Object.keys(currentUser.permissionScopes)) {
          const valItem = currentUser.permissionScopes[keyItem];
          currentUser.permissionScopes[keyItem] = lodash.groupBy(
            valItem,
            (data) => data.permission.function,
          );
        }
      }

      /**
       * @param {Object} param0
       * @param {string} param0.route
       * @param {string} param0.function
       * @param {string} param0.scope
       * @returns {boolean}
       */
      function hasPermission({ route, function: func, scope }) {
        if (!currentUser.permissionScopes[route]) {
          return false;
        }
        const ps = currentUser.permissionScopes[route][func];
        if (!ps || !Array.isArray(ps)) {
          return false;
        }
        if (!scope) {
          return true;
        }
        return ps.some((el) => {
          if (!el.scope) {
            return false;
          }
          if (el.scope.name === 'all') {
            return true;
          }
          return el.scope.name === scope;
        });
      }
      return {
        currentUser,
        currentUserRoles,
        permissions,
        teamMembers,
        updatedAt: dayjs(),
        hasPermission: hasPermission,
      };
    } catch (error) {
      logout();
    }
  };
  const settings = {
    ...defaultSettings,
  };
  const navTheme = window.localStorage.getItem('navTheme');
  if (['realDark', 'light'].includes(navTheme)) {
    settings.navTheme = navTheme;
  }
  if (settings.navTheme === 'realDark') {
    settings.logo =
      'https://savvyuni-public.oss-accelerate.aliyuncs.com/logos/savvygroup/SavvyGroup%20Logo%20White.svg';
  } else {
    settings.logo =
      'https://savvyuni-public.oss-accelerate.aliyuncs.com/logos/savvygroup/SavvyGroup%20Logo.svg';
  }
  if (publicPathnames.includes(window.location.pathname)) {
    return {
      fetchInitialState,
      settings,
    };
  }
  const data = await fetchInitialState();
  return {
    ...data,
    fetchInitialState,
    settings,
  };
}

export const dva = {
  config: {
    onError(e) {
      notification.error(e.message, 3);
    },
  },
};

export function useQiankunStateForSlave() {
  const { initialState, loading, refresh, setInitialState } = useModel('@@initialState');
  return {
    masterState: initialState,
    setMasterState: setInitialState,
    masterStateLoading: loading,
    refreshMasterState: refresh,
  };
}

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

export const request = {
  errorConfig: {
    errorHandler(error) {
      const { response, config } = error;
      if (config.method === 'get' && config.url === '/api/notice/user') {
        return error;
      }
      if (config.method === 'get' && config.url === '/api/aigc/teacherFeedbackSuggestion') {
        return error;
      }
      if (!response) {
        notification.error({
          description: '您的网络发生异常，无法连接服务器',
          message: '网络异常',
        });
      } else if (!response.data) {
        notification.error({
          duration: 5,
          message: '请求失败',
          description: getErrorMessageFromResponse(response),
        });
      } else {
        const errorText = codeMessage[response.status] || response.statusText;
        const errorType = response.data.error;
        if (errorType === 'Unauthorized') {
          if (!publicPathnames.includes(window.location.pathname)) {
            const expiredToken = window.localStorage.getItem('token');
            if (!!expiredToken) {
              notification.warning({
                message: '您的登录状态已过期',
                description: '请重新登录',
              });
            }
            logout();
          }
        } else {
          notification.error({
            duration: 5,
            message: errorText,
            description: getErrorMessageFromResponse(response.data),
          });
        }
      }
      return error;
    },
  },
  requestInterceptors: [
    (config) => {
      const headers = config.headers || {};
      const params = config.params || {};
      let token = params.token;
      const code = getQueryVariable('code');
      if (code) {
        //把code存到localStorage
        window.localStorage.setItem('code', code);
        //跳转到/public/Kuakua
        window.location.href = '/public/kuakua';
      }
      if (!token) {
        headers.Authorization = `Bearer ${window.localStorage.getItem('token')}`;
      }
      const ip = window.localStorage.getItem('ip');
      if (ip) {
        headers.ip = ip;
      }
      return config;
    },
  ],
  // responseInterceptors: [
  //   (response) => {
  //     console.log(response);
  //     return response;
  //   },
  // ],
};

export const layout = ({ initialState, setInitialState }) => {
  if (initialState) {
    const { currentUser, currentUserRoles, permissions, teamMembers } = initialState;
    // TODO: Remove all dependencies on user model and delete this dispatch
    const dvaApp = getDvaApp();
    dvaApp?._store?.dispatch({
      type: 'user/save',
      payload: {
        currentUser,
        currentUserRoles,
        permissions,
        teamMembers,
      },
    });
  }

  return {
    onPageChange: () => {
      const { location } = history;
      // if (!initialState?.currentUser && location.pathname !== loginPath) {
      //   history.push(loginPath);
      // }
      if (!initialState?.currentUser && !['/login', '/public/kuakua'].includes(location.pathname)) {
        history.push(loginPath);
      }
    },
    menuFooterRender: () => {
      return (
        <div style={{ textAlign: 'center' }}>
          <Space direction="vertical" align="center">
            <div>
              v {packageJson.version}
              {isDev && <Tag color="orange">develop mode</Tag>}
            </div>
            <div>
              <CopyrightOutlined /> {new Date().getFullYear()}思睿教育集团技术部
            </div>
          </Space>
        </div>
      );
    },
    footerRender: false,
    menuHeaderRender: false,
    pageTitleRender: false,
    onMenuHeaderClick: () => history.push('/'),
    headerTitleRender: (logo, _) => logo,
    avatarProps: {
      src: <Avatar size="small" icon={<SmileOutlined />} alt="avatar" />,
      title: <AvatarName />,
      render: (_, avatarChildren) => {
        return <AvatarDropdown>{avatarChildren}</AvatarDropdown>;
      },
    },
    actionsRender: () => {
      const actions = [];
      const permissions = initialState?.permissions || [];
      if (permissions.courseMatch?.includes?.('create')) {
        actions.push(<CreateCourseMatchModalForm key="coursematch-create" />);
      }
      if (permissions.lesson?.includes?.('create')) {
        actions.push(<CreateLessonModalForm key="lesson-create" />);
      }
      actions.push(<TimezoneConverter key="timezoneConverter" />);
      actions.push(<Notice key="notice" />);
      actions.push(<SelectTheme />);
      actions.push(<DSTDatesDisplay />);
      return actions;
    },
    childrenRender: (children) => {
      return (
        <>
          {children}
          <ChangeForgottenPasswordModal currentUser={initialState?.currentUser} />
          <OnboardingTODOs />
          <FloatButton.Group>
            {initialState?.permissions.salaryIncreaseApprovals?.includes?.('query') ? (
              <TeachersSalaryIncreaseFloatButton
                status="未发起"
                salaryIncreaseApprovalsPermission={
                  initialState?.permissions.salaryIncreaseApprovals
                }
              />
            ) : (
              ''
            )}
            <ConnectWechatQrCodeModal />
          </FloatButton.Group>
        </>
      );
    },
    ...initialState?.settings,
  };
};
