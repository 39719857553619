import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { queryByHeadTeacher, queryByStudent } from './service';

const ContractSelect = ({ headTeacher, student, category, ongoing, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState();
  const [data, setData] = useState([]);
  const fetchData = async (headTeacher, student) => {
    setLoading(true);
    try {
      const serverRequest = headTeacher ? queryByHeadTeacher : queryByStudent;
      const id = headTeacher || student;
      const data = await serverRequest(id, {
        headTeacher,
        student,
        category,
        ongoing,
      });
      setData(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (headTeacher || student) {
      fetchData(headTeacher, student);
    }
  }, [headTeacher, student]);

  const renderOptionText = (text) => {
    if (searchText) {
      const index = text.toLowerCase().indexOf(searchText);
      if (index !== -1) {
        const searchTextLength = searchText.length;
        const prefix = text.substring(0, index);
        const match = text.substring(index, index + searchTextLength);
        const suffix = text.substring(index + searchTextLength);
        return (
          <span>
            {prefix}
            <span style={{ color: '#1890ff', fontWeight: 'bold' }}>{match}</span>
            {suffix}
          </span>
        );
      }
    }
    return text;
  };

  return (
    <Select
      showSearch
      style={{
        height: '100%',
      }}
      placeholder="可按 合同号/学员姓名/产品名 进行搜索"
      disabled={loading}
      loading={loading}
      {...rest}
      onSearch={(value) => {
        setSearchText(value.toLowerCase());
      }}
      filterOption={(input, option) => {
        const searchText = input.toLowerCase();
        if (option?.student?.toLowerCase().includes(searchText)) return true;
        if (option?.contract?.toLowerCase().includes(searchText)) return true;
        if (option?.product?.toLowerCase().includes(searchText)) return true;
        return false;
      }}
    >
      {data.map((contract) => {
        const contractName = contract.organizationContractId || contract.contractId;
        const productName = contract.products?.length
          ? contract.products.map((elem) => elem.name).join(',')
          : '无产品';
        const studentName = contract.student ? contract.student.fullName : '无学生';
        const headTeacherName = contract.headTeacher
          ? `${
              contract.headTeacher.chineseName ||
              `${contract.headTeacher.lastName}${contract.headTeacher.firstName}`
            }`
          : '无班主任';
        let disabled = !contract.headTeacher || !contract.contractStatus.includes('已开启服务');
        const studentTimeZone = contract.student?.timezone;

        if (!ongoing) disabled = false;
        return (
          <Select.Option
            key={contract.id}
            value={contract.id}
            disabled={disabled}
            contract={contractName}
            product={productName}
            student={studentName}
            studentTimeZone={studentTimeZone}
          >
            <div style={{ width: '100%', whiteSpace: 'normal', wordWrap: 'normal' }}>
              {renderOptionText(contractName)} - {renderOptionText(studentName)} -{' '}
              {renderOptionText(productName)}
              {`[ ${Math.min(100, contract.usagePct)}% ]`} - {headTeacherName} -
              {contract.contractStatus}
              {(!contract.headTeacher || contract.contractStatus === '未开启服务') &&
                ` (提醒：请开启合同并添加班主任)`}
            </div>
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default ContractSelect;
