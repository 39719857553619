import { request } from '@@/plugin-request';

export async function query(params) {
  return request('/api/educationalResearchTasks/firstLessonQualityTasks', {
    method: 'get',
    params,
  });
}

export async function updateNeedGroupChat(data) {
  return request('/api/coursematch/updateNeedGroupChat', {
    method: 'put',
    data: data,
  });
}
