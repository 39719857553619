import { request } from '@@/plugin-request';

export async function queryById(id) {
  return request(`/api/contracts/edit/${id}`, {
    method: 'GET',
  });
}

export async function editContract(id, payload) {
  return request(`/api/contracts/${id}`, {
    method: 'PUT',
    data: payload,
  });
}

export async function submitForm(params) {
  return request('/api/contracts', {
    method: 'POST',
    data: params,
  });
}

export async function getFirstContracts(params) {
  return request('/api/contracts/first', {
    method: 'GET',
    params,
  });
}

export async function createContractsFromOrder(params) {
  return request('/api/orders/createContractsFromOrder', {
    method: 'PUT',
    params,
  });
}
