import { currencyClearingMapping, currencyCountryMapping } from '@/utils/CountryCurrencyMapping';
import { ProForm, ProFormSelect } from '@ant-design/pro-components';
import { Button, message } from 'antd';
import { useState } from 'react';

const PreSetAirwallexForm = ({
  currency,
  onFinish,
  airwallexPreSetData,
  setAirwallexPreSetData,
}) => {
  const [form] = ProForm.useForm();

  const [showClearingSystem, setShowClearingSystem] = useState(
    currencyClearingMapping[currency].length > 0,
  );

  const [paymentMethod, setPaymentMethod] = useState([
    {
      label: 'LOCAL',
      value: 'LOCAL',
    },
    {
      label: 'SWIFT',
      value: 'SWIFT',
    },
  ]);

  // 定义一个函数，用于根据account_currency和bank_country_code的值来更新payment_method和local_clearing_system的值
  const updatePaymentMethodAndClearingSystem = () => {
    const accountCurrency = form.getFieldValue('account_currency');
    const bankCountryCode = form.getFieldValue('bank_country_code');

    //获取货币对应的国家
    const correspondingCountries = currencyCountryMapping[accountCurrency] || [];

    // 判断货币和国家是否匹配
    const isCurrencyCountryMatch = correspondingCountries.includes(bankCountryCode);

    if (!isCurrencyCountryMatch) {
      // 如果不匹配，设置payment_method为SWIFT，并隐藏local_clearing_system
      form.setFieldsValue({
        payment_method: 'SWIFT',
      });
      setShowClearingSystem(false);
      setPaymentMethod([
        {
          label: 'SWIFT',
          value: 'SWIFT',
        },
      ]);
    } else {
      // 如果匹配，设置payment_method为LOCAL，并显示local_clearing_system
      form.setFieldsValue({
        payment_method: 'LOCAL',
      });
      setShowClearingSystem(true);
      setPaymentMethod([
        {
          label: 'LOCAL',
          value: 'LOCAL',
        },
        {
          label: 'SWIFT',
          value: 'SWIFT',
        },
      ]);
    }

    // 获取对应的清算系统列表
    const correspondingClearingSystems = currencyClearingMapping[accountCurrency] || [];
    if (correspondingClearingSystems.length > 0) {
      // 设置local_clearing_system为第一个可选的清算系统
      form.setFieldsValue({
        local_clearing_system: correspondingClearingSystems[0] || '',
      });
    } else {
      // 如果没有可选的清算系统，清空local_clearing_system的值
      setShowClearingSystem(false);
    }
  };

  return (
    <ProForm
      // onFinish={async (values) => {
      //   try {
      //     setAirwallexPreSetData(values);
      //     onFinish();
      //   } catch (err) {
      //     console.log(err);
      //     message.error('获取Airwallex表单失败！');
      //   }
      // }}
      submitter={{
        render: (props) => {
          return [
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                try {
                  const values = props.form?.getFieldsValue();
                  setAirwallexPreSetData(values);
                  onFinish();
                } catch (err) {
                  console.log(err);
                  message.error('获取Airwallex表单失败！');
                }
              }}
            >
              下一步
            </Button>,
          ];
        },
      }}
      form={form}
      initialValues={
        JSON.stringify(airwallexPreSetData) !== '{}'
          ? airwallexPreSetData
          : {
              entity_type: 'PERSONAL', // 设置默认收款方类型为PERSONAL
              account_currency: currency, // 设置默认货币为USD
              bank_country_code: currencyCountryMapping[currency][0],
              payment_method: 'LOCAL', // 设置默认支付方式为LOCAL
            }
      }
    >
      <ProFormSelect
        name="entity_type"
        label="Beneficiary Type"
        options={[
          {
            label: 'Company',
            value: 'COMPANY',
          },
          {
            label: 'Personal',
            value: 'PERSONAL',
          },
        ]}
        rules={[{ required: true, message: 'Select Beneficiary Type Please!' }]}
        disabled
      />
      <ProFormSelect
        name="account_currency"
        label="Account Currency"
        request={async () => {
          return Object.keys(currencyCountryMapping).map((currency) => ({
            value: currency,
            label: currency,
          }));
        }}
        fieldProps={{
          onChange: () => {
            // 当account_currency改变时，更新payment_method和local_clearing_system的值
            updatePaymentMethodAndClearingSystem();
          },
        }}
        rules={[{ required: true, message: 'Select Account Currency Please!' }]}
        disabled
      />

      <ProFormSelect
        name="bank_country_code"
        label="Bank Country Code"
        request={async () => {
          return Object.values(currencyCountryMapping)
            .flat()
            .map((countryCode) => ({
              value: countryCode,
              label: countryCode,
            }));
        }}
        fieldProps={{
          onChange: () => {
            // 当bank_country_code改变时，更新payment_method和local_clearing_system的值
            updatePaymentMethodAndClearingSystem();
          },
        }}
        rules={[{ required: true, message: 'Select Bank Country Code Please!' }]}
      />

      <ProFormSelect
        name="payment_method"
        label="Payment Method"
        options={paymentMethod}
        rules={[{ required: true, message: 'Select Payment Method Please!' }]}
        disabled
      />

      {showClearingSystem && (
        <ProFormSelect
          name="local_clearing_system"
          label="Local Clearing System"
          request={async () => {
            // 根据account_currency的值来动态获取可选的清算系统列表
            const accountCurrency = form.getFieldValue('account_currency');
            const correspondingClearingSystems = currencyClearingMapping[accountCurrency] || [];
            return correspondingClearingSystems.map((clearingSystem) => ({
              value: clearingSystem,
              label: clearingSystem,
            }));
          }}
          rules={[{ required: true, message: 'Select Local Clearing System Please!' }]}
        />
      )}
    </ProForm>
  );
};

export default PreSetAirwallexForm;
