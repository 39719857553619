import { request } from '@@/plugin-request';

export async function getStudent(payload) {
  return request(`/api/student/get`, {
    method: 'GET',
    params: payload,
  });
}

export async function getSyllabusEvents(params) {
  // school: async (start, end) => {
  //   return [];
  // },
  // syllabus: async (start, end) => {
  //   return [];
  // },
}

export async function getSemesterCourse4Students(payload) {
  return request(`/api/student/getSemesterCourse4Students`, {
    method: 'GET',
    params: payload,
  });
}

export async function update(payload) {
  return request(`/api/student/update`, {
    method: 'PUT',
    data: payload,
  });
}

export async function get4SchoolSemesterCourse(params) {
  return request(`/api/student/get4SchoolSemesterCourse`, {
    method: 'GET',
    params,
  });
}

export async function getDates({ studentId, params }) {
  return request(`/api/importantdates/student/${studentId}`, {
    method: 'GET',
    params,
  });
}

export async function getLessons(payload) {
  return request(`/api/lessons/simple`, {
    method: 'GET',
    params: payload,
  });
}

export async function createNote(payload) {
  return request(`/api/student/note`, {
    method: 'POST',
    data: payload,
  });
}

export async function updateNote(payload) {
  return request(`/api/student/note`, {
    method: 'PUT',
    data: payload,
  });
}

export async function createStudentSemesterCourse(payload) {
  return request(`/api/student/createStudentSemesterCourse`, {
    method: 'POST',
    data: payload,
  });
}


export async function getEventsByStudent(params) {
  return request(`/api/operationEvents/getEventsByStudent`, {
    method: 'GET',
    params,
  });
}

export async function getTeachers(params) {
  return request('/api/users/teachers', {
    method: 'GET',
    params,
  });
}

export async function getComplaintsById(params) {
  return request(`/api/teachingComplaints/students`, {
    method: 'GET',
    params,
  });
}

export async function saveBiweeklyReport(payload) {
  return request(`/api/schoolSemester/biweeklyReport`, {
    method: 'POST',
    data: payload,
  });
}

export async function getBiweeklyReport(params) {
  return request(`/api/schoolSemester/biweeklyReport`, {
    method: 'GET',
    params,
  });
}

export async function biweeklyReportPDFGeneration(payload) {
  return request(`/api/biweeklyReport/generate`, {
    method: 'POST',
    data: payload,
  });
}

//recentAttendanceAndNextClassQuery
export async function getRecentAttendanceAndNextClassQuery(params) {
  return request(`/api/student/recentAttendanceAndNextClassQuery`, {
    method: 'GET',
    params,
  });
}
