import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useModel } from 'umi';
import { useHeadTeachers } from '@/hooks/useHeadTeachers';
import { getUsername } from '@/utils/utils';

const HeadTeacherSelector = ({
  onChange,
  onSelect,
  withDeleted = false,
  nullOption = false,
  allowCustomOption = false,
  defaultActiveCurrentUser = false,
  defaultActiveOnlyOption = true,
  ...rest
}) => {
  const {
    initialState: { currentUser },
  } = useModel('@@initialState');
  const {
    data,
    isLoading: loading,
    isError,
  } = useHeadTeachers({
    withDeleted,
  });

  // 保证页面行为统一，强制使用定制tags mode
  if (rest.mode === 'tags') {
    rest.mode = 'multiple';
    allowCustomOption = true;
  }

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!loading && data) {
      const options = (data || []).map((el) => {
        let metadata = '';
        if (el.chineseName) {
          metadata += `${el.chineseName}-`;
        }
        if (el.firstName && el.lastName) {
          metadata += `${el.firstName.toLowerCase()}${el.lastName.toLowerCase()}-${el.lastName.toLowerCase()}${el.firstName.toLowerCase()}`;
        }
        return {
          key: el.id,
          value: el.id,
          label: getUsername(el),
          metadata: metadata,
        };
      });
      // 能看到多个教务的人才会有无班主任的选项
      if (options?.length > 1 && nullOption) {
        options.push({
          key: 0,
          value: 0,
          label: '无班主任',
        });
      }
      setOptions(options);
      if (defaultActiveOnlyOption && options.length === 1) {
        onChange(options[0].value);
      } else if (defaultActiveCurrentUser && options.some((el) => el.value === currentUser.id)) {
        onChange(currentUser.id);
      }
    }
    return () => {
      setOptions([]);
    };
  }, [loading, data]);

  const matchWithInput = (input, option) => {
    if (!input || !option) return false;
    let match = false;
    if (!match && option.label) {
      match = option.label.indexOf(input) !== -1;
    }
    if (!match && option.metadata) {
      match = option.metadata.indexOf(input) !== -1;
    }
    return match;
  };

  const searchHandler = (input) => {
    if (!input || !allowCustomOption) {
      return;
    }
    if (options.some((el) => matchWithInput(input, el))) {
      return;
    }
    const newOptions = options.filter((el) => el.key !== -1);
    setOptions([
      ...newOptions,
      {
        key: -1,
        value: input,
        label: input,
        metadata: input,
      },
    ]);
  };

  return (
    <Select
      showSearch
      loading={loading}
      disabled={loading || isError}
      options={options}
      onSelect={(value) => {
        if (typeof onSelect === 'function') {
          onSelect(options.find((el) => el.value === value));
        }
      }}
      onChange={onChange}
      filterOption={matchWithInput}
      onSearch={searchHandler}
      placeholder="可用中文或拼音搜索姓名"
      {...rest}
    />
  );
};

export default HeadTeacherSelector;
