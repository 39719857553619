import { pathToRegexp } from 'path-to-regexp';
import { query as queryAttendances } from '../../../services/attendance';
// import { history } from 'umi';
import { getById } from './service';

const Model = {
  namespace: 'trainingCourseProfile',
  state: {
    courseId: '',
    currentItem: {},
    list: [],
    attendances: [],
    traineesCount: null,
  },

  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ location }) => {
        const match = pathToRegexp('/trainingcourse/:id').exec(location.pathname);

        if (match) {
          const id = match[1];

          if (id !== 'new') {
            dispatch({
              type: 'fetchCurrent',
              payload: {
                id,
              },
            });
            // dispatch({
            //   type: 'fetchAttendances',
            //   payload: {
            //     id,
            //   },
            // });
          } else {
            dispatch({
              type: 'saveCurrentCourse',
              payload: {},
            });
          }
        }
      });
    },
  },

  effects: {
    *fetchCurrent({ payload }, { call, put }) {
      const response = yield call(getById, payload.id);
      if (response) {
        const { course, traineesCount } = response;
        course.lessons = course.lessons.filter((elem) => !elem.deletedAt);
        yield put({
          type: 'save',
          payload: {
            currentItem: course,
            courseId: payload.id,
            traineesCount,
          },
        });
      }
    },

    *fetchAttendances({ payload }, { call, put }) {
      const response = yield call(queryAttendances, {
        course: payload.id,
      });
      if (response) {
        yield put({
          type: 'save',
          payload: {
            attendances: response,
          },
        });
      }
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },

    queryList(state, action) {
      return { ...state, list: action.payload };
    },
  },
};
export default Model;
