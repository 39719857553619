import { request } from '@@/plugin-request';

export async function create(params) {
  return request('/api/semester/create', {
    method: 'POST',
    data: params,
  });
}

export async function query(params) {
  return request('/api/semester/query', {
    method: 'GET',
    params,
  });
}
