import { Button, Popover, Tag, Timeline } from 'antd';
import dayjs from 'dayjs';

export const displayDate = (date) => {
  return dayjs(date).format('YYYY-MM');
};

export const getSemesterList = (semesterPatterns, marginInMonth) => {
  let currentSemesterList = [];
  let semesterList = [];
  if (!semesterPatterns) {
    return { currentSemesterList, semesterList };
  }
  let beforeDate = dayjs();
  let afterDate = dayjs();
  if (marginInMonth) {
    beforeDate.add(marginInMonth, 'month');
  }
  for (const semesterPattern of semesterPatterns) {
    for (const semester of semesterPattern.semesters) {
      if (
        dayjs(semester.startDate).startOf('month') < beforeDate &&
        dayjs(semester.endDate).endOf('month') > afterDate
      ) {
        currentSemesterList.push(semester);
      }
      semesterList.push(semester);
    }
  }
  const sortFunc = (a, b) => (new Date(b.startDate) > new Date(a.startDate) ? 1 : -1);
  currentSemesterList.sort(sortFunc);
  semesterList.sort(sortFunc);
  return { currentSemesterList, semesterList };
};
export default ({ semesterPatterns, showSemesterHistoryPlain }) => {
  if (!semesterPatterns) {
    return <div style={{ color: 'orange' }}>无学期规律</div>;
  }
  const { currentSemesterList, semesterList } = getSemesterList(semesterPatterns);

  const content = (
    <Timeline
      style={{ width: '800px' }}
      items={semesterList.map((ele) => ({
        label: `${displayDate(ele.startDate)} 至 ${displayDate(ele.endDate)}`,
        children: ele.name,
      }))}
    ></Timeline>
  );
  let currentSemester;
  if (!semesterPatterns?.length) {
    currentSemester = <div style={{ color: 'orange' }}>无学期规律</div>;
  } else if (semesterPatterns && !currentSemesterList?.length) {
    currentSemester = <div>当前无学期</div>;
  } else {
    currentSemester = (
      <div>
        <span>当前学期:</span>
        {currentSemesterList.map((ele) => (
          <Tag style={{ whiteSpace: 'pre' }}>
            <div>
              <span style={{ fontWeight: 'bold' }}>{ele.name}:</span>
              {`  ${displayDate(ele.startDate)}至${displayDate(ele.endDate)}`}
            </div>
          </Tag>
        ))}
      </div>
    );
  }

  return (
    <div>
      {currentSemester}
      {semesterList?.length && semesterList.length !== currentSemesterList?.length ? (
        !showSemesterHistoryPlain ? (
          <Popover content={content} title="历史学期" trigger="hover" style={{ width: '800px' }}>
            <Button type="link">查看历史学期</Button>
          </Popover>
        ) : (
          <div>历史学期：{content}</div>
        )
      ) : null}
    </div>
  );
};
