import { request } from '@@/plugin-request';

export async function getById(id) {
  return request(`/api/trainingCourse/${id}`, {
    method: 'GET',
  });
}

export async function queryReg(params) {
  return request(`/api/registration`, {
    method: 'GET',
    params,
  });
}

export async function queryRegById(id) {
  return request(`/api/registration/${id}`, {
    method: 'GET',
  });
}

export async function queryRegByUser(id, params) {
  return request(`/api/registration/user/${id}`, {
    method: 'GET',
    params,
  });
}

export async function createReg(params) {
  return request(`/api/registration`, {
    method: 'POST',
    data: params,
  });
}

export async function updateReg(id, params) {
  return request(`/api/registration/${id}`, {
    method: 'PUT',
    data: params,
  });
}

export async function switchRegStatus(id) {
  return request(`/api/registration/switch/${id}`, {
    method: 'PUT',
  });
}
