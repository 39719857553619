import { smsTwoFactor, twoFactorVerify, signin as verify } from '@/services/login';
import { getErrorMessageFromResponse } from '@/utils/utils';
import { message } from 'antd';
import { parse } from 'querystring';
import { useCallback, useRef, useState } from 'react';

const redirect = () => {
  const urlParams = new URL(window.location.href);
  const params = parse(urlParams.search, '?');
  let { redirect } = params;
  if (redirect) {
    const redirectUrlParams = new URL(redirect);
    if (redirectUrlParams.origin === urlParams.origin) {
      redirect = redirect.substr(urlParams.origin.length);
      if (redirect.match(/^\/.*#/)) {
        redirect = redirect.substr(redirect.indexOf('#') + 1);
      }
    }
  }
  window.location.href = redirect || '/';
};

export default function useLoginModel() {
  const timer = useRef(0);
  const countdownEndAt = useRef(0);
  const [countdown, setCountdown] = useState();
  const [twoFAdata, setTwoFAdata] = useState({});
  const [verifyMethod, setVerifyMethod] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  //未绑定飞书和微信二维码的用户显示二维码
  const [connectModalVisible, setConnectModalVisible] = useState(false);
  //是否跳过绑定
  const [isSkip, setIsSkip] = useState(false);
  const [qrCode, setQrCode] = useState('');
  //电话号码
  const [phone, setPhone] = useState({
    phonePrefix: '',
    phone: '',
  });

  const onCancel = useCallback(() => {
    setModalVisible(false);
  }, []);

  const startCountdown = () => {
    // 2 min cooldown
    countdownEndAt.current = Date.now() + 2 * 60 * 1000;
    timer.current = setInterval(() => {
      const timeRemaining = Math.floor((countdownEndAt.current - Date.now()) / 1000);
      if (timeRemaining <= 0) {
        clearInterval(timer.current);
        countdownEndAt.current = 0;
        setCountdown(0);
      } else {
        setCountdown(timeRemaining);
      }
    }, 1000);
  };

  const onSuccess = (token) => {
    message.success('登录成功！');
    localStorage.setItem('token', token);
    countdownEndAt.current = 0;
    clearInterval(timer.current);
    setCountdown(0);
    onCancel();
    redirect();
  };

  const signinViaSMS = async (data) => {
    if (!data.phonePrefix || !data.phone) {
      message.error('请填写区号和手机号');
      return Promise.reject('请填写区号和手机号');
    }
    try {
      const { isLocked, cooldown, sp, requestId } = await smsTwoFactor(data);
      if (isLocked) {
        throw new Error('该账号已被锁定，请联系管理员或HR解锁。');
      }
      if (cooldown) {
        throw new Error('发送验证码太频繁，请2分钟后再试。');
      }
      if (sp && requestId) {
        message.success('成功发送验证码，2分钟内有效。');
        setTwoFAdata({
          sp,
          requestId,
        });
      } else {
        setTwoFAdata({});
        throw new Error('找不到用户。');
      }
    } catch (error) {
      message.error(getErrorMessageFromResponse(error));
    }
  };

  const signin = async (values) => {
    if (countdown > 0) {
      setModalVisible(true);
      return Promise.resolve();
    }
    try {
      const res = await verify(values);
      const {
        token,
        isLocked,
        twoFactor,
        requestId,
        sp,
        verifyMethod,
        cooldown,
        qrCode,
        phone,
        phonePrefix,
      } = res;
      if (isLocked) {
        throw new Error('该账号已被锁定，请联系管理员或HR解锁。');
      }
      if (cooldown) {
        throw new Error('发送验证码太频繁，请2分钟后再试。');
      }

      if (qrCode) {
        setConnectModalVisible(true);
        setQrCode(qrCode);
        setIsSkip(false);
        setPhone({ phonePrefix, phone });
      }

      if (twoFactor) {
        startCountdown();
        setTwoFAdata({ requestId, sp });
        setVerifyMethod(verifyMethod);
        setModalVisible(true);
      } else if (token) {
        onSuccess(token);
      }
    } catch (error) {
      message.error(getErrorMessageFromResponse(error));
    }
  };

  const twoFactorSignin = async (values) => {
    try {
      const res = await twoFactorVerify({ ...values, verifyMethod, ...twoFAdata });
      const { token } = res;
      if (token) {
        onSuccess(token);
      } else {
        message.error('登录失败，请检查验证码');
      }
    } catch (error) {
      console.log(error);
      message.error(getErrorMessageFromResponse(error));
    }
  };

  return {
    modalVisible,
    verifyMethod,
    onCancel,
    signin,
    twoFactorSignin,
    countdown,
    signinViaSMS,
    connectModalVisible,
    setConnectModalVisible,
    qrCode,
    isSkip,
    setIsSkip,
    phone,
    setPhone,
  };
}
