import { message } from 'antd';
import lodash from 'lodash';
import { getCountries, putSchool, query, update } from './service';

const SchoolModel = {
  namespace: 'school',

  state: {
    onCreate: false,
    schoolsWithoutSemesterPattern: null,
    onSubmitLoading: false,
    createdSchool: null,
    schoolData: [],
    schoolDataLoading: false,
    schoolDataCount: 0,
    editing: false,
    school: null,
    countryList: [],
    selectedCountry: null,
    page: 1,
    pageSize: 20,
    updatedSchool: null,
    noSemesterWarningCount: 0,
  },

  effects: {
    *onCountryFilterReset(_, { put }) {
      yield put({
        type: 'updateState',
        payload: {
          selectedCountry: null,
          page: 1,
        },
      });

      yield put({
        type: 'query',
      });
    },

    *onSchoolsWithoutSemesterPatternFilterReset(_, { put }) {
      yield put({
        type: 'updateState',
        payload: {
          schoolsWithoutSemesterPattern: null,
          page: 1,
        },
      });
    },

    *getCountries(_, { put, call }) {
      const data = yield call(getCountries, {});

      if (data) {
        yield put({
          type: 'updateState',
          payload: {
            countryList: data.map((item) => item.country),
          },
        });
      }
    },

    *onSchoolSearch({ payload }, { put }) {
      const { value } = payload;

      yield put({
        type: 'updateState',
        payload: {
          searchValue: value,
          page: 1,
        },
      });

      yield put({
        type: 'query',
        payload: {
          mode: 'statistics',
        },
      });
    },

    *onPageChange({ payload }, { put }) {
      const { page, pageSize } = payload;

      yield put({
        type: 'updateState',
        payload: {
          page,
          pageSize,
        },
      });

      yield put({
        type: 'query',
      });
    },

    *onSchoolEdit({ payload }, { put, select, call }) {
      const { school } = payload;

      let schoolToEdit;

      if (school) {
        schoolToEdit = {
          ...school,
        };
      } else {
        schoolToEdit = {
          name: '',
          shortName: null,
          country: null,
          levels: [],
          resourceURLs: [],
        };
      }

      yield put({
        type: 'updateState',
        payload: {
          editing: true,
          school: schoolToEdit,
        },
      });
    },

    *onSchoolEditEnd(_, { put }) {
      yield put({
        type: 'updateState',
        payload: {
          editing: false,
          school: null,
          onSubmitLoading: false,
          createdSchool: null,
        },
      });
    },

    *update({ payload }, { call, put }) {
      const school = payload.school;
      const { mode, isEnable } = payload.school;

      yield put({
        type: 'updateState',
        payload: {
          onSubmitLoading: true,
          mode,
        },
      });

      const response = yield call(update, school);
      if (response) {
        yield put({
          type: 'query',
          payload: {
            mode,
            isEnable,
          },
        });
      }

      yield put({
        type: 'updateState',
        payload: {
          // createdSchool: response,
          onSubmitLoading: false,
        },
      });
    },

    *onSchoolEditSubmit({ payload }, { select, put, call }) {
      const school = yield select((state) => state.school.school);
      const schools = yield select((state) => state.school.schoolData);
      const { mode } = payload;

      const result = schools.filter((s) => {
        if (
          s.name.toLowerCase() === school.name.toLowerCase() &&
          school.country.toLowerCase().includes(s.country) &&
          s.id !== school.id
        ) {
          return s;
        }
      });

      if (result.length > 0) {
        message.error({
          content: `【${school.name}】${school.id ? '更新' : '创建'}失败: 重复名字和国家`,
          duration: 4,
        });
      } else {
        yield put({
          type: 'updateState',
          payload: {
            onSubmitLoading: true,
            mode,
          },
        });

        if (school) {
          const { name, country, timezone, levels } = school;

          if (!name || !country || !timezone || !(levels && levels.length)) {
            yield put({
              type: 'updateState',
              payload: {
                onSubmitLoading: false,
              },
            });

            return message.error({
              content: `学校名，国家，时区和年级为必填`,
              duration: 4,
            });
          }

          const params = {
            id: school.id,
            shortName: school.shortName,
            name,
            levels,
            country: school.country.split('-')[1].trim().toLowerCase(),
            timezone,
            resourceURLs: school.resourceURLs,
          };

          const response = yield call(putSchool, params);

          if (response) {
            const { error } = response;

            if (error) {
              message.error({
                content: `【${school.name}】${school.id ? '更新' : '创建'}失败: 重复名字和国家`,
                duration: 4,
              });
            } else {
              message.success({
                content: `【${school.name}】${school.id ? '更新' : '创建'}成功`,
                duration: 4,
              });

              yield put({
                type: 'query',
                payload: {
                  mode,
                },
              });
            }

            yield put({
              type: 'onSchoolEditEnd',
            });
          }

          yield put({
            type: 'updateState',
            payload: {
              // createdSchool: response,
              onSubmitLoading: false,
            },
          });
        }
      }
    },

    *create({ payload }, { call, put }) {
      const response = yield call(create, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            createdSchool: response,
            onSubmitLoading: false,
          },
        });

        yield put({
          type: 'query',
        });
      }
    },

    *query({ payload = {} }, { call, put, select }) {
      const page = yield select((state) => state.school.page);
      const pageSize = yield select((state) => state.school.pageSize);
      const searchValue = yield select((state) => state.school.searchValue);
      const country = yield select((state) => state.school.selectedCountry);
      const schoolsWithoutSemesterPattern = yield select(
        (state) => state.school.schoolsWithoutSemesterPattern,
      );
      const { mode = 'statistics', isEnable } = payload;

      yield put({
        type: 'updateState',
        payload: {
          schoolDataLoading: true,
        },
      });

      const params = {
        page,
        pageSize,
        searchValue,
        country,
        schoolsWithoutSemesterPattern,
        mode,
        isEnable,
      };

      const response = yield call(query, params);
      let noSemesterPatternWarningCount = 0;
      for (const school of response?.schools) {
        if (!school?.semesterPatterns?.length && school?.currentStudentCount) {
          noSemesterPatternWarningCount += 1;
        }
      }

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            schoolData: response.schools,
            schoolDataCount: response.count,
            schoolDataLoading: false,
            noSemesterPatternWarningCount,
          },
        });
      }
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    init(state, { payload }) {
      return {
        ...state,
        onCreate: true,
        createdSchool: null,
        selectedLevels: [],
      };
    },

    end(state, { payload }) {
      return {
        ...state,
        onCreate: false,
        createdSchool: null,
        selectedLevels: [],
      };
    },
  },
};

export default SchoolModel;
