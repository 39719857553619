import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { useState } from 'react';
import { getUploadParams } from './service.js';

export default ({
  onChange,
  value,
  buttonText,
  folderId,
  bucket = 'savvyuni-intl-erp',
  ...rest
}) => {
  const [ossData, setOssData] = useState();
  const [fileList, setFileList] = useState(Array.isArray(value) ? value : !!value ? [value] : []);

  const getOSSAuth = async (file) => {
    if (!ossData || new Date().getTime() > ossData.expire) {
      const res = await getUploadParams({
        folderId,
      });
      setOssData(res);
      return {
        key: `${res.dir}/${file.name}`,
        OSSAccessKeyId: res.accessId,
        policy: res.policy,
        Signature: res.signature,
      };
    } else {
      return {
        key: `${ossData.dir}/${file.name}`,
        OSSAccessKeyId: ossData.accessId,
        policy: ossData.policy,
        Signature: ossData.signature,
      };
    }
  };

  const onUploadChange = ({ file }) => {
    let newFileList = [];
    if (file.status === 'removed') {
      newFileList = [...fileList.filter((f) => f.uid !== file.uid)];
    } else {
      if (fileList.some((f) => f.uid === file.uid)) {
        newFileList = [
          ...fileList.map((f) => {
            if (f.uid === file.uid) {
              const newFile = {
                ...f,
                ...file,
              };
              if (file.status === 'done') {
                newFile.bucket = bucket;
                newFile.key = `${ossData.dir}/${file.name}`;
              }
              return newFile;
            }
            return f;
          }),
        ];
      } else {
        newFileList = [...fileList, file];
      }
    }
    setFileList(newFileList);
    if (onChange && typeof onChange === 'function') {
      if (rest.maxCount === 1) {
        onChange(newFileList[0]);
      } else {
        onChange(newFileList);
      }
    }
  };

  return (
    <Upload
      action={`https://${bucket}.oss-accelerate.aliyuncs.com`}
      data={getOSSAuth}
      fileList={fileList}
      onChange={onUploadChange}
      {...rest}
    >
      <Button icon={<UploadOutlined />} {...rest}>
        {buttonText || '点击上传'}
      </Button>
    </Upload>
  );
};
