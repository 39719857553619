import { logout } from '@/services/user';
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { useEmotionCss } from '@ant-design/use-emotion-css';
import { history, useModel } from '@umijs/max';
import { Divider, Spin } from 'antd';
import { useCallback } from 'react';
import { flushSync } from 'react-dom';
import HeaderDropdown from '../HeaderDropdown';

export const AvatarName = () => {
  const { initialState } = useModel('@@initialState');
  const { currentUser } = initialState || {};
  const name = currentUser
    ? `${currentUser.nickname || `${currentUser.firstName} ${currentUser.lastName}`}`
    : 'Anon';
  const roles = currentUser?.roles
    ?.map((r) => <span key={r.label}>{r.label}</span>)
    .reduce((arr, val) => [arr, <Divider key="divider" type="vertical" />, val], []);
  return (
    <span className="anticon">
      {name}
      {roles}
    </span>
  );
};

export const AvatarDropdown = ({ menu, children }) => {
  const actionClassName = useEmotionCss(({ token }) => {
    return {
      display: 'flex',
      height: '48px',
      marginLeft: 'auto',
      overflow: 'hidden',
      alignItems: 'center',
      padding: '0 8px',
      cursor: 'pointer',
      borderRadius: token.borderRadius,
      '&:hover': {
        backgroundColor: token.colorBgTextHover,
      },
    };
  });
  const loading = (
    <span className={actionClassName}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );
  const { initialState, setInitialState } = useModel('@@initialState');
  if (!initialState) {
    return loading;
  }
  const { currentUser } = initialState;
  if (!currentUser) {
    return loading;
  }

  const onMenuClick = useCallback(
    (event) => {
      const { key } = event;
      switch (key) {
        case 'logout':
          flushSync(() => {
            setInitialState((s) => ({ ...s, currentUser: undefined }));
          });
          logout();
          break;
        case 'settings':
          history.push(`/account/profile/${currentUser.id}`);
          break;
        default:
          return;
      }
    },
    [setInitialState],
  );

  const menuItems = [
    {
      key: 'settings',
      icon: <SettingOutlined />,
      label: '个人设置',
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: '退出登录',
    },
  ];

  return (
    <HeaderDropdown
      menu={{
        selectedKeys: [],
        onClick: onMenuClick,
        items: menuItems,
      }}
    >
      {children}
    </HeaderDropdown>
  );
};
