import dayjs from 'dayjs';
import { queryContracts } from './service';

const generateDisplayDataBody = (startAt, endAt, dateUnit) => {
  const displayDataBody = [];

  const startdayjs = startAt.clone();
  const enddayjs = endAt.clone();

  let currentdayjs = startdayjs;

  if (dateUnit === 'day') {
    while (currentdayjs <= enddayjs) {
      const currentDate = currentdayjs.toDate();

      displayDataBody.push({
        label: `${currentDate.getFullYear()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getDate()}`,
        start: currentdayjs.startOf('day'),
        end: currentdayjs.endOf('day'),
        value: 0,
      });

      currentdayjs = currentdayjs.clone().add(1, 'day');
    }
  }

  if (dateUnit === 'week') {
    while (currentdayjs <= enddayjs.endOf('day')) {
      const weekStartAt = currentdayjs.clone();
      const weekStartAtDay = weekStartAt.toDate().getDay();
      let weekEndAt;
      if (weekStartAtDay <= 6 && weekStartAtDay > 0) {
        // week start day is Mon. to Sat.
        const newWeekEndAt = weekStartAt
          .clone()
          .add(7 - weekStartAtDay, 'day')
          .endOf('day');

        if (newWeekEndAt < enddayjs) {
          weekEndAt = newWeekEndAt;
        } else {
          weekEndAt = enddayjs.clone().endOf('day');
        }
      } else {
        // week start day is Sunday
        weekEndAt = weekStartAt.endOf('day');
      }

      const weekStartAtDate = weekStartAt.toDate();
      const weekEndAtDate = weekEndAt.toDate();

      displayDataBody.push({
        label: `${
          weekStartAtDate.getMonth() + 1
        }-${weekStartAtDate.getDate()}/${weekEndAtDate.getDate()}`,
        start: weekStartAt.startOf('day'),
        end: weekEndAt.endOf('day'),
        value: 0,
      });

      currentdayjs = weekEndAt.clone().add(1, 'hour').startOf('day');
    }
  }

  // month feature

  return displayDataBody;
};

const DataDownloadModel = {
  namespace: 'contractChart',

  state: {
    startAt: dayjs().startOf('month'),
    endAt: dayjs().endOf('day'),
    dateUnit: 'day',
    contracts: [],
    contractsCount: 0,
    displayData: [],
    loading: false,
  },

  effects: {
    *onDateRangeChange({ payload }, { put }) {
      const { dates } = payload;

      yield put({
        type: 'updateState',
        payload: {
          startAt: dates[0].startOf('day'),
          endAt: dates[1].endOf('day'),
        },
      });

      yield put({
        type: 'queryContracts',
      });
    },

    *onDateUnitChange({ payload }, { put }) {
      const { value } = payload;

      yield put({
        type: 'updateState',
        payload: {
          dateUnit: value,
        },
      });

      yield put({
        type: 'generateDisplayData',
      });
    },

    *queryContracts({ payload }, { call, put, select }) {
      const startAt = yield select((state) => state.contractChart.startAt);
      const endAt = yield select((state) => state.contractChart.endAt);

      yield put({
        type: 'updateState',
        payload: {
          loading: true,
        },
      });

      const params = {
        startAt,
        endAt,
      };

      const data = yield call(queryContracts, params);

      if (data) {
        yield put({
          type: 'updateState',
          payload: {
            contracts: data.contracts,
            contractsCount: data.count,
          },
        });

        yield put({
          type: 'generateDisplayData',
        });
      }
    },

    *generateDisplayData(_, { put, select }) {
      const contracts = yield select((state) => state.contractChart.contracts);
      const dateUnit = yield select((state) => state.contractChart.dateUnit);
      const startAt = yield select((state) => state.contractChart.startAt);
      const endAt = yield select((state) => state.contractChart.endAt);

      yield put({
        type: 'updateState',
        loading: true,
      });

      const displayData = generateDisplayDataBody(startAt, endAt, dateUnit);

      if (contracts && contracts.length) {
        let totalContractsCount = 0;

        if (dateUnit === 'day') {
          contracts.forEach((contract) => {
            const { saleDate, count } = contract;

            const date = new Date(saleDate);
            const countInt = parseInt(count, 10);

            totalContractsCount += countInt;

            const dateString = [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-');

            const index = displayData.findIndex((item) => item.label === dateString);

            if (index >= 0) displayData[index].value += countInt;
          });
        }

        if (dateUnit === 'week') {
          contracts.forEach((contract) => {
            const { saleDate, count } = contract;

            const saleDatedayjs = dayjs(saleDate);
            const countInt = parseInt(count, 10);

            totalContractsCount += countInt;

            // const dateString = [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-')

            const index = displayData.findIndex(
              (item) => item.start <= saleDatedayjs && item.end >= saleDatedayjs,
            );

            if (index >= 0) displayData[index].value += countInt;
          });
        }

        yield put({
          type: 'updateState',
          payload: {
            displayData,
            loading: false,
            contractsCount: totalContractsCount,
          },
        });
      } else {
        yield put({
          type: 'updateState',
          payload: {
            displayData,
            loading: false,
            contractsCount: 0,
          },
        });
      }
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default DataDownloadModel;
