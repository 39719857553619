import { NotificationOutlined } from '@ant-design/icons';
import { ProList } from '@ant-design/pro-components';
import { Badge, Modal } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useModel } from 'umi';
import NoticeContent from './NoticeContent';

const renderBadge = (count, active = false) => {
  return (
    <Badge
      count={count}
      style={{
        marginBlockStart: -2,
        marginInlineStart: 4,
        color: active ? '#1890FF' : '#999',
        backgroundColor: active ? '#E6F7FF' : '#eee',
      }}
    />
  );
};
const keyOptions = {
  allNotice: 'allNotice',
  readNotice: 'readNotice',
  unreadNotice: 'unreadNotice',
};

export default () => {
  const noticeModel = useModel('notice');
  const {
    getDataSource,
    noticeCount,
    getNotices,
    updateStatus,
    noticeCenterOpen,
    setNoticeCenterOpen,
  } = noticeModel;
  const [activeKey, setActiveKey] = useState(keyOptions.unreadNotice);

  // re-decide whether content is too long
  const [refresh, setRefresh] = useState(true);
  useEffect(() => {
    getNotices();
  }, []);

  useEffect(() => {
    setRefresh(!refresh);
  }, [getDataSource, activeKey]);

  const action = useRef();
  const reload = () => {
    action.current?.reload();
    getNotices();
  };

  return (
    <div>
      <Badge
        count={noticeCount.unreadNoticeLength}
        onClick={() => {
          setNoticeCenterOpen(true);
        }}
      >
        <NotificationOutlined style={{ fontSize: '20px' }}>消息中心</NotificationOutlined>
      </Badge>
      <Modal
        width={'90%'}
        open={noticeCenterOpen}
        onCancel={() => {
          setNoticeCenterOpen(false);
        }}
        footer={null}
      >
        <div style={{ maxHeight: '800px', overflowY: 'auto' }}>
          {/* Set maximum height and scrolling */}
          <ProList
            size="large"
            rowKey="name"
            pagination={{
              pageSize: 5, // Number of items per page
              position: 'bottom', // Optional: Position of the pagination component
            }}
            actionRef={action}
            dataSource={getDataSource(activeKey)}
            metas={{
              title: {
                dataIndex: 'name',

                render: (text) => {
                  return (
                    <div>
                      <div style={{ fontSize: '20px', cursor: 'default' }}>{text}</div>
                    </div>
                  );
                },
              },
              description: {
                dataIndex: 'content',
                render: (_, record) => {
                  return (
                    <div style={{ margin: '10px', padding: '10px' }}>
                      <NoticeContent content={record.content} refresh={refresh} />

                      <div style={{ whiteSpace: 'pre' }}>{record.desc}</div>
                    </div>
                  );
                },
              },
              actions: {
                render: (text, row) => [
                  row.unread ? (
                    <a
                      href={row.html_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      key="link"
                      onClick={() => {
                        updateStatus(row.id, true);
                      }}
                    >
                      标记为已读
                    </a>
                  ) : (
                    <a
                      href={row.html_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      key="link"
                      onClick={() => {
                        updateStatus(row.id, false);
                      }}
                    >
                      标记为未读
                    </a>
                  ),
                ],
              },
            }}
            toolbar={{
              menu: {
                activeKey,
                items: [
                  {
                    key: 'allNotice',
                    label: (
                      <span>
                        全部消息
                        {renderBadge(noticeCount.allNoticeLength, activeKey === 'allNotice')}
                      </span>
                    ),
                  },
                  {
                    key: 'unreadNotice',
                    label: (
                      <span>
                        未读消息
                        {renderBadge(noticeCount.unreadNoticeLength, activeKey === 'unreadNotice')}
                      </span>
                    ),
                  },
                  {
                    key: 'readNotice',
                    label: (
                      <span>
                        已读消息
                        {renderBadge(noticeCount.readNoticeLength, activeKey === 'readNotice')}
                      </span>
                    ),
                  },
                ],
                onChange(key) {
                  setActiveKey(key);
                },
              },
            }}
          />
        </div>
      </Modal>
    </div>
  );
};
