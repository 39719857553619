/* eslint-disable guard-for-in */
import { queryStudents } from './service';

const Model = {
  namespace: 'salesLeads',
  state: {
    students: [],
    creatingStudent: false,
  },
  effects: {
    *queryStudents({ payload }, { call, put }) {
      const students = yield call(queryStudents, {});

      yield put({
        type: 'updateState',
        payload: {
          students,
        },
      });
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
export default Model;
