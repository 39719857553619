import { request } from '@@/plugin-request';

export async function query(params) {
  return request('/api/datacenter/headTeacherStudentChart', {
    params,
  });
}

export async function getAllTeams(params) {
  return request('/api/team', {
    params,
  });
}

export async function getAllHeadTeachers(params) {
  return request('/api/users/headTeacher', {
    params,
  });
}

export async function download(params) {
  return request('/api/datacenter/download', {
    method: 'POST',
    data: params,
  });
}
