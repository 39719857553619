import { request } from '@@/plugin-request';

export async function create(params) {
  return request('/api/attendances', {
    method: 'POST',
    data: params,
  });
}

export async function submitAssignment(params) {
  return request('/api/submissions', {
    method: 'PUT',
    data: params,
  });
}

export async function createVideoLog(payload) {
  return request('/api/learningprogress/videolog', {
    method: 'POST',
    data: payload,
  });
}

export async function watchCourse(id) {
  return request(`/api/registrations/${id}`);
}
