import { timezoneOptions } from '@/utils/tz';
import { cn } from '@h6you/timezone-names-cn';
import { Button, message, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import { htmlToText } from 'html-to-text';
import { useCallback, useEffect, useRef, useState } from 'react';

export const generateTime = (startAt, endAt, timezone) => {
  const date = dayjs.utc(startAt).toDate().toLocaleDateString('zh-cn', {
    timeZone: timezone,
    year: 'numeric',
    month: 'long',
    weekday: 'long',
    day: 'numeric',
  });
  const start = dayjs
    .utc(startAt)
    .toDate()
    .toLocaleTimeString('zh-cn', { timeZone: timezone, hour: '2-digit', minute: '2-digit' });
  const end = dayjs
    .utc(endAt)
    .toDate()
    .toLocaleTimeString('zh-cn', { timeZone: timezone, hour: '2-digit', minute: '2-digit' });

  return `${date} ${start} - ${end}`;
};

function dataToHtml(lesson, selectedTimezones) {
  return (
    <>
      <h4>## 课程提醒 ##</h4>
      <p>课程名: {lesson.name}</p>
      <div>
        上课时间:
        {selectedTimezones.map((tz) => {
          const zhName = tz === 'Asia/Shanghai' ? '亚洲/北京' : cn[tz];
          return (
            <div style={{ marginLeft: '1em' }} key={tz}>
              {zhName || tz}时间: {generateTime(lesson.startAt, lesson.endAt, tz)}
            </div>
          );
        })}
      </div>
      <br />
      <div>
        课程登陆地址
        <div style={{ marginLeft: '1em' }}>学员端: https://wisemind.vip</div>
      </div>
      <br />
      <div style={{ marginTop: '1em' }}>课程结束后60分钟教室会自动关闭，请注意时间~</div>
      <div>
        请
        {lesson.teachers
          ?.map((t) => {
            return t.nickname || `${t.lastName}${t.firstName}`;
          })
          .join(', ')}
        老师下课后及时发送课后反馈哟~
      </div>
    </>
  );
}

const LessonInfoModal = ({ lesson, closeModal, ...props }) => {
  if (!lesson || !lesson.name) return <></>;
  const textRef = useRef();
  const [selectedTimezones, setTimezones] = useState(['Asia/Shanghai']);
  useEffect(() => {
    const defaultTz = new Set(['Asia/Shanghai']);
    if (lesson.student?.timezone) {
      defaultTz.add(lesson.student.timezone);
    }
    lesson.teachers?.forEach?.((t) => {
      if (t.timezone) {
        defaultTz.add(t.timezone);
      }
    });
    if (lesson.timezone) {
      defaultTz.add(lesson.timezone);
    }
    setTimezones([...defaultTz]);
  }, [lesson.id]);

  const generateHtml = useCallback(() => {
    return dataToHtml(lesson, selectedTimezones);
  }, [lesson.id, selectedTimezones]);

  return (
    <Modal
      {...props}
      width={550}
      onCancel={closeModal}
      footer={[
        <Button
          key="copy"
          onClick={() => {
            const text = htmlToText(textRef.current.outerHTML);
            navigator.clipboard.writeText(text);
            message.success('已复制');
          }}
          type="primary"
          ghost
        >
          点击复制以上信息
        </Button>,
        <Button key="cancel" onClick={closeModal} type="primary">
          知道了
        </Button>,
      ]}
    >
      <>
        <div style={{ textAlign: 'center' }}>
          请选择时区呈现:
          <Select
            mode="multiple"
            value={selectedTimezones}
            onChange={(values) => {
              setTimezones(values);
            }}
            placeholder="选择时区"
            style={{ width: '50%', marginLeft: '1em' }}
            optionFilterProp="label"
            options={timezoneOptions}
          />
          <p>
            <small>点击【更新档案】记录同学的时区信息，下次就会自动选中哦~</small>
          </p>
        </div>
        <br />
        <div ref={textRef} style={{ marginLeft: 20 }}>
          {generateHtml()}
        </div>
      </>
    </Modal>
  );
};

export default LessonInfoModal;
