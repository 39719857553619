import { getPaycheck } from '@/components/Salary/service';
import {
  getOwnerSalarySettings,
  getWallet,
  getWithdraw,
  getWithdrawLimit,
} from '@/components/Wallet/service';

const Model = {
  namespace: 'wallet',
  state: {
    owner: {},
    wallets: [],
    withdraws: [],
    adminVerifyPending: [],
    limits: {},
    pendingStats: {},
    withdrawStats: {},
  },
  effects: {
    *init({ payload = {} }, { put }) {
      yield put({
        type: 'fetchOwner',
        payload,
      });
      yield put({
        type: 'fetchWallet',
        payload,
      });
      yield put({
        type: 'fetchAdminVerifyPending',
        payload,
      });
      yield put({
        type: 'fetchWithdrawHistory',
        payload,
      });
      yield put({
        type: 'fetchWithdrawLimit',
        payload,
      });
    },

    *fetchOwner({ payload }, { call, put }) {
      const data = yield call(getOwnerSalarySettings, {
        id: payload?.ownerId,
      });
      yield put({
        type: 'save',
        payload: {
          owner: data,
        },
      });
    },

    *fetchWallet({ payload }, { call, put }) {
      const data = yield call(getWallet, {
        ownerId: payload?.ownerId,
      });
      yield put({
        type: 'save',
        payload: {
          wallets: data,
        },
      });
    },

    *fetchAdminVerifyPending({ payload }, { call, put }) {
      const data = yield call(getPaycheck, {
        ownerId: payload?.ownerId,
        status: 'adminVerifyPending',
      });
      const pendingStats = {};
      data?.forEach?.((d) => {
        if (!d.error) {
          pendingStats[d.content] = pendingStats[d.content] || 0;
          pendingStats[d.content] += d.amount;
        }
      });
      yield put({
        type: 'save',
        payload: {
          adminVerifyPending: data,
          pendingStats,
        },
      });
    },

    *fetchWithdrawHistory({ payload }, { call, put }) {
      const data = yield call(getWithdraw, {
        ownerId: payload?.ownerId,
        status: ['提取中', '已处理', '打款失败'],
      });
      const withdrawStats = {};
      data?.forEach?.((d) => {
        withdrawStats[d.payoutCurrency] = withdrawStats[d.payoutCurrency] || 0;
        withdrawStats[d.payoutCurrency] += d.payoutAmount;
      });
      yield put({
        type: 'save',
        payload: {
          withdraws: data,
          withdrawStats,
        },
      });
    },

    *fetchWithdrawLimit(_, { call, put }) {
      const data = yield call(getWithdrawLimit);
      const limits = {};
      data.forEach((d) => {
        limits[d.currency] = {
          min: d.min,
          max: d.max,
        };
      });
      yield put({
        type: 'save',
        payload: {
          limits,
        },
      });
    },

    *onWithdrawSubmitted({ payload }, { put }) {
      yield put({
        type: 'fetchWallet',
        payload,
      });
      yield put({
        type: 'fetchWithdrawHistory',
        payload,
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    clear() {
      return {
        owner: {},
        wallets: [],
        withdraws: [],
        adminVerifyPending: [],
        limits: {},
        pendingStats: {},
        withdrawStats: {},
      };
    },
  },
};
export default Model;
