import { Select } from 'antd';
import { getAllCourses } from '../Course/service';
import { getConnectedLessonsByParentLessonId } from './service';

const { Option } = Select;

const Model = {
  namespace: 'lesson',
  state: {
    connectedLessonDrawerShow: false,
    lessonItem: null,
    cardData: [],
    allCourses: [],
  },
  effects: {
    *getAllCourses({ payload }, { call, put }) {
      const data = yield call(getAllCourses, payload);
      yield put({
        type: 'save',
        payload: {
          allCourses: [...data],
        },
      });
    },

    *initializeCardData({ payload }, { call, put, select }) {
      const id = yield select((state) => state.lesson.lessonItem?.id);
      const cardData = yield select((state) => state.lesson.cardData);
      if (id) {
        const connectedLessons = yield call(getConnectedLessonsByParentLessonId, { id });
        for (const item of connectedLessons) {
          cardData.push({
            course: item.childLesson?.course?.id,
            lesson: item.childLesson?.id,
            video: item.video,
            document: item.document,
          });
        }
      }
      yield put({
        type: 'updateCardData',
        payload: {
          cardData,
        },
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },

    clear(state) {
      return {
        ...state,
        connectedLessonDrawerShow: false,
        lessonItem: null,
        cardData: [],
        // allCourses: [],
      };
    },

    updateCardData(state, { payload }) {
      return { ...state, cardData: [...payload.cardData] };
    },
  },
};

export default Model;
