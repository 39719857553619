import { request } from '@@/plugin-request';

export async function getSalaryAdjustment(params) {
  return request('/api/salary/adjustment', {
    method: 'get',
    params,
  });
}

export async function createSalaryAdjustment(payload) {
  return request('/api/salary/adjustment', {
    method: 'post',
    data: payload,
  });
}

export async function updateSalaryAdjustment(payload) {
  return request('/api/salary/adjustment', {
    method: 'put',
    data: payload,
  });
}

export async function createSalaryAllocation(payload) {
  return request('/api/salary/allocation', {
    method: 'post',
    data: payload,
  });
}

export async function updateSalaryAllocation(payload) {
  return request('/api/salary/allocation', {
    method: 'put',
    data: payload,
  });
}

export async function getSalaryAllocation(params) {
  return request('/api/salary/allocation', {
    method: 'get',
    params,
  });
}

export async function getPaycheck(params) {
  return request('/api/salary/paycheck', {
    method: 'get',
    params,
  });
}

export async function teacherVerifyPaycheck(params) {
  return request('/api/salary/paycheck', {
    method: 'put',
    data: {
      action: 'teacherVerify',
      ...params,
    },
  });
}

export async function adminVerifyPaycheck(id) {
  return request('/api/salary/paycheck', {
    method: 'put',
    data: {
      action: 'adminVerify',
      id,
    },
  });
}

export async function verifyAndReleaseLater(id) {
  return request('/api/salary/paycheck', {
    method: 'put',
    data: {
      action: 'verifyAndReleaseLater',
      id,
    },
  });
}

export async function releasePaycheck(id) {
  return request('/api/salary/paycheck', {
    method: 'put',
    data: {
      action: 'release',
      id,
    },
  });
}

export async function getSubsidy(params) {
  return request('/api/salarySubsidy/subsidy', {
    method: 'get',
    params,
  });
}

export async function createSubsidy(payload) {
  return request('/api/salarySubsidy/subsidy', {
    method: 'post',
    data: payload,
  });
}

export async function updateSubsidy(payload) {
  return request('/api/salarySubsidy/subsidy', {
    method: 'put',
    data: payload,
  });
}

export async function getAllocation(params) {
  return request('/api/salarySubsidy/allocation', {
    method: 'get',
    params,
  });
}

export async function getInProgressSubsidy(params) {
  return request('/api/salarySubsidy/inProgressSubsidy', {
    method: 'get',
    params,
  });
}

//更新课节计算系数
export async function updateLessonCoefficient(payload) {
  return request('/api/salary/teacher/lessonCoefficient', {
    method: 'put',
    data: payload,
  });
}
