import PlayerDatabase from '@/components/SavvyPlayer/utils/db';
import { createVideoLog } from '@/pages/TrainingCourse/MyTrainingCourse/service';
import { getTodos } from '@/services/onboarding';
import { useCallback, useEffect, useState } from 'react';
import { useModel } from 'umi';

export default function useOnboardingModel() {
  const { initialState, initialStateLoading } = useModel('@@initialState', (model) => ({
    initialState: model.initialState,
    initialStateLoading: model.loading,
  }));
  const [todos, setTodos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [receivingAccountVisible, setReceivingAccountVisible] = useState(false);
  const [erpAccountVisible, setErpAccountVisible] = useState(false);

  const refreshTodos = useCallback(async () => {
    setLoading(true);
    try {
      const todos = await getTodos();
      setTodos(todos);
      if (todos && todos.length) {
        const receivingAccountVisible = todos.some((td) => td.name === '提供收款信息');
        const erpAccountVisible = todos.some(
          (td) => td.name === '上传签署合同' || td.name === '填写自我介绍',
        );
        setReceivingAccountVisible(receivingAccountVisible);
        setErpAccountVisible(erpAccountVisible);
        setModalVisible(receivingAccountVisible || erpAccountVisible);
      } else {
        setReceivingAccountVisible(false);
        setErpAccountVisible(false);
        setModalVisible(false);
      }
      return todos;
    } catch (err) {
      setTodos();
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  const syncVideoWatchLogs = async () => {
    if (PlayerDatabase) {
      try {
        const data = await PlayerDatabase.videos.toArray();
        if (data?.length > 0) {
          await createVideoLog({
            data,
            player: 'videojs',
            url: window.location.href,
          });
          await PlayerDatabase.videos.clear();
        }
      } catch (error) {
        console.error('Error:', error);
        console.error('Stack trace:', error.stack);
      }
    }
  };

  useEffect(() => {
    if (!initialStateLoading && initialState?.currentUser) {
      refreshTodos();
      syncVideoWatchLogs();
    }
  }, [initialStateLoading]);

  return {
    todos,
    setTodos,
    refreshTodos,
    loading,
    modalVisible,
    setModalVisible,
    receivingAccountVisible,
    erpAccountVisible,
  };
}
