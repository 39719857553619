import { getUploadParams } from '@/components/Upload/service';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { useState } from 'react';

export default ({ onChange, value, buttonText, ...rest }) => {
  const [ossData, setOssData] = useState();
  const [fileList, setFileList] = useState(Array.isArray(value) ? value : !!value ? [value] : []);

  const getOSSAuth = async (file) => {
    if (!ossData || new Date().getTime() > ossData.expire) {
      const res = await getUploadParams();
      setOssData(res);
      return {
        key: `${res.dir}/${file.name}`,
        OSSAccessKeyId: res.accessId,
        policy: res.policy,
        Signature: res.signature,
      };
    } else {
      return {
        key: `${ossData.dir}/${file.name}`,
        OSSAccessKeyId: ossData.accessId,
        policy: ossData.policy,
        Signature: ossData.signature,
      };
    }
  };
  const onUploadChange = ({ file, fileList }) => {
    setFileList(fileList);
    if (onChange && typeof onChange === 'function') {
      if (file.status === 'done') {
        if (rest.maxCount === 1) {
          onChange({
            ...file,
            key: `${ossData.dir}/${file.name}`,
          });
        } else {
          onChange(
            fileList.map((f) => {
              return {
                ...f,
                key: `${ossData.dir}/${f.name}`,
              };
            }),
          );
        }
      } else if (file.status === 'removed') {
        if (rest.maxCount === 1) {
          onChange(null);
        } else {
          onChange(fileList);
        }
      }
    }
  };
  return (
    <Upload
      action="https://savvyuni-intl-erp.oss-accelerate.aliyuncs.com"
      data={getOSSAuth}
      fileList={fileList}
      onChange={onUploadChange}
      {...rest}
    >
      <Button icon={<UploadOutlined />}>{buttonText || '点击上传'}</Button>
    </Upload>
  );
};
