import { getByUser, query as queryLessons } from '@/pages/Lesson/service';
import { create, update, updateByUser } from './service';

const AssignmentModel = {
  namespace: 'assignment',
  state: {
    lessonSearchValue: '',
    lessonData: [],

    // table pagination
    current: 1,
    pageSize: 15,
    total: 0,
  },

  effects: {
    *update({ payload }, { call, put }) {
      const response = yield call(update, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            updatedAssignment: response,
          },
        });
      }
    },

    *updateByUser({ payload }, { call, put }) {
      const response = yield call(updateByUser, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            marking: false,
          },
        });
      }
    },

    *create({ payload }, { call, put }) {
      const response = yield call(create, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            createdAssignment: response,
          },
        });
      }
    },

    *queryLessons({ payload }, { call, put, select }) {
      const searchValue = yield select((state) => state.assignment.lessonSearchValue);
      const current = yield select((state) => state.assignment.current);
      const pageSize = yield select((state) => state.assignment.pageSize);

      const query = {
        searchValue,
        current,
        pageSize,
      };

      const response = yield call(queryLessons, query);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            lessonData: response.lessons,
            total: response.count,
          },
        });
      }
    },

    *getByUser({ payload }, { call, put }) {
      const response = yield call(getByUser, { ...payload, assignment: true });

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            lessonData: response,
          },
        });
      }
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default AssignmentModel;
