import { queryById } from './service';

const model = {
  namespace: 'contract',
  state: {
    currentContract: {},
    drawerVisible: false,
  },
  effects: {
    *getById({ payload }, { call, put }) {
      const response = yield call(queryById, payload.id);
      if (response) {
        yield put({
          type: 'save',
          payload: {
            currentContract: response.data,
            drawerVisible: true,
          },
        });
      } else {
        yield put({
          type: 'save',
          payload: {
            currentContract: {},
            drawerVisible: false,
          },
        });
      }
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
