const countryCodes = {
  1: {
    label: '北美',
    length: 10,
    pattern: /^\d{10}$/,
    localNumber: '12044802409',
  },

  86: {
    label: '中国',
    length: 11,
    pattern: /^\d{11}$/,
  },

  44: {
    label: '英国',
    length: 10,
    pattern: /^\d{10}$/,
  },

  61: {
    label: '澳大利亚',
    length: 9,
    pattern: /^\d{9}$/,
    localNumber: '61480050916',
  },

  64: {
    label: '新西兰',
    length: '8-10',
    pattern: /^\d{8,10}$/,
    localNumber: '61480050916',
  },

  65: {
    label: '新加波',
    length: 8,
    pattern: /^\d{8}$/,
  },

  852: {
    label: '香港',
    length: 8,
    pattern: /^\d{8}$/,
  },

  886: {
    label: '台湾',
    length: 10,
    pattern: /^\d{10}$/,
  },

  81: {
    label: '日本',
    length: 10,
    pattern: /^\d{10}$/,
  },

  60: {
    label: '马来西亚',
  },
};

const verifyFormat = (countryCode, phone) => {
  const format = countryCodes[countryCode];

  if (!format.length || !format.pattern) return true;

  if (format && phone && phone.match(format.pattern)) {
    return true;
  }

  return false;
};

module.exports = {
  verifyFormat,
  countryCodes,
};
