/* eslint-disable guard-for-in */
import {
  createProductService,
  queryProduct,
  queryProductAttribute,
  queryProductService,
} from './service';

const Model = {
  namespace: 'product',
  state: {
    serviceModalVisible: false,
    currentService: {},
    createdServices: [],
    selectedServices: [],
    serviceList: [],
    serviceCount: 0,
    attributeModalVisible: false,
    currentAttribute: {},
    productModalVisible: false,
    currentProduct: {},
  },
  effects: {
    *queryProduct({ params }, { call, put }) {
      const res = yield call(queryProduct, params);
      yield put({
        type: 'save',
        payload: {
          productList: res,
        },
      });
    },

    *queryAttribute({ params }, { call, put }) {
      const res = yield call(queryProductAttribute, params);
      yield put({
        type: 'save',
        payload: {
          attributeList: res,
        },
      });
    },

    *queryService({ params }, { call, put }) {
      const res = yield call(queryProductService, params);
      yield put({
        type: 'save',
        payload: {
          serviceList: res.services,
          serviceCount: res.count,
        },
      });
    },

    *createService({ payload }, { call, put, select }) {
      const created = yield select((state) => state.product.createdService);
      const res = yield call(createProductService, payload);
      yield put({
        type: 'save',
        payload: {
          createdService: [...created, res],
        },
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },

    clear() {
      return {
        serviceModalVisible: false,
        currentService: {},
        attributeModalVisible: false,
        currentAttribute: {},
        productModalVisible: false,
        currentProduct: {},
      };
    },
  },
};
export default Model;
