import { request } from '@@/plugin-request';

export async function queryByHeadTeacher(id, params) {
  return request(`/api/contracts/headteacher/${id}`, {
    method: 'GET',
    params,
  });
}

export async function queryByStudent(id, params) {
  return request(`/api/contracts/student/${id}`, {
    method: 'GET',
    params,
  });
}
