import ContractSelect from '@/components/DropdownSelect/Contract';
import SearchSelect from '@/components/DropdownSelect/Course/SearchSelect';
import HeadTeacherSelect from '@/components/DropdownSelect/HeadTeacher';
import CustomSelect from '@/components/DropdownSelect/ProductServiceItem';
import CustomDatePicker from '@/components/FormItems/DatePicker';
import AttributeSelect from '@/pages/Lesson/components/AttributeSelect';
import { getSemesterList } from '@/pages/School/Components/SemesterList';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  ProForm,
  ProFormCheckbox,
  ProFormDependency,
  ProFormGroup,
  ProFormRadio,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
} from '@ant-design/pro-components';
import { Cascader, Form, Input, InputNumber, Select, Spin, Tooltip, message } from 'antd';
import { parseInt } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import PreviousTeacherSelect from './PreviousTeacherSelect';
import {
  academicPerformanceRadioArray,
  reasonCascaderArray,
  riskLevelRadioArray,
  typeSelectArray,
  typesToFields,
} from './options';
import { create, getContractById, getSchools, getStudentSensitiveInfo, update } from './service';

const CreateCourseMatch = ({
  setCourseMatchModalVisible,
  setCourseMatchRecord,
  setCourseMatchInfoModalVisible,
  edit,
  record,
  refreshTable,
  studentSemesterCourse,
}) => {
  const {
    courseNo: courseNo_ssc,
    courseName: courseName_ssc,
    courseId: courseId_ssc,
    studentId: studentId_ssc,
    semesterId: semesterId_ssc,
    semesterName: semesterName_ssc,
    schoolId: schoolId_ssc,
    schoolName: schoolName_ssc,
    courseCustomizedName: courseCustomizedName_ssc,
    contractOptions,
  } = studentSemesterCourse ?? {};

  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [contractData, setContractData] = useState();
  const [paSelected, setPaSelected] = useState([]);
  // used for getting school options
  const [schoolsData, setSchools] = useState([]);
  // 因为onValuesChange 无法re-render
  const [selectedSchools, setSelectedSchools] = useState([]);

  useEffect(() => {
    if (edit) {
      getContractById(record?.contract?.[0]?.id).then((_contractData) => {
        setContractData(_contractData);
      });
    }
  }, []);

  const getSchoolsFunc = async () => {
    const data = await getSchools();
    setSchools(data);
  };

  useEffect(() => {
    getSchoolsFunc();
  }, []);

  let schoolOptions = [];

  if (schoolsData?.length) {
    const ret = schoolsData?.map?.((elem) => {
      let label = elem.name;
      if (elem.country) {
        label += ` (${elem.country})`;
      }
      return {
        value: elem.id,
        label,
      };
    });
    schoolOptions = [...ret, { label: '无', value: 0 }];
  }

  const initialValues = edit
    ? {
        headTeacher: record?.headTeacher?.id,
        contract: record?.contract?.[0]?.id, // 未完成匹配只有一个合同
        productAttribute: [
          {
            value: record?.productAttribute?.id,
            label: record?.productAttribute?.name,
          },
        ],
        riskLevel: record?.student?.riskLevel,
        riskLevelNotes: record?.student?.riskLevelNotes,
        schools: !!record.course?.school
          ? [{ label: record.course?.school?.name, value: record.course?.school?.id }]
          : record.semester?.pattern?.school?.id
            ? [record.semester?.pattern?.school?.id]
            : [],
        semester: record.semester?.id,
        course: record?.course
          ? {
              value: record?.course?.id,
              label: `【${record?.course?.courseNo || '未知课号'}】${record?.course?.name} [学校：${
                record?.course?.school?.name || '无'
              }] [学术等级：${record?.course?.academicLevel || '未知'}] [课程类型：${
                record?.course?.type || '未知'
              }]`,
            }
          : null,
        serviceItem: record?.serviceItem
          ? {
              value: record?.serviceItem?.id,
              label: `【${record?.serviceItem?.academicLevel || '无学术等级'}】${
                record?.serviceItem?.name
              }`,
            }
          : null,
        courseNo: record?.courseCustomizedName?.split(' - ')?.[0],
        courseName: record?.courseCustomizedName?.split(' - ')?.[1],
        serviceItemCustomizedName: record?.serviceItemCustomizedName,
        types: record?.courseMatchType?.map((t) => t.type),
        examDate: record?.courseMatchType?.filter((t) => t.type === '考试辅导')?.[0]?.examDate
          ?.timestamp,
        essayDeadline: record?.courseMatchType?.filter((t) => t.type === '论文辅导')?.[0]
          ?.essayDeadline?.timestamp,
        essayWordCount: record?.courseMatchType?.filter((t) => t.type === '论文辅导')?.[0]
          ?.essayWordCount,
        assignmentDeadline: record?.courseMatchType?.filter((t) => t.type === '作业辅导')?.[0]
          ?.assignmentDeadline?.timestamp,
        reason: [record?.courseMatchReason?.[0]?.category, record?.courseMatchReason?.[0]?.reason],
        reasonNotes: record?.courseMatchReason?.[0]?.reasonNotes,
        previousTeacher: record?.courseMatchReason?.[0]?.previousTeacher
          ? {
              value: record?.courseMatchReason?.[0]?.previousTeacher?.id,
              label: record?.courseMatchReason?.[0]?.previousTeacher?.chineseName
                ? `${record?.courseMatchReason?.[0]?.previousTeacher?.chineseName}`
                : `${record?.courseMatchReason?.[0]?.previousTeacher?.lastName}${record?.courseMatchReason?.[0]?.previousTeacher?.firstName}`,
            }
          : null,
        lessonFrequency: record?.lessonFrequency,
        idealFirstLessonDate: record?.idealFirstLessonDate,
        academicPerformance: record?.student?.academicPerformance,
        academicPerformanceNotes: record?.student?.academicPerformanceNotes,
        headTeacherNotes: record?.headTeacherNotes,
        latestMatchTime: record?.latestMatchTime,
        needQualityCheck: record?.needQualityCheck,
      }
    : {};

  const onModalSubmit = async (values) => {
    const headTeacherFromContractOption =
      contractOptions &&
      values.contract &&
      contractOptions.find((ele) => ele.value === values.contract).headTeacherId;
    values.headTeacher = values.headTeacher || headTeacherFromContractOption;
    // 对于兑换后的产品属性的key是123-123格式的兼容
    let _productAttribute = [];
    if (Array.isArray(values.productAttribute)) {
      for (let pa of values.productAttribute) {
        pa = pa.value || pa;
        if (typeof pa === 'string') {
          pa = Number(pa.split('-')?.[1]);
        }
        _productAttribute.push(pa);
      }
    } else {
      _productAttribute = values.productAttribute?.value || values.productAttribute;
      if (typeof _productAttribute === 'string') {
        _productAttribute = Number(_productAttribute.split('-')?.[1]);
      }
    }
    const body = {
      ...values,
      types: values.types?.map((type) => {
        let _result = {
          type: type,
        };
        typesToFields[type]?.forEach((elem) => {
          _result = {
            ..._result,
            [elem]: values[elem] || null,
          };
        });
        return _result;
      }),
      // reasons: values.reasons?.map((reason) => {
      //   return {
      //     category: reason[0],
      //     reason: reason[0] === '新老师匹配' ? reason[0] : reason[1],
      //     reasonNotes: null,
      //   };
      // }),
      reasonCategory: values.reason[0],
      reason: values.reason[0] === '新老师匹配' ? values.reason[0] : values.reason[1],
      reasonNotes: values.reasonNotes,
      courseCustomizedName: !!values.course
        ? null
        : values.courseNo || values.courseName
          ? `${values.courseNo || ''} - ${values.courseName || ''}`
          : courseCustomizedName_ssc,
      serviceItemCustomizedName: values.serviceItemCustomizedName,
      productAttribute: _productAttribute,
      course: courseId_ssc || values.course?.value || values.course,
      serviceItem: values.serviceItem?.value || values.serviceItem,
      qualityInspector: values.qualityInspector?.value || values.qualityInspector,
      teacher: values.teacher?.value || values.teacher,
      studentSemesterCourse: studentSemesterCourse?.id,
      semester: semesterId_ssc || values.semester,
      headTeacher:
        contractOptions?.find((ele) => ele.value === values.contract)?.headTeacherId ||
        values.headTeacher,
    };
    try {
      if (edit) {
        setLoading(true);
        const res = await update({
          id: record?.id,
          edit: { ...body },
        });
        if (res.msg) {
          message.warning(res.msg);
          setLoading(false);
          return;
        }
        message.success('更新匹配记录成功');
        setLoading(false);
        setCourseMatchModalVisible(false);
        refreshTable?.();
      } else {
        setLoading(true);
        const res = await create({
          ...body,
        });
        if (res.msg) {
          setLoading(false);
          message.warning(res.msg);
          return;
        }
        message.success('新建匹配记录成功');
        setLoading(false);
        setCourseMatchModalVisible(false);
        setCourseMatchRecord(res);
        setCourseMatchInfoModalVisible(true);
      }

      return true;
    } catch (error) {
      if (edit) {
        message.error('更新失败请重试！');
      } else {
        message.error('新建失败请重试！');
      }
      setLoading(false);
      return false;
    }
  };

  return (
    <Spin spinning={loading} size="large" tip="努力地加载数据中...">
      <ProForm
        formRef={formRef}
        initialValues={initialValues}
        onFinish={onModalSubmit}
        onValuesChange={async (changedValues) => {
          if (changedValues.courseNo || changedValues.courseName) {
            // formRef.current?.resetFields(['course']);
            formRef.current?.setFieldsValue({
              course: null,
            });
          } else if (changedValues.serviceItemCustomizedName) {
            // formRef.current?.resetFields(['serviceItem']);
            formRef.current?.setFieldsValue({
              serviceItem: null,
            });
          } else if (
            changedValues.headTeacher &&
            !!contractData &&
            changedValues.headTeacher !== contractData?.headTeacher?.id
          ) {
            setContractData(null);
            setPaSelected([]);
            // formRef.current?.resetFields([
            // 'contract',
            // 'course',
            // 'courseNo',
            // 'courseName',
            // 'serviceItem',
            // 'serviceItemCustomizedName',
            // 'productAttribute',
            // 'riskLevel',
            // 'riskLevelNotes',
            // 'academicPerformance',
            // 'academicPerformanceNotes',
            // ]);
            formRef.current?.setFieldsValue({
              contract: null,
              course: null,
              courseNo: null,
              courseName: null,
              serviceItem: null,
              serviceItemCustomizedName: null,
              productAttribute: [],
              riskLevel: null,
              riskLevelNotes: null,
              academicPerformance: null,
              academicPerformanceNotes: null,
            });
          } else if (changedValues.course) {
            // formRef.current?.resetFields([
            //   'courseNo',
            //   'courseName',
            //   'reason',
            //   'previousTeacher',
            //   'reasonNotes',
            // ]);
            formRef.current?.setFieldsValue({
              courseNo: null,
              courseName: null,
              reason: null,
              previousTeacher: [],
              reasonNotes: null,
            });
          } else if (changedValues.serviceItem) {
            // formRef.current?.resetFields([
            //   'serviceItemCustomizedName',
            //   'reason',
            //   'previousTeacher',
            //   'reasonNotes',
            // ]);
            formRef.current?.setFieldsValue({
              serviceItemCustomizedName: null,
              reason: null,
              previousTeacher: [],
              reasonNotes: null,
            });
          } else if (changedValues.contract) {
            // formRef.current?.resetFields([
            //   'productAttribute',
            //   'course',
            //   'courseNo',
            //   'courseName',
            //   'serviceItem',
            //   'serviceItemCustomizedName',
            //   'riskLevel',
            //   'riskLevelNotes',
            //   'academicPerformance',
            //   'academicPerformanceNotes',
            // ]);
            setPaSelected([]);
            formRef.current?.setFieldsValue({
              productAttribute: [],
              course: null,
              courseNo: null,
              courseName: null,
              serviceItem: null,
              serviceItemCustomizedName: null,
              riskLevel: null,
              riskLevelNotes: null,
              academicPerformance: null,
              academicPerformanceNotes: null,
            });
            const _contractData = await getContractById(changedValues.contract);
            setContractData(_contractData);
            const _studentRiskData = await getStudentSensitiveInfo({
              id: _contractData?.student?.id,
              sensitive: true,
            });
            const schools =
              _contractData?.student?.educations
                ?.filter?.((el) => !!el.school)
                .map((el) => {
                  return {
                    label: el.school.name,
                    value: el.school.id,
                  };
                }) || null;
            const lastestRiskInfo = _studentRiskData.infoRecords
              .filter((ele) => ele.type === '风险等级')
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))?.[0];

            formRef.current?.setFieldsValue({
              riskLevel: lastestRiskInfo?.riskRating,
              riskLevelNotes: lastestRiskInfo?.riskRatingNotes,
              academicPerformance: _studentRiskData?.academicPerformance,
              academicPerformanceNotes: _studentRiskData?.academicPerformanceNotes,
              schools,
            });
            // 因为改变school会改变semesterOptions，然后setFieldValues不会trigger proformDependency/re-render,所以这里通过setState来trigger re-render
            setSelectedSchools(schools);
          } else if (changedValues.productAttribute) {
            // formRef.current?.resetFields(['course', 'serviceItem']);
            const _paRecords = contractData?.products?.map((p) => p.productAttributes).flat();
            const _paSelected = Array.isArray(changedValues.productAttribute)
              ? changedValues.productAttribute.map((selectedPa) =>
                  _paRecords?.find?.((pa) => pa?.id === selectedPa),
                )
              : _paRecords?.find?.(
                  (pa) =>
                    pa?.id ==
                    (changedValues.productAttribute.value || changedValues.productAttribute),
                );
            setPaSelected(_paSelected);
            formRef.current?.setFieldsValue({
              course: null,
              serviceItem: null,
            });
          }
        }}
      >
        {contractOptions ? (
          <>
            <ProFormDependency name={['contract']}>
              {({ contract }) => {
                const selectedContract = contract
                  ? contractOptions.find((ele) => ele.value === contract)
                  : null;
                if (!selectedContract) {
                  return null;
                }
                return (
                  <ProFormSelect
                    label="合同班主任"
                    disabled={true}
                    value={selectedContract.headTeacherId}
                    options={
                      selectedContract
                        ? [
                            {
                              value: selectedContract?.headTeacherId,
                              label: selectedContract?.headTeacherName,
                            },
                          ]
                        : []
                    }
                  ></ProFormSelect>
                );
              }}
            </ProFormDependency>
            <Form.Item
              name="contract"
              label="可搜索合同"
              rules={[
                {
                  required: true,
                  message: '请选择排课合同',
                },
              ]}
            >
              <ContractSelect
                disabled={!!edit}
                student={studentSemesterCourse?.studentId}
                ongoing={true}
                category="一对一"
              />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              name="headTeacher"
              label="合同班主任"
              rules={[
                {
                  required: true,
                  message: '请选择班主任',
                },
              ]}
            >
              <HeadTeacherSelect disabled={!!edit} defaultActiveCurrentUser={!edit} />
            </Form.Item>

            <ProFormDependency name={['headTeacher']}>
              {({ headTeacher }) => {
                return (
                  <Form.Item
                    name="contract"
                    label="可搜索合同"
                    rules={[
                      {
                        required: true,
                        message: '请选择排课合同',
                      },
                    ]}
                  >
                    <ContractSelect
                      disabled={!!edit}
                      headTeacher={headTeacher}
                      ongoing={true}
                      category="一对一"
                    />
                  </Form.Item>
                );
              }}
            </ProFormDependency>
          </>
        )}
        <ProFormDependency name={['contract']}>
          {({ contract }) => {
            return (
              <Form.Item
                name="productAttribute"
                label={
                  <Tooltip
                    title="支持多选：仅最多可以选择一个课程+一个服务项"
                    overlayStyle={{
                      maxWidth: '400px',
                    }}
                  >
                    产品属性 <InfoCircleOutlined />
                  </Tooltip>
                }
                rules={[
                  {
                    required: true,
                    message: '请选择产品属性',
                  },
                  // {
                  //   validator: (rule, value, callback, ...rest) => {
                  //     if (paSelected?.filter((pa) => pa.type !== '学术').length >= 1) {
                  //       message.info('已选择一个服务项，若想选择其他服务项请取消选择当前服务项');
                  //       return Promise.resolve();
                  //     }
                  //   },
                  // },
                ]}
              >
                {!!contractData ? (
                  <AttributeSelect
                    // coursematch="true"
                    multiple={!edit}
                    maxSelectCount={2}
                    paSelected={paSelected}
                    contract={contractData}
                    disabled={!contract}
                  />
                ) : (
                  <Select placeholder="请先选择合同" disabled={true} />
                )}
              </Form.Item>
            );
          }}
        </ProFormDependency>
        <ProFormGroup>
          <ProFormRadio.Group
            name="riskLevel"
            label="学生风险等级"
            options={riskLevelRadioArray}
            // fieldProps={{
            //   defaultValue: null,
            // }}
            rules={[
              {
                required: true,
                message: '请选择风险等级',
              },
            ]}
          />
          <ProFormDependency name={['riskLevel']}>
            {({ riskLevel }) => {
              return (
                <ProFormTextArea
                  label="原因"
                  name="riskLevelNotes"
                  fieldProps={{
                    autoSize: true,
                  }}
                  placeholder="请填写原因..."
                  rules={[
                    {
                      required: riskLevel === '高',
                      message: '请注明高风险原因',
                    },
                  ]}
                />
              );
            }}
          </ProFormDependency>
        </ProFormGroup>

        <ProFormDependency
          name={['contract', 'schools', 'productAttribute', 'serviceItemCustomizedName']}
        >
          {({ contract, schools, productAttribute, serviceItemCustomizedName }) => {
            if (!productAttribute) return;
            const result = [];
            const paArray = Array.isArray(productAttribute) ? productAttribute : [productAttribute];
            for (let pa of paArray) {
              if (result.length >= 2) continue;
              let _productAttribute = pa?.value || pa;
              if (typeof _productAttribute === 'string') {
                _productAttribute = Number(_productAttribute.split('-')?.[1]);
              }
              const paRecord = contractData?.products
                ?.map((p) => p.productAttributes)
                .flat()
                .find?.((pa) => pa?.id === _productAttribute);
              const isCourse = paRecord?.type === '学术';

              if (isCourse) {
                result.push(
                  <>
                    <h3>{paRecord?.name}</h3>
                    <ProFormDependency name={['contract']}>
                      {({ contract }) => {
                        if (schoolId_ssc && schoolName_ssc) {
                          return (
                            <ProFormSelect
                              label="需求匹配课程所对应学校"
                              value={schoolId_ssc}
                              options={[{ label: schoolName_ssc, value: schoolId_ssc }]}
                              disabled={true}
                            ></ProFormSelect>
                          );
                        }
                        const _disabled = !contract || !contractData;
                        return (
                          <Form.Item
                            name="schools"
                            label="需求匹配课程所对应学校"
                            //fieldProps={{
                            //  disabled: false,
                            //}}
                            //rules={[
                            //  {
                            //    required: true,
                            //    message: '请选择匹配课程对应的学校',
                            //  },
                            //]}
                          >
                            <Select
                              options={schoolOptions}
                              showSearch={true}
                              mode="multiple"
                              optionFilterProp="label"
                            ></Select>
                            {
                              //  <SchoolSelect
                              //    placeholder={_disabled ? '请先选择学生合同' : '请手动选择学校...'}
                              //    allowClear
                              //    mode="multiple"
                              //    disabled={_disabled}
                              //  />
                            }
                          </Form.Item>
                        );
                      }}
                    </ProFormDependency>
                    <ProFormDependency name={['schools']}>
                      {({ schools }) => {
                        if (studentSemesterCourse) {
                          return (
                            <ProFormSelect
                              label="选择学期"
                              value={semesterId_ssc}
                              options={[{ label: semesterName_ssc, value: semesterId_ssc }]}
                              disabled={true}
                              name="semester"
                            ></ProFormSelect>
                          );
                        }
                        let semesterOptions = [];
                        const schoolIds = schools.map((ele) =>
                          isNaN(ele) ? ele.value : parseInt(ele),
                        );
                        for (const schoolItem of schoolsData) {
                          if (schoolIds.includes(schoolItem.id)) {
                            const { currentSemesterList } = getSemesterList(
                              schoolItem.semesterPatterns,
                              3,
                            );
                            if (currentSemesterList.length) {
                              semesterOptions = [
                                ...semesterOptions,
                                ...currentSemesterList.map((semesterItem) => ({
                                  schoolId: schoolItem.id,
                                  label: `${semesterItem.name}(${schoolItem.name})`,
                                  value: semesterItem.id,
                                })),
                              ];
                            }
                          }
                        }

                        return (
                          <ProFormSelect
                            name="semester"
                            options={semesterOptions}
                            label="选择学期"
                          ></ProFormSelect>
                        );
                      }}
                    </ProFormDependency>
                    <ProFormDependency name={['courseNo', 'courseName']}>
                      {({ courseNo, courseName }) => {
                        if (!studentSemesterCourse) {
                          return (
                            <Form.Item
                              name="course"
                              label="需求匹配课程名称"
                              // fieldProps={{
                              //   disabled: false,
                              // }}
                              rules={[
                                {
                                  required: !courseNo && !courseName,
                                  message: '请选择需求匹配课程名称',
                                },
                              ]}
                            >
                              <SearchSelect
                                allowClear
                                disabled={!contract || !contractData}
                                schools={
                                  isNaN(schools?.[0]) ? schools?.map((s) => s.value) : schools
                                }
                                category="一对一"
                                placeholder="可搜索课号或课程名"
                              />
                            </Form.Item>
                          );
                        }
                      }}
                    </ProFormDependency>
                    <p>若系统课程管理没有此课程，可手动填写课号课名以及补充内容：</p>
                    {studentSemesterCourse ? (
                      <ProFormSelect
                        label="课名"
                        value={courseId_ssc || -1}
                        options={[
                          {
                            value: courseId_ssc || -1,
                            // label: courseNo_ssc + courseName_ssc || courseCustomizedName_ssc,
                            label: courseNo_ssc
                              ? `【${courseNo_ssc}】 ${courseName_ssc}`
                              : courseCustomizedName_ssc,
                          },
                        ]}
                        disabled={true}
                      ></ProFormSelect>
                    ) : (
                      <ProFormGroup>
                        <ProFormDependency name={['courseName', 'contract', 'course']}>
                          {({ courseName, contract, course }) => {
                            return (
                              <ProFormTextArea
                                label="课号"
                                name="courseNo"
                                fieldProps={{
                                  disabled: !contract || !contractData || !!course,
                                  autoSize: true,
                                }}
                                placeholder="请手填课号..."
                                // onChange={(e) => {
                                //   formRef.current?.resetFields(['course']);
                                // }}
                                rules={[
                                  {
                                    required: !!courseName,
                                    message: '请手动填写课号',
                                  },
                                ]}
                              />
                            );
                          }}
                        </ProFormDependency>
                        <ProFormDependency name={['courseNo', 'contract', 'course']}>
                          {({ courseNo, contract, course }) => {
                            return (
                              <ProFormText
                                label="课名 & 其他补充"
                                name="courseName"
                                fieldProps={{
                                  disabled:
                                    !contract || !contractData || !!course || courseName_ssc,
                                  autoSize: true,
                                }}
                                placeholder="请手填课名..."
                                // onChange={(e) => {
                                //   formRef.current?.resetFields(['course']);
                                // }}
                                rules={[
                                  {
                                    required: !!courseNo,
                                    message: '请手动填写课名',
                                  },
                                ]}
                              />
                            );
                          }}
                        </ProFormDependency>
                      </ProFormGroup>
                    )}
                  </>,
                );
              } else {
                result.push(
                  <>
                    <h3>{paRecord?.name}</h3>
                    <Form.Item
                      name="serviceItem"
                      label="需求匹配服务项名称"
                      rules={[
                        {
                          required: !serviceItemCustomizedName,
                          message: '请选择需求匹配课程名称',
                        },
                      ]}
                    >
                      <CustomSelect
                        allowClear
                        mode="single"
                        attribute={_productAttribute}
                        disabled={!contract || !contractData}
                        placeholder="可搜索服务项"
                      />
                    </Form.Item>
                    <p>可手动填写服务项：</p>
                    <ProFormGroup>
                      <ProFormTextArea
                        label="服务项"
                        name="serviceItemCustomizedName"
                        placeholder="请手动填写服务项"
                        fieldProps={{
                          autoSize: true,
                        }}
                      />
                    </ProFormGroup>
                  </>,
                );
              }
            }
            // const isServiceItem = !!contractData?.products
            //   ?.map((p) => p.productAttributes)
            //   .flat()
            //   .find?.((pa) => pa?.id === _productAttribute)?.productServiceItems?.length;
            // const isServiceItem = _productAttribute !== 1;
            // 区分服务项和一对一课
            return <>{result}</>;
          }}
        </ProFormDependency>

        {/* <ProFormDependency name={['productAttribute']}>
          {({ productAttribute }) => {
            if (!productAttribute) return;
            {
              const result = [];
              for (let pa of productAttribute) {
                let _productAttribute = pa?.value || pa;
                if (typeof _productAttribute === 'string') {
                  _productAttribute = Number(_productAttribute.split('-')?.[1]);
                }
                const isCourse =
                  contractData?.products
                    ?.map((p) => p.productAttributes)
                    .flat()
                    .find?.((pa) => pa?.id === _productAttribute)?.type === '学术';

                if (isCourse) {
                  result.push(
                    <>
                      <p>若系统课程管理没有此课程，可手动填写课号课名以及补充内容：</p>
                      <ProFormGroup>
                        <ProFormDependency name={['courseName', 'contract', 'course']}>
                          {({ courseName, contract, course }) => {
                            return (
                              <ProFormTextArea
                                label="课号"
                                name="courseNo"
                                fieldProps={{
                                  disabled: !contract || !contractData || !!course,
                                  autoSize: true,
                                }}
                                placeholder="请手填课号..."
                                // onChange={(e) => {
                                //   formRef.current?.resetFields(['course']);
                                // }}
                                rules={[
                                  {
                                    required: !!courseName,
                                    message: '请手动填写课号',
                                  },
                                ]}
                              />
                            );
                          }}
                        </ProFormDependency>
                        <ProFormDependency name={['courseNo', 'contract', 'course']}>
                          {({ courseNo, contract, course }) => {
                            return (
                              <ProFormTextArea
                                label="课名 & 其他补充"
                                name="courseName"
                                fieldProps={{
                                  disabled: !contract || !contractData || !!course,
                                  autoSize: true,
                                }}
                                placeholder="请手填课名..."
                                // onChange={(e) => {
                                //   formRef.current?.resetFields(['course']);
                                // }}
                                rules={[
                                  {
                                    required: !!courseNo,
                                    message: '请手动填写课名',
                                  },
                                ]}
                              />
                            );
                          }}
                        </ProFormDependency>
                      </ProFormGroup>
                    </>,
                  );
                } else {
                  result.push(
                    <>
                      <p>可手动填写服务项：</p>
                      <ProFormGroup>
                        <ProFormTextArea
                          label="服务项"
                          name="serviceItemCustomizedName"
                          placeholder="请手动填写服务项"
                          fieldProps={{
                            autoSize: true,
                          }}
                        />
                      </ProFormGroup>
                    </>,
                  );
                }
              }
              // const isServiceItem = !!contractData?.products
              //   ?.map((p) => p.productAttributes)
              //   .flat()
              //   .find?.((pa) => pa?.id === _productAttribute)?.productServiceItems?.length;
              // const isServiceItem = _productAttribute !== 1;
              // 区分服务项和一对一课
              return <>{result}</>;
            }
          }}
        </ProFormDependency> */}

        <Form.Item
          name="types"
          label="需求类型"
          // fieldProps={{
          //   disabled: false,
          // }}
          rules={[
            {
              required: true,
              message: '请选择需求类型',
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="选择需求类型"
            // style={{ width: '50%', marginLeft: '1em' }}
            optionFilterProp="label"
            options={typeSelectArray}
          />
        </Form.Item>
        <ProFormDependency name={['types']}>
          {({ types }) => {
            if (!types) return null;
            return ['考试辅导', '论文辅导', '作业辅导'].some((value) => types.includes(value)) ? (
              <>
                {types.includes('考试辅导') && (
                  <Form.Item
                    name="examDate"
                    label="考试时间"
                    // rules={[
                    //   {
                    //     required: types?.includes('考试辅导'),
                    //     message: '请填写考试时间',
                    //   },
                    // ]}
                  >
                    <CustomDatePicker />
                  </Form.Item>
                )}
                {types.includes('论文辅导') && (
                  <>
                    <Form.Item
                      name="essayDeadline"
                      label="论文Deadline"
                      // rules={[
                      //   {
                      //     required: types?.includes('论文辅导'),
                      //     message: '请填写论文Deadline',
                      //   },
                      // ]}
                    >
                      <CustomDatePicker />
                    </Form.Item>
                    <Form.Item
                      name="essayWordCount"
                      label="论文大概字数要求"
                      // rules={[
                      //   {
                      //     required: types?.includes('论文辅导'),
                      //     message: '请填写论文大概字数要求',
                      //   },
                      // ]}
                    >
                      <InputNumber />
                    </Form.Item>
                  </>
                )}
                {types.includes('作业辅导') && (
                  <Form.Item
                    name="assignmentDeadline"
                    label="作业Deadline"
                    // rules={[
                    //   {
                    //     required: types?.includes('作业辅导'),
                    //     message: '请填写作业Deadline',
                    //   },
                    // ]}
                  >
                    <CustomDatePicker />
                  </Form.Item>
                )}
              </>
            ) : null;
          }}
        </ProFormDependency>
        <ProFormGroup>
          <ProFormTextArea
            label="上课频次"
            name="lessonFrequency"
            fieldProps={{
              autoSize: true,
            }}
            placeholder="请填写..."
            rules={[
              {
                required: true,
                message: '请填写上课频次',
              },
            ]}
          />
          <ProFormTextArea
            label="需第一次上课时间"
            name="idealFirstLessonDate"
            fieldProps={{
              autoSize: true,
            }}
            placeholder="请填写..."
            rules={[
              {
                required: true,
                message: '请填写需第一次上课时间',
              },
            ]}
          />
        </ProFormGroup>
        <ProFormGroup>
          <ProFormRadio.Group
            name="academicPerformance"
            label="学生学术水平"
            options={academicPerformanceRadioArray}
            rules={[
              {
                required: true,
                message: '请选择学生水平',
              },
            ]}
          />
          <ProFormDependency name={['academicPerformance']}>
            {({ academicPerformance }) => {
              return (
                <ProFormTextArea
                  label="文字说明"
                  name="academicPerformanceNotes"
                  fieldProps={{
                    autoSize: true,
                  }}
                  placeholder="请说明..."
                  rules={[
                    {
                      required: true,
                      message: '请说明',
                    },
                  ]}
                />
              );
            }}
          </ProFormDependency>
        </ProFormGroup>
        <ProFormGroup>
          <Form.Item
            name="reason"
            label="匹配原因"
            // fieldProps={{
            //   disabled: false,
            // }}
            rules={[
              {
                required: true,
                message: '请选择匹配原因',
              },
            ]}
          >
            <Cascader
              options={reasonCascaderArray}
              expandTrigger="hover"
              placeholder="请选择匹配原因"
              // displayRender={(labels) => labels[labels.length - 1]}
              // onChange={onChange}
            />
          </Form.Item>
          <ProFormDependency name={['reason', 'course', 'serviceItem']}>
            {({ reason, course, serviceItem }) => {
              // if (!reason || reason[0] === '新老师匹配') return null;
              return (
                <>
                  {reason && (reason[0] === '替换原老师' || reason[0] === '临时代课') ? (
                    <Form.Item
                      name="previousTeacher"
                      label="原老师姓名"
                      rules={[
                        {
                          required:
                            (reason && reason[0] === '替换原老师') || reason[0] === '临时代课',
                          message: '请选择原老师',
                        },
                      ]}
                    >
                      <PreviousTeacherSelect
                        mode={edit ? 'single' : 'multiple'}
                        contractData={contractData}
                        course={course?.value || course || courseId_ssc}
                        serviceItem={serviceItem?.value || serviceItem}
                      />
                    </Form.Item>
                  ) : null}
                  <ProFormTextArea
                    label="文字说明"
                    name="reasonNotes"
                    fieldProps={{
                      disabled: !reason || reason[0] === '新老师匹配',
                      autoSize: true,
                    }}
                    placeholder="请说明..."
                    rules={[
                      {
                        required: !!reason && reason?.[0] !== '新老师匹配',
                        message: '请说明',
                      },
                    ]}
                  />
                </>
              );
            }}
          </ProFormDependency>
        </ProFormGroup>
        <Form.Item
          name="headTeacherNotes"
          label="特殊注意事项"
          // rules={[
          //   {
          //     required: false,
          //     message: '请说明详情',
          //   },
          // ]}
        >
          <Input.TextArea rows={3} width={'100%'} placeholder="请说明殊注意事项..." />
        </Form.Item>
        <Form.Item
          name="latestMatchTime"
          label="最晚匹配时间"
          // rules={[
          //   {
          //     required: false,
          //     message: '请填写最晚匹配时间',
          //   },
          // ]}
        >
          <CustomDatePicker />
        </Form.Item>
        <ProFormCheckbox name="needQualityCheck" valuePropName="checked">
          是否需要过课？
        </ProFormCheckbox>
      </ProForm>
    </Spin>
  );
};

export default CreateCourseMatch;
