import { getById as getLessonById, query as queryLessons } from '@/pages/Lesson/service';
import { createLink } from '@/pages/Material/service';
import dayjs from 'dayjs';
import { pathToRegexp } from 'path-to-regexp';
import { v4 as uuidv4 } from 'uuid';
import { createAssignment, getAssignmentById, updateAssignment } from './service';

const AssignmentModel = {
  namespace: 'assignmentProfile',
  state: {
    questions: [
      {
        id: 0,
        category: 'multiple',
        body: '',
        bodyType: 'editor',
        explanation: null,
        explanationFile: null,
        explanationVideo: null,
        hasExplanation: false,
        optionType: 'options',
        options: [
          { content: '', key: uuidv4() },
          { content: '', key: uuidv4() },
        ],
        answer: null,
        mark: 1,
        isCount: true,
      },
    ],
    updatedAssignment: null,
    createdAssignment: null,
    selectedLessonId: null,
    currentLesson: null,
    searchedLessons: null,
    assignmentData: null,
    timezone: null,
    startAt: null,
    endAt: null,
    publish: false,
    submitLoading: false,
    lock: false,
    mode: 'assignment',
    duration: null,
  },

  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ location }) => {
        const match = pathToRegexp('/assignment/:id').exec(location.pathname);
        const search = new URLSearchParams(location.search)
        const lessonId = search.get('lessonId')

        if (match) {
          const assignmentId = match[1];

          if (assignmentId === 'new') {
            if (lessonId) {
              dispatch({
                type: 'getLessonById',
                payload: {
                  id: lessonId,
                },
              });
            }
          } else {
            dispatch({
              type: 'getById',
              payload: {
                id: assignmentId,
              },
            });
          }
        }
      });
    },
  },

  effects: {
    *createAssignment({ payload }, { call, put }) {
      const response = yield call(createAssignment, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            createdAssignment: response,
            submitLoading: false,
          },
        });
      }
    },
    *updateAssignment({ payload }, { call, put }) {
      const response = yield call(updateAssignment, { id: payload.assignment.id, payload });

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            updatedAssignment: response,
            submitLoading: false,
          },
        });
      }
    },
    *getById({ payload }, { call, put }) {
      const response = yield call(getAssignmentById, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            assignmentData: response,
            questions: response.questions,
            currentLesson: response.lesson,
            lock: response.isPublish,
            submitLoading: false,
            startAt: response.startAt ? dayjs(response.startAt) : null,
            endAt: response.endAt ? dayjs(response.endAt) : null,
            mode: response.mode,
            duration: response.duration,
          },
        });
      }
    },

    *getLessonById({ payload }, { call, put }) {
      const response = yield call(getLessonById, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            currentLesson: response,
          },
        });
      }
    },

    *queryLessons({ payload }, { call, put }) {
      const response = yield call(queryLessons, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            searchedLessons: response,
          },
        });
      }
    },

    *createLink({ payload }, { call, put, select }) {
      const questions = yield select((state) => state.assignmentProfile.questions);
      const { questionIndex, where } = payload;
      const response = yield call(createLink, payload);

      if (response) {
        const newQuestions = questions;
        let newQuestion;
        if (where === 'body') {
          newQuestion = {
            ...questions[questionIndex],
            body: response,
          };
          newQuestions.splice(questionIndex, 1, newQuestion);
        }

        if (where === 'explanationFile') {
          newQuestion = {
            ...questions[questionIndex],
            explanationFile: response,
          };
          newQuestions.splice(questionIndex, 1, newQuestion);
        }

        if (where === 'explanationVideo') {
          newQuestion = {
            ...questions[questionIndex],
            explanationVideo: response,
          };
          newQuestions.splice(questionIndex, 1, newQuestion);
        }

        if (where === 'options') {
          newQuestion = {
            ...questions[questionIndex],
            options: response,
          };
          newQuestions.splice(questionIndex, 1, newQuestion);
        }

        yield put({
          type: 'updateState',
          payload: {
            questions: newQuestions,
          },
        });
      }
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    cleanUp(state) {
      return {
        ...state,
        updatedAssignment: null,
        createdAssignment: null,
        selectedLessonId: null,
        currentLesson: null,
        searchedLessons: null,
        assignmentData: null,
        timezone: null,
        startAt: null,
        endAt: null,
        publish: false,
        submitLoading: false,
        lock: false,
        mode: null,
        duration: null,
      };
    },
  },
};

export default AssignmentModel;
