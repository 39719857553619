import { message } from 'antd';
import { getContractsByStudent, getStudyReportContract } from '../../Contract/service';
import { createNote, getDates, getLessons, getStudent, update } from './service';

const Model = {
  namespace: 'studentProfile',

  state: {
    currentStudent: {},
    updating: false,
    updatedStudent: null,
    submitButtonLoading: false,
    contracts: [],
    products: [],
    courses: [],
    dates: [],
    lessons: [],
    linkingCourse: false,

    studentProfilePageLoading: true,
    contentLoading: true,
    list: [],

    creatingNote: false,
    submitNoteLoading: false,
    studentNote: null,

    // study report
    studyReportVisible: false,
    student1v1Contracts: [],
  },

  effects: {
    *getStudent({ payload }, { call, put }) {
      const dates = yield call(getDates, {
        studentId: payload.studentId,
        params: {
          timestamp: encodeURIComponent(new Date()),
        },
      });
      if (dates) {
        yield put({
          type: 'updateState',
          payload: {
            dates,
          },
        });
      }
      const lessons = yield call(getLessons, {
        ...payload,
        timestamp: encodeURIComponent(new Date()),
      });
      if (lessons) {
        yield put({
          type: 'updateState',
          payload: {
            lessons,
          },
        });
      }
      const response = yield call(getStudent, payload);
      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            currentStudent: response,
            studentProfilePageLoading: false,
          },
        });
      }
    },

    *update({ payload }, { call, put, select }) {
      const searchValue = yield select((state) => state.student.searchValue);

      const response = yield call(update, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            updatedStudent: response,
            submitButtonLoading: false,
            currentStudent: response,
          },
        });

        yield put({
          type: 'getStudent',
          payload: {
            studentId: response.studentId,
          },
        });

        if (searchValue) {
          yield put({
            type: 'student/query',
          });
        } else {
          yield put({
            type: 'student/getStudentsByUser',
          });
        }
      }
    },

    *getContracts(_, { call, put, select }) {
      const id = yield select((state) => state.studentProfile.currentStudent.id);

      if (id) {
        const response = yield call(getContractsByStudent, {
          id,
          params: { studentProfile: true },
        });

        if (response) {
          yield put({
            type: 'updateState',
            payload: {
              contracts: response,
            },
          });
        }
      }
    },

    *getDates({ payload }, { call, put, select }) {
      const studentId = yield select((state) => state.studentProfile.currentStudent.studentId);

      if (studentId) {
        const response = yield call(getDates, {
          studentId,
          params: {
            ...payload,
          },
        });
        if (response) {
          yield put({
            type: 'updateState',
            payload: {
              dates: response,
            },
          });
        }
      }
    },

    *getLessons({ payload }, { call, put, select }) {
      const studentId = yield select((state) => state.studentProfile.currentStudent.studentId);

      if (studentId) {
        const response = yield call(getLessons, {
          studentId,
          ...payload,
        });

        if (response) {
          yield put({
            type: 'updateState',
            payload: {
              lessons: response,
            },
          });
        }
      }
    },

    *initStudentNoteForm({ payload }, { put }) {
      let newStudentNote = payload?.studentNote;

      yield put({
        type: 'updateState',
        payload: {
          creatingNote: true,
        },
      });

      if (!newStudentNote) {
        newStudentNote = {
          content: '',
          tag: 'all',
        };
      }

      yield put({
        type: 'updateState',
        payload: {
          studentNote: newStudentNote,
        },
      });
    },

    *editStudentNoteForm({ payload }, { put, select }) {
      const studentNote = yield select((state) => state.studentProfile.studentNote);
      const { tag, content } = payload;

      if (tag) studentNote.tag = tag;
      if (content !== undefined) studentNote.content = content;

      yield put({
        type: 'updateState',
        payload: {
          studentNote,
        },
      });
    },

    *submitStudentNoteForm({ payload }, { put, select, call }) {
      const studentNote = yield select((state) => state.studentProfile.studentNote);
      const currentStudent = yield select((state) => state.studentProfile.currentStudent);

      yield put({
        type: 'updateState',
        payload: {
          submitNoteLoading: true,
        },
      });

      if (studentNote && currentStudent) {
        if (!studentNote.content.trim()) {
          return message.error('无法提交空白备注');
        }

        const params = {
          studentId: currentStudent.id,
          content: studentNote.content,
          tag: studentNote.tag,
        };

        if (studentNote.id) {
          // update logic, later feature
          // const resp = yield call( updateNote, params)
        } else {
          const resp = yield call(createNote, params);

          if (resp && resp.id) {
            message.success('创建备注成功');

            yield put({
              type: 'updateState',
              payload: {
                submitNoteLoading: false,
                creatingNote: false,
                studentNote: null,
              },
            });

            yield put({
              type: 'getStudent',
              payload: {
                studentId: currentStudent.studentId,
              },
            });
          }
        }
      }
    },

    *endStudentNoteForm({ payload }, { put }) {
      yield put({
        type: 'updateState',
        payload: {
          studentNote: null,
          submitNoteLoading: false,
          creatingNote: false,
        },
      });
    },

    *getStudentContracts(_, { put, call, select }) {
      const studentId = yield select((state) => state.studentProfile.currentStudent.id);

      if (studentId) {
        try {
          const data = yield call(getStudyReportContract, { studentId, includeBanke: true });

          if (data) {
            yield put({
              type: 'updateState',
              payload: {
                studyReportVisible: true,
                student1v1Contracts: data,
              },
            });
          }
        } catch {
          message.error('获取学员合同失败!');
        }
      }
    },

    *cancelStudyReport(_, { put }) {
      yield put({
        type: 'updateState',
        payload: {
          studyReportVisible: false,
          student1v1Contracts: [],
        },
      });
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    end(state, { payload }) {
      return {
        ...state,
        updating: false,
        submitButtonLoading: false,
        updatedStudent: null,
      };
    },
  },
};
export default Model;
