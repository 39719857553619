import { Button, Timeline } from 'antd';
import { useState } from 'react';

const MaxCountTimeline = ({ items = [], maxCount = 3, ...props }) => {
  const [displayCount, setDisplayCount] = useState(maxCount);
  let collapseOrExpand = null;
  const hiddenItemCount = items.length - displayCount;
  if (hiddenItemCount > 0) {
    collapseOrExpand = (
      <Button
        type="link"
        size="small"
        onClick={() => {
          setDisplayCount(items.length);
        }}
      >
        ...({hiddenItemCount} more)
      </Button>
    );
  }
  const extraItemCount = displayCount - maxCount;
  if (extraItemCount > 0) {
    collapseOrExpand = (
      <Button
        type="link"
        size="small"
        onClick={() => {
          setDisplayCount(maxCount);
        }}
      >
        收起
      </Button>
    );
  }
  return (
    <>
      <Timeline {...props} items={items.slice(0, displayCount)} />
      <div style={{ textAlign: 'right' }}>{collapseOrExpand}</div>
    </>
  );
};

export default MaxCountTimeline;
