import { request } from '@@/plugin-request';

export async function getContacts(params) {
  return request('/api/consultants/contacts', {
    method: 'get',
    params,
  });
}

export async function createContact(data) {
  return request('/api/consultants/contacts', {
    method: 'post',
    data,
  });
}

export async function updateContact(data) {
  return request('/api/consultants/contacts', {
    method: 'put',
    data,
  });
}
