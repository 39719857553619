import { message } from 'antd';
import dayjs from 'dayjs';
import { exportSalaryHistory, getCumulativeSalary, getSalaryHistory } from './service';

const Model = {
  namespace: 'teacherSalary',
  state: {
    pagination: {
      page: 1,
      pageSize: 20,
    },
    selectedDate: null,
    data: [],
    cumulativeData: [],
    dataLoading: false,
    selectedCourseCategory: null,
  },

  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ location }) => {
        if (location.pathname === '/finance/salary/teacher') {
          dispatch({
            type: 'updateState',
            payload: {
              selectedDate: dayjs().subtract(1, 'months'),
            },
          });
          dispatch({
            type: 'fetchTeacherSalary',
          });
        }
      });
    },
  },

  effects: {
    *fetchTeacherSalary({ payload = {} }, { call, put, select }) {
      const date = yield select((state) => state.teacherSalary.selectedDate);
      const pagination = yield select((state) => state.teacherSalary.pagination);

      if (!date) return;

      const params = {
        pagination,
        year: new Date(date).getFullYear(),
        month: new Date(date).getMonth(),
        date: date.toDate(),
        ...payload,
      };

      const response = yield call(getSalaryHistory, params);

      yield put({
        type: 'fetchCumulativeSalary',
      });

      yield put({
        type: 'updateState',
        payload: {
          data: response.data,
        },
      });
    },

    *fetchCumulativeSalary({ payload = {} }, { call, put, select }) {
      const date = yield select((state) => state.teacherSalary.selectedDate);
      const pagination = yield select((state) => state.teacherSalary.pagination);

      if (!date) return;

      const params = {
        pagination,
        year: new Date(date).getFullYear(),
        month: new Date(date).getMonth(),
        date: date.toDate(),
        ...payload,
      };

      const response = yield call(getCumulativeSalary, params);

      yield put({
        type: 'updateState',
        payload: {
          cumulativeData: response,
        },
      });
    },

    *exportSalaryData({ payload = {} }, { call, put, select }) {
      const date = yield select((state) => state.teacherSalary.selectedDate);
      const pagination = yield select((state) => state.teacherSalary.pagination);

      if (!date) return;

      const params = {
        pagination,
        year: new Date(date).getFullYear(),
        month: new Date(date).getMonth(),
        date: date.toDate(),
      };

      const response = yield call(exportSalaryHistory, params);

      if (response) {
        const blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = url;
        tempLink.setAttribute('download', `${new Date().toLocaleDateString('zh-CN')}_工资导出.csv`);
        tempLink.click();
        tempLink.remove();

        message.success({
          content: '工资导出成功',
          duration: 3,
        });
      }
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default Model;
