import { FloatButton, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { getAllSalaryIncreaseApprovalList } from '../service';
import TeachersSalaryIncreaseTable from './TeachersSalaryIncreaseTable';
const TeachersSalaryIncreaseFloatButton = ({ status, salaryIncreaseApprovalsPermission }) => {
  const [visible, setVisible] = useState(false);
  const [count, setCount] = useState(0);
  useEffect(() => {
    getAllSalaryIncreaseApprovalList({
      pageSize: 10,
      current: 1,
      approvalStatus: status,
    }).then((res) => {
      setCount(res.count);
    });
  }, [status]);

  const onFinish = () => {
    setCount(count - 1);
  };
  return (
    <>
      <FloatButton
        onClick={() => setVisible(true)}
        badge={{
          count: count,
          overflowCount: 999,
        }}
        style={{ right: 20, bottom: 100 }}
        tooltip={<div>达到涨薪标准的老师</div>}
      />
      <Modal
        title="达到涨薪标准的老师"
        open={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={1600}
      >
        <TeachersSalaryIncreaseTable
          status={status}
          onFinish={onFinish}
          salaryIncreaseApprovalsPermission={salaryIncreaseApprovalsPermission}
        />
      </Modal>
    </>
  );
};

export default TeachersSalaryIncreaseFloatButton;
