import { request } from '@@/plugin-request';

export async function createTeacherRate(data) {
  return request('/api/users/teachers/teacherRate', {
    method: 'POST',
    data,
  });
}

export async function queryTeacherRate(params) {
  return request('/api/users/teachers/teacherRate', {
    method: 'GET',
    params: params,
  });
}
