import { request } from '@@/plugin-request';

export async function downloadData(params) {
  return request('/api/datacenter/download', {
    method: 'POST',
    data: params,
  });
}

export async function createDownloadLog(params) {
  return request('/api/log/download', {
    method: 'POST',
    data: params,
  });
}
