import ReceivingAccountForm from '@/components/Form/ReceivingAccount';
import Upload from '@/components/Upload/FormItem';
import { update } from '@/pages/Account/service';
import { request } from '@@/plugin-request';
import { ProForm } from '@ant-design/pro-components';
import { Divider, Input, message, Modal } from 'antd';
import { useState } from 'react';
import { useModel } from 'umi';
import PreSetAirwallexForm from '../Form/PreSetAirwallexForm';

const OnboardingModal = () => {
  const {
    initialState: { currentUser },
  } = useModel('@@initialState');
  const {
    todos,
    setTodos,
    refreshTodos,
    loading,
    modalVisible,
    setModalVisible,
    receivingAccountVisible,
    erpAccountVisible,
  } = useModel('onboarding');

  const [preSetAirwallexFormVisible, setPreSetAirwallexFormVisible] = useState(true);
  const [airwallexPreSetData, setAirwallexPreSetData] = useState({});
  return (
    <Modal
      width={720}
      open={modalVisible}
      onCancel={() => {
        setModalVisible(false);
      }}
      footer={false}
    >
      {erpAccountVisible && (
        <>
          <Divider>Finish setting up ERP account</Divider>
          <ProForm
            layout="horizontal"
            initialValues={{
              id: currentUser?.id,
            }}
            params={{
              id: currentUser?.id,
            }}
            request={async (params) => {
              const data = await request(`/api/users/onboarding/${params.id}`);
              if (!!data.employmentContract?.id) {
                data.employmentContract.uid = -1;
                data.employmentContract.status = 'done';
                const keySplits = data.employmentContract.key.split('/');
                data.employmentContract.name = keySplits[keySplits.length - 1];
              }
              return data;
            }}
            onFinish={async (values) => {
              try {
                if (values.employmentContract) {
                  values.employmentContract.sp = 'oss';
                  values.employmentContract.bucket = 'savvyuni-intl-erp';
                }
                await update(values);

                let newTodos = [...todos];
                if (values.description) {
                  newTodos = newTodos.filter((td) => td.name !== '填写自我介绍');
                }
                if (values.employmentContract) {
                  newTodos = newTodos.filter((td) => td.name !== '上传签署合同');
                }
                setTodos(newTodos);
                message.success('更新成功！');
                setModalVisible(false);
              } catch (err) {
                message.error(err.data?.message || '更新失败 :O');
              }
            }}
          >
            <ProForm.Item name="id" hidden />
            <ProForm.Item name="description" label="自我介绍">
              <Input.TextArea
                placeholder="Introduce yourself here! This can help us match you with students."
                autoSize={{ minRows: 3 }}
              />
            </ProForm.Item>
            <ProForm.Item name="employmentContract" label="签署合同上传">
              <Upload maxCount={1} buttonText={'请上传签名的合同'} />
            </ProForm.Item>
          </ProForm>
        </>
      )}
      {receivingAccountVisible && (
        <>
          <Divider>Register a Payee account</Divider>
          {currentUser.paymentMethod === 'Airwallex' ? (
            <>
              {preSetAirwallexFormVisible && (
                <PreSetAirwallexForm
                  // currency={currentUser.salaryCurrency}
                  currency={currentUser.payoutCurrency}
                  airwallexPreSetData={airwallexPreSetData}
                  setAirwallexPreSetData={setAirwallexPreSetData}
                  onFinish={() => {
                    setPreSetAirwallexFormVisible(false);
                  }}
                />
              )}
              {!preSetAirwallexFormVisible && (
                <ReceivingAccountForm
                  owner={currentUser.id}
                  // currency={currentUser.salaryCurrency}
                  currency={currentUser.payoutCurrency}
                  paymentMethod={currentUser.paymentMethod}
                  airwallexPreSetData={airwallexPreSetData}
                  onFinish={() => {
                    const newTodos = todos.filter((td) => td.name !== '提供收款信息');
                    setTodos(newTodos);
                  }}
                />
              )}
            </>
          ) : (
            <ReceivingAccountForm
              owner={currentUser.id}
              // currency={currentUser.salaryCurrency}
              currency={currentUser.payoutCurrency}
              paymentMethod={currentUser.paymentMethod}
              onFinish={() => {
                const newTodos = todos.filter((td) => td.name !== '提供收款信息');
                setTodos(newTodos);
              }}
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default OnboardingModal;
