import { request } from '@@/plugin-request';

export async function getAssignmentById(payload) {
  return request('/api/assignments/getAssignment', { method: 'GET', params: payload });
}

export async function createAssignment(payload) {
  return request('/api/assignments', { method: 'POST', data: payload });
}

export async function updateAssignment({ id, payload }) {
  return request(`/api/assignments/${id}`, { method: 'PUT', data: payload });
}
