import { useEffect, useRef, useState } from 'react';
import './NoticeContent.css'; // Import your CSS file

const App = ({ content, refresh }) => {
  const [expanded, setExpanded] = useState(false);
  const [tooLong, setTooLong] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    let attempts = 0;

    const checkHeight = () => {
      if (content.length === 0) {
        return;
      }
      if (contentRef.current) {
        const actualHeight = contentRef.current.clientHeight;
        const maxHeight = 50;
        setTooLong(actualHeight >= maxHeight);

        if (actualHeight !== 0 || attempts >= 10) {
          return; // Stop checking
        }

        attempts++;
        setTimeout(checkHeight, 1000); // Retry after 1 seconds
      }
    };

    checkHeight(); // Start the checking process
  }, [refresh]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return expanded ? (
    <div>
      <div className="content-container-expanded">
        <p className={`content ${expanded ? 'expanded' : ''}`} ref={contentRef}>
          {content}
        </p>
      </div>
      {tooLong && (
        <button className="show-more-button" onClick={toggleExpand}>
          查看更少
        </button>
      )}
    </div>
  ) : (
    <div>
      <div className="content-container">
        <p className={`content ${expanded ? 'expanded' : ''}`} ref={contentRef}>
          {content}
        </p>
      </div>
      {tooLong && (
        <button className="show-more-button" onClick={toggleExpand}>
          查看更多
        </button>
      )}
    </div>
  );
};

export default App;
