import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { getPreviousTeachers } from './service';

const PreviousTeacherSelect = ({ contractData, course, serviceItem, mode = 'single', ...rest }) => {
  if (!contractData || (!course && !serviceItem))
    return <Select placeholder="请先选择课程/服务项" disabled={true} />;
  const [previousTeachers, setPreviousTeachers] = useState(null);
  useEffect(() => {
    getPreviousTeachers({
      student: contractData.student?.id,
      course,
      serviceItem,
    }).then((data) => {
    const options = data?.map((item) => ({
      label: `${`${item.lastName}${item.firstName}`}${
        item.deletedAt || item.teacherStatus === 'resigned' ? '(已离职)' : ''
      } - ${item.chineseName || '无'} - ${item.wechatId || '无'}`,
      value: item.id,
    }));
    setPreviousTeachers(options);
    });
  }, [contractData]);

  return (
    <Select
      mode={mode}
      allowClear
      showSearch
      placeholder="选择原老师"
      // style={{ width: '50%', marginLeft: '1em' }}
      optionFilterProp="label"
      options={previousTeachers}
      {...rest}
    />
  );
};

export default PreviousTeacherSelect;
