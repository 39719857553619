import { createContact, getContacts, updateContact as update } from '@/services/sales';
import { message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useModel } from 'umi';

export default function useSalesModel() {
  const { initialState, initialStateLoading } = useModel('@@initialState', (model) => ({
    initialState: model.initialState,
    initialStateLoading: model.loading,
  }));
  const [contacts, setContacts] = useState([]);
  const [contactsLoading, setContactsLoading] = useState(false);

  useEffect(() => {
    const visibleRoles = ['sales', 'admin', 'superadmin'];
    if (
      !initialStateLoading &&
      initialState?.currentUserRoles?.some?.((r) => visibleRoles.includes(r))
    ) {
      setContactsLoading(true);
      getContacts()
        .then((res) => {
          setContacts(res);
        })
        .catch((err) => {
          setContacts([]);
        })
        .finally(() => {
          setContactsLoading(false);
        });
    }
  }, [initialStateLoading]);

  const addNewContact = useCallback(async (data) => {
    try {
      const res = await createContact(data);
      setContacts((prevState) => {
        return [res, ...prevState];
      });
      return res;
    } catch (err) {
      const errorMsg = err.data?.message || '操作失败。';
      message.error(errorMsg);
    }
  }, []);

  const updateContact = useCallback(async (data) => {
    try {
      const res = await update(data);
      setContacts((prevState) => {
        const newState = prevState.map?.((c) => {
          if (c.id === res.id) {
            return res;
          }
          return c;
        });
        return newState;
      });
      return res;
    } catch (err) {
      const errorMsg = err.data?.message || '操作失败。';
      message.error(errorMsg);
    }
  }, []);

  return {
    contacts,
    contactsLoading,
    addNewContact,
    updateContact,
  };
}
