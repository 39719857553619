import { request } from '@@/plugin-request';

export async function create(payload) {
  return request('/api/assignments/create', { method: 'POST', data: payload });
}

export async function update(payload) {
  return request('/api/assignments', { method: 'PUT', data: payload });
}

export async function updateByUser(payload) {
  return request('/api/submissions/updateByUser', { method: 'PUT', data: payload });
}

export async function query(payload) {
  return request('/api/assignments/query', { method: 'GET', params: payload });
}
