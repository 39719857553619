import { request } from '@@/plugin-request';

export async function getOperationTeams(params) {
  return request('/api/team', {
    method: 'GET',
    params: {
      ...params,
      category: 'operation'
    }
  });
}
