// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/var/www/html/intl-erp/src/models/user';
import model_2 from '/var/www/html/intl-erp/src/models/wallet';
import model_3 from '/var/www/html/intl-erp/src/pages/Account/Consultant/model';
import model_4 from '/var/www/html/intl-erp/src/pages/Account/model';
import model_5 from '/var/www/html/intl-erp/src/pages/Account/TeacherView/model';
import model_6 from '/var/www/html/intl-erp/src/pages/Account/Team/model';
import model_7 from '/var/www/html/intl-erp/src/pages/Assignment/Marking/model';
import model_8 from '/var/www/html/intl-erp/src/pages/Assignment/model';
import model_9 from '/var/www/html/intl-erp/src/pages/Assignment/Profile/model';
import model_10 from '/var/www/html/intl-erp/src/pages/Contract/EditForm/model';
import model_11 from '/var/www/html/intl-erp/src/pages/Contract/model';
import model_12 from '/var/www/html/intl-erp/src/pages/Course/model';
import model_13 from '/var/www/html/intl-erp/src/pages/Course/Profile/model';
import model_14 from '/var/www/html/intl-erp/src/pages/DashboardWorkplace/components/OperationAdmin/model';
import model_15 from '/var/www/html/intl-erp/src/pages/DashboardWorkplace/model';
import model_16 from '/var/www/html/intl-erp/src/pages/DataCenter/DataDownload/model';
import model_17 from '/var/www/html/intl-erp/src/pages/DataCenter/DataPool/Components/ContractsCountChart/model';
import model_18 from '/var/www/html/intl-erp/src/pages/DataCenter/DataPool/Components/HeadTeacherStudentChart/model';
import model_19 from '/var/www/html/intl-erp/src/pages/Finance/Salary/Teacher/model';
import model_20 from '/var/www/html/intl-erp/src/pages/FirstLessonReview/model';
import model_21 from '/var/www/html/intl-erp/src/pages/Lesson/model';
import model_22 from '/var/www/html/intl-erp/src/pages/Material/model';
import model_23 from '/var/www/html/intl-erp/src/pages/Products/model';
import model_24 from '/var/www/html/intl-erp/src/pages/Sales/model';
import model_25 from '/var/www/html/intl-erp/src/pages/SalesLeads/model';
import model_26 from '/var/www/html/intl-erp/src/pages/School/model';
import model_27 from '/var/www/html/intl-erp/src/pages/Semester/model';
import model_28 from '/var/www/html/intl-erp/src/pages/Student/model';
import model_29 from '/var/www/html/intl-erp/src/pages/Student/Profile/model';
import model_30 from '/var/www/html/intl-erp/src/pages/TrainingCourse/Profile/model';

export const models = {
model_1: { namespace: 'user', model: model_1 },
model_2: { namespace: 'wallet', model: model_2 },
model_3: { namespace: 'Account.Consultant.model', model: model_3 },
model_4: { namespace: 'Account.model', model: model_4 },
model_5: { namespace: 'Account.TeacherView.model', model: model_5 },
model_6: { namespace: 'Account.Team.model', model: model_6 },
model_7: { namespace: 'Assignment.Marking.model', model: model_7 },
model_8: { namespace: 'Assignment.model', model: model_8 },
model_9: { namespace: 'Assignment.Profile.model', model: model_9 },
model_10: { namespace: 'Contract.EditForm.model', model: model_10 },
model_11: { namespace: 'Contract.model', model: model_11 },
model_12: { namespace: 'Course.model', model: model_12 },
model_13: { namespace: 'Course.Profile.model', model: model_13 },
model_14: { namespace: 'DashboardWorkplace.components.OperationAdmin.model', model: model_14 },
model_15: { namespace: 'DashboardWorkplace.model', model: model_15 },
model_16: { namespace: 'DataCenter.DataDownload.model', model: model_16 },
model_17: { namespace: 'DataCenter.DataPool.Components.ContractsCountChart.model', model: model_17 },
model_18: { namespace: 'DataCenter.DataPool.Components.HeadTeacherStudentChart.model', model: model_18 },
model_19: { namespace: 'Finance.Salary.Teacher.model', model: model_19 },
model_20: { namespace: 'FirstLessonReview.model', model: model_20 },
model_21: { namespace: 'Lesson.model', model: model_21 },
model_22: { namespace: 'Material.model', model: model_22 },
model_23: { namespace: 'Products.model', model: model_23 },
model_24: { namespace: 'Sales.model', model: model_24 },
model_25: { namespace: 'SalesLeads.model', model: model_25 },
model_26: { namespace: 'School.model', model: model_26 },
model_27: { namespace: 'Semester.model', model: model_27 },
model_28: { namespace: 'Student.model', model: model_28 },
model_29: { namespace: 'Student.Profile.model', model: model_29 },
model_30: { namespace: 'TrainingCourse.Profile.model', model: model_30 },
} as const
