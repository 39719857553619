import { request } from '@@/plugin-request';

export async function create(data) {
  return request(`/api/coursematch/create`, {
    method: 'POST',
    data,
  });
}

export async function update(body) {
  return request('/api/coursematch/update', {
    method: 'PUT',
    data: body,
  });
}

export async function getContractById(id) {
  return request(`/api/contracts/lessonModal/${id}`, {
    method: 'GET',
  });
}

export async function getPreviousTeachers(params) {
  return request(`/api/coursematch/getPreviousTeachers`, {
    method: 'GET',
    params,
  });
}

export async function getStudentSensitiveInfo(params) {
  return request(`/api/student/getStudentSensitiveInfo`, {
    method: 'GET',
    params,
  });
}

export async function get4ReuseCourseMatch(params) {
  return request(`/api/coursematch/get4ReuseCourseMatch`, {
    method: 'GET',
    params,
  });
}

export async function reusePreviousRecord(data) {
  return request(`/api/coursematch/reusePreviousRecord`, {
    method: 'PUT',
    data,
  });
}

export async function getSchools(params) {
  return request(`/api/school/dropdownSelect`, {
    method: 'GET',
    params,
  });
}
