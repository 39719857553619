import { message, Select } from 'antd';
import { useEffect, useState } from 'react';
import { getOptions } from './service';

const CustomSelect = ({ teacher, attribute, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await getOptions({ teacher, attribute, forSelect: true });
      const data = res.map((item) => ({
        label: `【${item.academicLevel || '无学术等级'}】${item.name}`,
        value: item.id,
      }));
      setOptions(data);
    } catch (e) {
      message.error('获取产品发生错误!');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [attribute]);

  const onFilterOption = (inputValue, option) => {
    let fuzzyMatch = false;
    if (option?.label?.includes(inputValue)) fuzzyMatch = true;
    return fuzzyMatch;
  };

  return (
    <Select
      showSearch
      placeholder="请选择服务项"
      mode="multiple"
      style={{
        width: '100%',
      }}
      filterOption={onFilterOption}
      loading={loading}
      options={options}
      {...props}
    />
  );
};

export default CustomSelect;
