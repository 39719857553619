import { request } from '@@/plugin-request';

export async function create(data) {
  return request('/api/course', {
    method: 'POST',
    data,
  });
}

export async function update(data) {
  return request('/api/course', {
    method: 'PUT',
    data,
  });
}

export async function query(params) {
  return request('/api/course/query', {
    method: 'GET',
    params,
  });
}

export async function getById(id) {
  return request(`/api/course/${id}`, {
    method: 'GET',
  });
}

export async function queryContract(params) {
  return request('/api/contracts', {
    method: 'GET',
    params,
  });
}

export async function queryTeachers(params) {
  return request('/api/users/teachers', {
    method: 'GET',
    params,
  });
}

export async function getAllCourses(params) {
  return request('/api/course/getAllCourses', {
    method: 'GET',
    params,
  });
}

export async function getStudyStageReport(payload) {
  return request('/api/course/getStudyStageReport', {
    method: 'POST',
    data: payload,
  });
}

export async function queryForSelect(params) {
  return request('/api/course/getAll', {
    method: 'GET',
    params,
  });
}
