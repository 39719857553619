module.exports = {
  operation: {
    label: '教务团队',
    value: 'operation',
    multipleOwners: true,
    multipleOwning: false,
    ownerMemberExclusive: false,
    ownerRoles: ['operations'],
    memberRoles: ['operations'],
  },
  //销售团队
  sales: {
    label: '销售团队',
    value: 'sales',
    multipleOwners: false,
    multipleOwning: true,
    ownerMemberExclusive: false,
    ownerRoles: ['sales'],
    memberRoles: ['sales'],
  },
};
