import CreateForm from '@/components/Lesson/CreateForm';
import LessonInfoModal from '@/components/Lesson/LessonInfoModal';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, message } from 'antd';
import { useState } from 'react';

const CreateModalForm = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [infoLesson, setInfoLesson] = useState();
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setInfoLesson();
          setModalVisible(true);
        }}
      >
        排课
      </Button>
      <Modal
        width={720}
        destroyOnClose
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        footer={null}
      >
        <CreateForm
          onFinish={(lesson) => {
            if (lesson && lesson.id) {
              setInfoLesson(lesson);
              message.success(
                <>
                  {lesson.name}: 排课成功！
                  <Button
                    size="small"
                    type="link"
                    onClick={() => {
                      setInfoModalVisible(true);
                    }}
                  >
                    显示课程提醒
                  </Button>
                </>,
              );
              setModalVisible(false);
            }
          }}
        />
      </Modal>
      <LessonInfoModal
        key="info"
        title="课程信息"
        lesson={infoLesson}
        visible={infoModalVisible}
        closeModal={() => {
          setInfoModalVisible(false);
          setInfoLesson();
        }}
      />
    </>
  );
};
export default CreateModalForm;
