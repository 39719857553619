import { Popover } from 'antd';
import TeacherEvaluation from '../TeacherEvaluation/TeacherEvaluation';
import { queryTeacherRate } from '../TeacherEvaluation/service';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import MaxCountTimeline from '../Timeline/MaxCountTimeline';
import MaxCharacterTextBlock from '../Text/MaxCharacterTextBlock';
import { getUsername } from '@/utils/utils';
import { Chart, Point, Line, Axis, Area, Tooltip, Coordinate } from 'bizcharts';
import DataSet from '@antv/data-set';

const rateItemMap = {
  teachingLevel: '教学水平',
  attendanceRate: '出勤率',
  workCooperation: '工作配合度',
  behaviorInClass: '课上行为举止',
  beforeClassCommunication: '课前沟通',
  afterClassFeedback: '课后反馈',
  groupAnswerSpeed: '群答速度',
  studentActualEvaluation: '学生实际评价',
};

const TeacherInfo = ({ teacherInfo, title, ...props }) => {
  const [comments, setComments] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [hasData, setHasData] = useState(false);

  const { DataView } = DataSet;
  const dv = new DataView().source(data);
  dv.transform({
    type: 'fold',
    fields: [`${getUsername(teacherInfo)}`], // 展开字段集
    key: 'user', // key字段
    value: 'score', // value字段
  });

  useEffect(() => {
    const fetchData = async () => {
      const teacherGradeData = await queryTeacherRate({
        id: teacherInfo.id,
      });
      if (teacherGradeData && teacherGradeData.length > 0) {
        setHasData(true);
      }
      const teachingLevelAvg =
        teacherGradeData.reduce((acc, cur) => acc + Number(cur.teachingLevel), 0) /
        teacherGradeData.length;
      const attendanceRateAvg =
        teacherGradeData.reduce((acc, cur) => acc + Number(cur.attendanceRate), 0) /
        teacherGradeData.length;
      const workCooperationAvg =
        teacherGradeData.reduce((acc, cur) => acc + Number(cur.workCooperation), 0) /
        teacherGradeData.length;
      const behaviorInClassAvg =
        teacherGradeData.reduce((acc, cur) => acc + Number(cur.behaviorInClass), 0) /
        teacherGradeData.length;
      const beforeClassCommunicationAvg =
        teacherGradeData.reduce((acc, cur) => acc + Number(cur.beforeClassCommunication), 0) /
        teacherGradeData.length;
      const afterClassFeedbackAvg =
        teacherGradeData.reduce((acc, cur) => acc + Number(cur.afterClassFeedback), 0) /
        teacherGradeData.length;
      const groupAnswerSpeedAvg =
        teacherGradeData.reduce((acc, cur) => acc + Number(cur.groupAnswerSpeed), 0) /
        teacherGradeData.length;
      const studentActualEvaluationAvg =
        teacherGradeData.reduce((acc, cur) => acc + Number(cur.studentActualEvaluation), 0) /
        teacherGradeData.length;

      setData([
        {
          item: rateItemMap.teachingLevel,
          [`${getUsername(teacherInfo)}`]: teachingLevelAvg,
        },
        {
          item: rateItemMap.attendanceRate,
          [`${getUsername(teacherInfo)}`]: attendanceRateAvg,
        },
        {
          item: rateItemMap.workCooperation,
          [`${getUsername(teacherInfo)}`]: workCooperationAvg,
        },
        {
          item: rateItemMap.behaviorInClass,
          [`${getUsername(teacherInfo)}`]: behaviorInClassAvg,
        },
        {
          item: rateItemMap.beforeClassCommunication,
          [`${getUsername(teacherInfo)}`]: beforeClassCommunicationAvg,
        },
        {
          item: rateItemMap.afterClassFeedback,
          [`${getUsername(teacherInfo)}`]: afterClassFeedbackAvg,
        },
        {
          item: rateItemMap.groupAnswerSpeed,
          [`${getUsername(teacherInfo)}`]: groupAnswerSpeedAvg,
        },
        {
          item: rateItemMap.studentActualEvaluation,
          [`${getUsername(teacherInfo)}`]: studentActualEvaluationAvg,
        },
      ]);

      if (teacherGradeData && teacherGradeData.length > 0) {
        setComments(teacherGradeData.filter((el) => el.comment));
      }
    };
    fetchData();
  }, [teacherInfo.id, open]);

  const items = comments.map((item) => {
    return {
      children: (
        <div>
          <MaxCharacterTextBlock originalContent={item.comment} maxCharacters={20} />
          <div
            style={{
              fontSize: '12px',
              color: 'rgba(0, 0, 0, 0.45)',
            }}
          >
            {dayjs(item.createdAt).format('YYYY-MM-DD')}
            <strong>由</strong>
            {getUsername(item.createdBy)}
            <strong>更新</strong>
          </div>
        </div>
      ),
    };
  });

  return (
    <>
      <Popover
        content={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              maxWidth: '700px',
            }}
          >
            <div>
              <div>用户名: {teacherInfo?.username}</div>
              <div>
                电话: +{teacherInfo?.phonePrefix}-{teacherInfo?.phone}
              </div>
              <div>微信: {teacherInfo?.wechatId}</div>
              <div>
                {comments && comments.length > 0 && (
                  <div>
                    <div
                      style={{
                        marginBottom: '10px',
                      }}
                    >
                      评价:
                    </div>
                    <MaxCountTimeline items={items} mode="left" />
                  </div>
                )}
              </div>
            </div>

            {hasData && (
              <Chart
                height={300}
                width={500}
                data={dv.rows}
                autoFit
                scale={{
                  score: {
                    min: 0,
                    max: 10,
                    tickInterval: 1,
                  },
                }}
                interactions={['legend-highlight']}
              >
                <Coordinate type="polar" radius={0.8} />
                <Tooltip shared />
                <Point position="item*score" color="user" shape="circle" />
                <Line position="item*score" color="user" size={2} />
                <Area position="item*score" color="user" />
                <Axis name="score" grid={{ line: { type: 'line' } }} />
                <Axis
                  name="item"
                  line={false}
                  verticalLimitLength={60}
                  label={{
                    autoHide: false,
                    autoEllipsis: false,
                  }}
                />
              </Chart>
            )}
          </div>
        }
        title={
          <div>
            {title}
            <TeacherEvaluation teacherId={teacherInfo.id} />
          </div>
        }
        trigger="click"
        open={open}
        onOpenChange={(visible) => {
          setOpen(visible);
        }}
        width={500}
        {...props}
      >
        <a>{getUsername(teacherInfo)}</a>
      </Popover>
    </>
  );
};

export default TeacherInfo;
