export const riskLevelRadioArray = [
  {
    label: '高',
    value: '高',
  },
  {
    label: '中',
    value: '中',
  },
  {
    label: '低',
    value: '低',
  },
];

export const academicPerformanceRadioArray = [
  {
    label: '高',
    value: '高',
  },
  {
    label: '中',
    value: '中',
  },
  {
    label: '低',
    value: '低',
  },
  {
    label: '中上',
    value: '中上',
  },
  {
    label: '中下',
    value: '中下',
  },
];

export const typesToFields = {
  考试辅导: ['examDate'],
  论文辅导: ['essayDeadline', 'essayWordCount'],
  作业辅导: ['assignmentDeadline'],
};

export const typeSelectArray = [
  {
    label: '考试辅导',
    value: '考试辅导',
  },
  {
    label: '课程辅导',
    value: '课程辅导',
  },
  {
    label: '论文辅导',
    value: '论文辅导',
  },
  {
    label: '预习课',
    value: '预习课',
  },
  {
    label: '作业辅导',
    value: '作业辅导',
  },
  {
    label: '面试辅导',
    value: '面试辅导',
  },
  {
    label: '远程实训',
    value: '远程实训',
  },
];

export const reasonCascaderArray = [
  {
    value: '新老师匹配',
    label: '新老师匹配',
  },
  {
    value: '替换原老师',
    label: '替换原老师',
    children: [
      {
        value: '学生不满意',
        label: '学生不满意',
      },
      {
        value: '辅导成绩不理想',
        label: '辅导成绩不理想',
      },
      {
        value: '老师离职',
        label: '老师离职',
      },
      {
        value: '忙碌没时间',
        label: '忙碌没时间',
      },
      {
        value: '时间不匹配',
        label: '时间不匹配',
      },
      {
        value: '内容无法辅导',
        label: '内容无法辅导',
      },
      {
        value: '老师不满意学生',
        label: '老师不满意学生',
      },
      {
        value: '老师身体原因',
        label: '老师身体原因',
      },
      {
        value: '其他',
        label: '其他',
      },
    ],
  },
  {
    value: '临时代课',
    label: '临时代课',
    children: [
      {
        value: '忙碌没时间',
        label: '忙碌没时间',
      },
      {
        value: '时间不匹配',
        label: '时间不匹配',
      },
      {
        value: '内容无法辅导',
        label: '内容无法辅导',
      },
      {
        value: '老师身体原因',
        label: '老师身体原因',
      },
      {
        value: '其他',
        label: '其他',
      },
    ],
  },
];

export const reasonCascaderSearchArray = [
  {
    value: '新老师匹配',
    label: '新老师匹配',
  },
  {
    value: '替换原老师',
    label: '替换原老师',
    children: [
      {
        value: '全部',
        label: '全部',
      },
      {
        value: '学生不满意',
        label: '学生不满意',
      },
      {
        value: '辅导成绩不理想',
        label: '辅导成绩不理想',
      },
      {
        value: '老师离职',
        label: '老师离职',
      },
      {
        value: '忙碌没时间',
        label: '忙碌没时间',
      },
      {
        value: '时间不匹配',
        label: '时间不匹配',
      },
      {
        value: '内容无法辅导',
        label: '内容无法辅导',
      },
      {
        value: '老师不满意学生',
        label: '老师不满意学生',
      },
      {
        value: '老师身体原因',
        label: '老师身体原因',
      },
      {
        value: '其他',
        label: '其他',
      },
    ],
  },
  {
    value: '临时代课',
    label: '临时代课',
    children: [
      {
        value: '全部',
        label: '全部',
      },
      {
        value: '忙碌没时间',
        label: '忙碌没时间',
      },
      {
        value: '时间不匹配',
        label: '时间不匹配',
      },
      {
        value: '内容无法辅导',
        label: '内容无法辅导',
      },
      {
        value: '老师身体原因',
        label: '老师身体原因',
      },
      {
        value: '其他',
        label: '其他',
      },
    ],
  },
];
