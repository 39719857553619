import { getOperationTeams as getTeams } from '@/services/team';
import dayjs from 'dayjs';
import { getTeamsByCategory } from '../../../Account/Team/service';
import { getDataByOperation, getTeamMembers, getTeamMembersLessons } from './service';

const Model = {
  namespace: 'operationAdminWorkplace',
  state: {
    loading: false,
    members: [],
    dates: [dayjs().tz('Asia/Shanghai').startOf('day'), dayjs().tz('Asia/Shanghai').endOf('day')],
    teams: [],
    operationsStudent: [],
  },

  effects: {
    *fetchDataByOperation({ payload }, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: {
          loading: true,
          operationsStudent: [],
        },
      });
      const dates = yield select((state) => state.operationAdminWorkplace.dates);
      const { operationId } = payload;
      const timeZone = 'Asia/Shanghai';
      if (!operationId) return;

      const params = {
        operationId,
        startAt: dates[0].startOf('month').tz(timeZone).toISOString(),
        endAt: dates[1].endOf('month').tz(timeZone).toISOString(),
      };

      const members = yield call(getDataByOperation, params);

      if (members?.length) {
        const membersLessons = yield call(getTeamMembersLessons, {
          userIds: members.map((m) => m.key),
          startAt: dates[0].startOf('month').tz(timeZone).toISOString(),
          endAt: dates[1].endOf('month').tz(timeZone).toISOString(),
        });

        if (membersLessons?.length) {
          members.forEach((m) => {
            const match = membersLessons.find((user) => user.id === m.key);

            if (match?.operatingLessons) {
              m.operatingLessons = match.operatingLessons;
            }
          });
        }
      }

      yield put({
        type: 'updateState',
        payload: {
          operationsStudent: members,
          loading: false,
        },
      });
    },
    *fetchTeamMembers({ payload }, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: {
          loading: true,
          members: [],
        },
      });

      const dates = yield select((state) => state.operationAdminWorkplace.dates);

      const { ownerId, teamId, all } = payload;

      const timeZone = 'Asia/Shanghai';

      let members = [];

      if (all) {
        const teams = yield call(getTeams);
        for (let team of teams) {
          const owner = team.owners[0]?.id;
          const params = {
            ownerId: owner,
            teamId: team.id,
            category: 'operation',
            startAt: dates[0].startOf('month').tz(timeZone).toISOString(),
            endAt: dates[1].endOf('month').tz(timeZone).toISOString(),
          };
          const teamMembers = yield call(getTeamMembers, params);
          members = members.concat(teamMembers);
        }
      } else {
        const params = {
          ownerId,
          teamId,
          category: 'operation',
          startAt: dates[0].startOf('month').tz(timeZone).toISOString(),
          endAt: dates[1].endOf('month').tz(timeZone).toISOString(),
        };

        members = yield call(getTeamMembers, params);
      }

      if (members?.length) {
        const membersLessons = yield call(getTeamMembersLessons, {
          userIds: members.map((m) => m.key),
          startAt: dates[0].startOf('month').tz(timeZone).toISOString(),
          endAt: dates[1].endOf('month').tz(timeZone).toISOString(),
        });

        if (membersLessons?.length) {
          members.forEach((m) => {
            const match = membersLessons.find((user) => user.id === m.key);

            if (match?.operatingLessons) {
              m.operatingLessons = match.operatingLessons;
            }
          });
        }
      }

      yield put({
        type: 'updateState',
        payload: {
          members,
          loading: false,
        },
      });
    },

    *getOperationTeams({ payload }, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: {
          teams: [],
        },
      });

      const teams = yield call(getTeamsByCategory, { categories: 'operation' });

      yield put({
        type: 'updateState',
        payload: {
          teams,
        },
      });
    },
  },

  reducers: {
    updateState(state, action) {
      return { ...state, ...action.payload };
    },
  },
};
export default Model;
