import lodash from 'lodash';

export function roundToTwo(num) {
  return +(Math.round(num + 'e+2') + 'e-2');
}

/**
 * Get the correct [convert from] and [convert to] attribute id
 * if no exchange, both to and from are equal to the selected attribute id
 * @param {(string|number)} attr
 * @return {Object} const { to, from } = splitAttributeKey(attr)
 */
export function splitAttributeKey(attr) {
  if (!attr) return { from: 0, to: 0 };
  let toAttr = attr;
  let fromAttr = attr;
  if (String(attr).includes('-')) {
    const keys = attr.split('-');
    toAttr = keys[0];
    fromAttr = keys[1];
  }
  return { from: Number(fromAttr), to: Number(toAttr) };
}

/**
 * Get the exchange rate (ratio) between toAttr and fromAttr
 * point_needed_for_toAttr * ratio = point_to_substract_from_fromAttr
 * @param {Object} obj
 * @param {number|string} obj.key
 * @param {Object} obj.rules
 * @return {number} ratio
 */
export function getRatio({ key, rules, toAttrUnitPoint, fromAttrUnitPoint }) {
  if (!key || !rules || !rules[key]) return 0;
  const ratio = (rules[key].take * fromAttrUnitPoint) / (rules[key].give * toAttrUnitPoint);
  return ratio;
}

/**
 * Get contract's usable points
 * @param {Object} contract
 * @param {number} contract.usage
 * @param {number} contract.point
 * @param {Array} contract.lessons
 * @param {Object} contract.productUsage
 * @return {number} usablePoints
 */
export function getContractUsablePoints(contract) {
  let contractUsage = 0;
  const product = contract.products[0];
  if (!product) return 0;
  if (contract.lessons && contract.lessons.length) {
    contractUsage = contract.lessons.reduce((a, b) => {
      let num = Number(b.point);
      if (num === 0 && b.productAttribute) {
        let multiplier = 1;
        if (contract.productUsage[product.id][b.productAttribute.id].exchangeUnitQuantity) {
          if (b.productAttribute && b.productAttribute.chargingUnit === '分钟') {
            multiplier =
              Number(b.actualDuration || b.duration) /
              contract.productUsage[product.id][b.productAttribute.id].exchangeUnitQuantity;
          }
          if (b.productAttribute && b.productAttribute.chargingUnit === '字') {
            const words = b.wordCount || b.expectWordCount;
            multiplier =
              Number(words || 0) /
              contract.productUsage[product.id][b.productAttribute.id].exchangeUnitQuantity;
          }
          num =
            Number(contract.productUsage[product.id][b.productAttribute.id].exchangeUnitPoint) *
            multiplier;
        }
      }
      return a + num;
    }, 0);
  }
  contractUsage = roundToTwo(contractUsage);
  if (product.productAttributes?.length) {
    for (const a of product.productAttributes) {
      if (a.type === '开通' && !a.isFree) {
        if (
          a.id in contract.productUsage[product.id] &&
          contract.productUsage[product.id][a.id].limit > 0
        ) {
          contractUsage += contract.productUsage[product.id][a.id].exchangeUnitPoint;
        }
      }
    }
  }
  return roundToTwo(roundToTwo(contract.point) - contractUsage);
}

/**
 * Get points/quantity available && unavailable for the attribute
 * @param {number|string} attrId
 * @param {Object} contract
 * @return {Object} { available, unavailable, reservedMinutes, reservedWords, reservedTimes }
 */
export function getAttrUsage(attr, contract) {
  if (!attr || !contract) return 0;
  const product = contract.products[0];
  if (!product) return 0;
  const attrUsage = contract.productUsage[product.id][attr];
  if (!attrUsage) return 0;

  const { exchangeUnitPoint, exchangeUnitQuantity, limit } = attrUsage;
  const unavailable = contract.lessons?.reduce((a, b) => {
    if (b.productAttribute?.id !== Number(attr)) return a;
    if (b.productAttribute?.isFree) {
      let increment = 1;
      if (b.productAttribute.chargingUnit === '分钟') increment = b.actualDuration || b.duration;
      if (b.productAttribute.chargingUnit === '字') increment = b.wordCount || b.expectWordCount;
      return a + (increment || 0);
    }
    let num = Number(b.point);
    if (num === 0 && b.productAttribute) {
      let multiplier = 1;
      if (b.productAttribute?.chargingUnit === '分钟') {
        multiplier = Number(b.actualDuration || b.duration);
      }
      if (b.productAttribute?.chargingUnit === '字') {
        const words = b.wordCount || b.expectWordCount;
        multiplier = Number(words || 0);
      }
      if (exchangeUnitPoint && exchangeUnitQuantity) {
        num = multiplier * (exchangeUnitPoint / exchangeUnitQuantity);
      } else {
        num = multiplier;
      }
    }
    return a + num;
  }, 0);
  const available = Number(limit) - unavailable || 0;
  const reservedLessons = contract.lessons?.filter(
    (el) => !el.actualDuration && !el.wordCount && (el.duration || el.expectWordCount),
  );
  let reservedMinutes = 0,
    reservedWords = 0,
    reservedTimes = 0;
  for (let l of reservedLessons) {
    if (l.productAttribute?.id !== Number(attr)) {
      continue;
    }
    if (l.productAttribute.chargingUnit === '分钟') {
      reservedMinutes += l.duration;
    } else if (l.productAttribute.chargingUnit === '字') {
      reservedWords += l.expectWordCount;
    } else {
      reservedTimes += 1;
    }
  }
  return { available, unavailable, reservedMinutes, reservedWords, reservedTimes };
}

/**
 * get the attribute correspond to the current lesson, the attribute where the points come from, and the maximum usable quantity for the current lesson
 * @param {number|string} attrId
 * @param {Object} contract
 * @return {number} amount
 */
export function getExchangableAmount(exchangeKey, contract) {
  if (!exchangeKey || !contract) return 0;
  const product = contract.products[0];
  if (!product) return 0;
  const attrUsage = contract?.productUsage[product?.id];
  if (!attrUsage) return 0;
  const pair = exchangeKey.split('-');
  const from = pair[0];
  const to = pair[1];

  const fromAttrData = attrUsage[from];
  const toAttrData = attrUsage[to];
  if (!toAttrData || !fromAttrData) return 0;
  const { available: points_remained_in_fromAttr } = getAttrUsage(from, contract);
  if (to === from) return points_remained_in_fromAttr;

  const { exchangeUnitPoint: fromAttrUnitPoint } = fromAttrData;
  const { exchangeUnitPoint: toAttrUnitPoint, exchangeUnitQuantity } = toAttrData;
  // point_needed_for_toAttr * ratio = point_to_substract_from_fromAttr
  const ratio = getRatio({
    key: exchangeKey,
    rules: product.exchangeRules,
    fromAttrUnitPoint,
    toAttrUnitPoint,
  });
  if (ratio === 0) return 0;
  const points_remained_in_contract = getContractUsablePoints(contract);
  const exchangablePoints = Math.min(points_remained_in_fromAttr, points_remained_in_contract);
  if (exchangablePoints <= 0) return 0;
  const amountExchangable =
    exchangeUnitQuantity * Math.floor(exchangablePoints / (ratio * toAttrUnitPoint));
  return amountExchangable;
}

export function getAdjustedProductUsage({ exchangeKey, adjustAmount, usageData, rules }) {
  let result = lodash.cloneDeep(usageData);
  const [from, to] = exchangeKey.split('-');
  if (from === to) return result;
  let attrUsage = lodash.cloneDeep(usageData);
  let isProductUsage = false;
  const firstKey = Object.keys(usageData)[0];
  if (typeof usageData[firstKey] === 'object') {
    isProductUsage = true;
    attrUsage = lodash.cloneDeep(usageData[firstKey]);
  }
  const toAttrUnitPoint = attrUsage[to].exchangeUnitPoint;
  const fromAttrUnitPoint = attrUsage[from].exchangeUnitPoint;
  const ratio = getRatio({
    key: exchangeKey,
    rules,
    toAttrUnitPoint,
    fromAttrUnitPoint,
  });
  const to_points_increment = (adjustAmount * toAttrUnitPoint) / attrUsage[to].exchangeUnitQuantity;
  const from_points_reduction = to_points_increment * ratio;
  attrUsage[to].limit = attrUsage[to].limit + Math.round(to_points_increment * 100) / 100;
  attrUsage[from].limit = attrUsage[from].limit - Math.round(from_points_reduction * 100) / 100;
  if (isProductUsage) {
    result[firstKey] = attrUsage;
  } else {
    result = attrUsage;
  }
  return result;
}
