import { message } from 'antd';
import dayjs from 'dayjs';
import { getMonthsInDuration, getWeeksInDuration } from '../../../../../utils/utils';
import { download, getAllHeadTeachers, getAllTeams, query } from './service';

const model = {
  namespace: 'headTeacherStudentChart',

  state: {
    rawData: {},
    data: [],
    intervals: [],
    startAt: dayjs().startOf('month'),
    endAt: dayjs().endOf('day'),
    displayUnit: 'week',
    loading: false,
    currentTab: '合同',
    chartPosition: 'label*value',
    contractStatusFilter: '已开启服务',
    groupByTeam: false,
    teamLoading: true,
    teams: {},
    memberToTeam: {},
    teamOptions: [],
    selectedTeams: [],
    headTeacherOptions: [],
    selectedHeadTeachers: [],
    salesChannelFilter: '全部',
    consumptionUnit: '小时',
  },

  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ location }) => {
        if (location.pathname === '/dataCenter/pool') {
          dispatch({
            type: 'fetchTeams',
          });
          dispatch({
            type: 'fetchHeadTeachers',
          });
        }
      });
    },
  },

  effects: {
    *query(_, { put, call, select }) {
      yield put({
        type: 'updateState',
        payload: {
          loading: true,
        },
      });
      let startAt = yield select((state) => state.headTeacherStudentChart.startAt);
      let endAt = yield select((state) => state.headTeacherStudentChart.endAt);
      const currentTab = yield select((state) => state.headTeacherStudentChart.currentTab);
      if (currentTab === '月度带生数') {
        startAt = dayjs
          .tz(startAt.format('YYYY-MM-DD'), 'Asia/Shanghai')
          .startOf('month')
          .toISOString();
        endAt = dayjs.tz(endAt.format('YYYY-MM-DD'), 'Asia/Shanghai').endOf('month').toISOString();
      } else {
        startAt = dayjs
          .tz(startAt.format('YYYY-MM-DD'), 'Asia/Shanghai')
          .startOf('day')
          .toISOString();
        endAt = dayjs.tz(endAt.format('YYYY-MM-DD'), 'Asia/Shanghai').endOf('day').toISOString();
      }
      const data = yield call(query, {
        currentTab,
        startAt,
        endAt,
      });
      yield put({
        type: 'updateState',
        payload: {
          rawData: data.data,
        },
      });
      yield put({
        type: 'groupData',
      });
      yield put({
        type: 'updateState',
        payload: {
          loading: false,
        },
      });
    },

    *onDateRangeChange({ payload }, { put, select }) {
      const { dates } = payload;
      yield put({
        type: 'updateState',
        payload: {
          startAt: dates[0],
          endAt: dates[1],
        },
      });
      yield put({
        type: 'query',
      });
    },

    *onTabChange({ payload }, { put }) {
      const { value } = payload;
      if (value === '月度带生数') {
        yield put({
          type: 'updateState',
          payload: {
            displayUnit: 'month',
            startAt: dayjs().startOf('month'),
            endAt: dayjs().endOf('month'),
          },
        });
      }
      yield put({
        type: 'updateState',
        payload: {
          currentTab: value,
        },
      });
      yield put({
        type: 'query',
      });
    },

    *groupData(_, { put, select }) {
      const rawData = yield select((state) => state.headTeacherStudentChart.rawData);
      const displayUnit = yield select((state) => state.headTeacherStudentChart.displayUnit);
      const currentTab = yield select((state) => state.headTeacherStudentChart.currentTab);
      const startAt = yield select((state) => state.headTeacherStudentChart.startAt);
      const endAt = yield select((state) => state.headTeacherStudentChart.endAt);
      const contractStatusFilter = yield select(
        (state) => state.headTeacherStudentChart.contractStatusFilter,
      );
      const teams = yield select((state) => state.headTeacherStudentChart.teams);
      const memberToTeam = yield select((state) => state.headTeacherStudentChart.memberToTeam);
      const groupByTeam = yield select((state) => state.headTeacherStudentChart.groupByTeam);
      const selectedTeams = yield select((state) => state.headTeacherStudentChart.selectedTeams);
      const selectedHeadTeachers = yield select(
        (state) => state.headTeacherStudentChart.selectedHeadTeachers,
      );
      const salesChannelFilter = yield select(
        (state) => state.headTeacherStudentChart.salesChannelFilter,
      );
      const consumptionUnit = yield select(
        (state) => state.headTeacherStudentChart.consumptionUnit,
      );

      const data = [];
      const mapping = {};

      // const ke = {};
      let intervals = [];
      if (displayUnit === 'week') {
        intervals = getWeeksInDuration(startAt, endAt);
      }
      if (displayUnit === 'month') {
        intervals = getMonthsInDuration(startAt, endAt);
      }
      for (const i of intervals) {
        mapping[i.label] = {};
        const periodStart = i.start;
        const periodEnd = i.end;
        if (rawData) {
          if (['1V1付费课消', '合同'].includes(currentTab)) {
            for (let d of rawData) {
              if (currentTab === '1V1付费课消') {
                if (
                  salesChannelFilter === '非前途' &&
                  d.contract &&
                  d.contract.products &&
                  d.contract.products[0] &&
                  d.contract.products[0].salesChannels &&
                  d.contract.products[0].salesChannels.some((chan) =>
                    chan.name.includes('新东方前途'),
                  )
                ) {
                  continue;
                } else if (
                  salesChannelFilter === '前途' &&
                  (!d.contract ||
                    !d.contract.products ||
                    !d.contract.products[0] ||
                    !d.contract.products[0].salesChannels ||
                    !d.contract.products[0].salesChannels.some((chan) =>
                      chan.name.includes('新东方前途'),
                    ))
                ) {
                  continue;
                }
                const startTime = dayjs(d.startAt).unix();
                let key;
                const fullName = d.headTeacher.chineseName
                  ? `${d.headTeacher.chineseName}`
                  : `${d.headTeacher.lastName}${d.headTeacher.firstName}`;
                if (!groupByTeam) {
                  if (selectedHeadTeachers && selectedHeadTeachers.length) {
                    if (selectedHeadTeachers.includes(d.headTeacher.id)) {
                      key = fullName;
                    }
                  } else {
                    key = fullName;
                  }
                } else {
                  if (selectedTeams) {
                    const team = memberToTeam[d.headTeacher.id];
                    if (
                      !selectedTeams.length ||
                      (selectedTeams.length !== 1 && selectedTeams.includes(team))
                    ) {
                      key = teams[memberToTeam[d.headTeacher.id]];
                    }
                    if (selectedTeams.length === 1 && selectedTeams.includes(team)) {
                      key = fullName;
                    }
                  }
                }
                // if (Array.isArray(ke[d.headTeacher.id])) {
                //   ke[d.headTeacher.id].push(d);
                // } else {
                //   ke[d.headTeacher.id] = [d];
                // }

                if (key && startTime >= periodStart && startTime < periodEnd) {
                  let usage = d.actualDuration;
                  if (consumptionUnit === '字数') {
                    if (d.wordCount && !d.actualDuration) {
                      usage = d.wordCount;
                    } else {
                      usage = 0;
                    }
                  }
                  if (mapping[i.label][key]) {
                    mapping[i.label][key].value += usage;
                  } else {
                    mapping[i.label][key] = {
                      value: usage,
                    };
                  }
                }
              }
              if (currentTab === '合同') {
                let hit = false;
                if (contractStatusFilter === '未开启服务') {
                  hit = d.category === '班主任变更';
                } else {
                  hit = d.category?.includes(contractStatusFilter);
                }
                if (hit) {
                  let key;
                  let fullName;
                  if (contractStatusFilter === '未开启服务') {
                    fullName = d.newHeadTeacher.chineseName
                      ? `${d.newHeadTeacher.chineseName}`
                      : `${d.newHeadTeacher.lastName}${d.newHeadTeacher.firstName}`;
                  } else {
                    fullName = d.currentHeadTeacher.chineseName
                      ? `${d.currentHeadTeacher.chineseName}`
                      : `${d.currentHeadTeacher.lastName}${d.currentHeadTeacher.firstName}`;
                  }
                  if (!groupByTeam) {
                    if (selectedHeadTeachers && selectedHeadTeachers.length) {
                      if (
                        selectedHeadTeachers.includes(d.currentHeadTeacher?.id) ||
                        selectedHeadTeachers.includes(d.newHeadTeacher?.id)
                      ) {
                        key = fullName;
                      }
                    } else {
                      key = fullName;
                    }
                  } else {
                    if (selectedTeams) {
                      const team = memberToTeam[d.currentHeadTeacher?.id || d.newHeadTeacher?.id];
                      if (
                        !selectedTeams.length ||
                        (selectedTeams.length !== 1 && selectedTeams.includes(team))
                      ) {
                        key = teams[memberToTeam[d.currentHeadTeacher?.id || d.newHeadTeacher?.id]];
                      }
                      if (selectedTeams.length === 1 && selectedTeams.includes(team)) {
                        key = fullName;
                      }
                    }
                  }
                  let startTime;
                  if (d.category?.includes('已开启服务')) {
                    startTime = dayjs(d.openDate).unix();
                  } else {
                    startTime = dayjs(d.createdAt).unix();
                  }
                  if (key && startTime >= periodStart && startTime < periodEnd) {
                    if (mapping[i.label][key]) {
                      mapping[i.label][key].value += 1;
                    } else {
                      mapping[i.label][key] = {
                        value: 1,
                      };
                    }
                  }
                }
              }
            }
          }
          if (currentTab === '学生数') {
            const userIds = Object.keys(rawData);
            for (let id of userIds) {
              id = Number(id);
              let key;
              if (!groupByTeam) {
                if (selectedHeadTeachers && selectedHeadTeachers.length) {
                  if (selectedHeadTeachers.includes(id)) {
                    key = rawData[id].name;
                  }
                } else {
                  key = rawData[id].name;
                }
              } else {
                if (selectedTeams) {
                  const team = memberToTeam[`${id}`];
                  if (
                    !selectedTeams.length ||
                    (selectedTeams.length !== 1 && selectedTeams.includes(team))
                  ) {
                    key = teams[memberToTeam[`${id}`]];
                  }
                  if (selectedTeams.length === 1 && selectedTeams.includes(team)) {
                    key = rawData[id].name;
                  }
                }
              }
              if (key) {
                const students = new Set();
                const inRecords = rawData[id].in?.filter(
                  (r) => dayjs(r.createdAt).unix() < periodEnd,
                );
                const outRecords = rawData[id].out?.filter(
                  (r) => dayjs(r.createdAt).unix() < periodEnd,
                );
                const startedRecords = rawData[id].started?.filter(
                  (r) => dayjs(r.openDate).unix() < periodEnd,
                );
                const endedRecords = rawData[id].ended?.filter(
                  (r) => dayjs(r.createdAt).unix() < periodEnd,
                );
                const ins = [...startedRecords, ...inRecords];
                const outs = [...endedRecords, ...outRecords];
                for (const i of ins) {
                  const or = outs?.find((o) => o.contract.id === i.contract.id);
                  if (!or) {
                    students.add(i.contract.student.id);
                  }
                }
                if (mapping[i.label][key]) {
                  mapping[i.label][key].value += students.size;
                } else {
                  mapping[i.label][key] = {
                    value: students.size,
                  };
                }
              }
            }
          }
          if (currentTab === '月度带生数') {
            const userIds = Object.keys(rawData);
            for (let id of userIds) {
              id = Number(id);
              let key;
              if (!groupByTeam) {
                if (selectedHeadTeachers && selectedHeadTeachers.length) {
                  if (selectedHeadTeachers.includes(id)) {
                    key = rawData[id].name;
                  }
                } else {
                  key = rawData[id].name;
                }
              } else {
                if (selectedTeams) {
                  const team = memberToTeam[`${id}`];
                  if (
                    !selectedTeams.length ||
                    (selectedTeams.length !== 1 && selectedTeams.includes(team))
                  ) {
                    key = teams[memberToTeam[`${id}`]];
                  }
                  if (selectedTeams.length === 1 && selectedTeams.includes(team)) {
                    key = rawData[id].name;
                  }
                }
              }
              if (key) {
                const students = {};
                let total = 0;
                const ins = [...rawData[id].in, ...rawData[id].started];
                // the most recent one in the front
                const outs = [...rawData[id].out, ...rawData[id].ended].sort((a, b) => {
                  if (dayjs(a.createdAt).isBefore(b.createdAt)) {
                    return 1;
                  }
                  return -1;
                });
                for (const i of ins) {
                  const createdAt = dayjs(i.openDate || i.createdAt).unix();
                  if (createdAt < periodEnd) {
                    const o = outs.find((o) => o.contract.id === i.contract.id);
                    let end = periodEnd;
                    if (o) {
                      const outAt = dayjs(o.createdAt).unix();
                      if (outAt < periodEnd) {
                        end = outAt;
                      }
                    }
                    const duration = Math.max(0, end - Math.max(createdAt, periodStart));
                    if (!students[i.contract.student.id]) {
                      students[i.contract.student.id] = duration;
                    } else {
                      const curr = students[i.contract.student.id];
                      students[i.contract.student.id] = Math.max(curr, duration);
                    }
                  }
                }
                for (const s in students) {
                  total += students[s];
                }
                if (mapping[i.label][key]) {
                  mapping[i.label][key].value += total / (periodEnd - periodStart);
                } else {
                  mapping[i.label][key] = {
                    value: total / (periodEnd - periodStart),
                  };
                }
              }
            }
          }
        }
      }
      // for (const jiaowu in ke) {
      //   ke[jiaowu] = lodash.orderBy(ke[jiaowu], 'startAt');
      // }
      // console.log(ke);
      const labels = Object.keys(mapping);
      for (const label of labels) {
        const groups = mapping[label];
        const names = Object.keys(groups);
        for (const name of names) {
          data.push({
            name,
            label,
            value:
              currentTab === '1V1付费课消'
                ? Math.round((groups[name].value * 100) / (consumptionUnit === '小时' ? 60 : 1)) /
                  100
                : Math.round(groups[name].value * 100) / 100,
          });
        }
      }
      yield put({
        type: 'updateState',
        payload: {
          intervals,
          data,
          loading: false,
        },
      });
    },

    *fetchTeams(_, { put, call }) {
      yield put({
        type: 'updateState',
        payload: {
          teamLoading: true,
        },
      });
      const data = yield call(getAllTeams);
      const teams = {};
      const memberToTeam = {};
      const teamOptions = [];
      if (data && data.length) {
        for (const t of data) {
          if (t.owners && t.owners.length) {
            t.owners.forEach((o) => {
              memberToTeam[o.id] = t.id;
            });
          }
          if (t.members && t.members.length) {
            t.members.forEach((m) => {
              memberToTeam[m.id] = t.id;
            });
          }
          teams[t.id] = t.name;
          teamOptions.push({
            label: t.name,
            value: t.id,
          });
        }
      }
      yield put({
        type: 'updateState',
        payload: {
          teams,
          memberToTeam,
          teamOptions,
          teamLoading: false,
        },
      });
    },

    *fetchHeadTeachers(_, { put, call }) {
      const data = yield call(getAllHeadTeachers);
      const options = [];
      if (data && data.length) {
        for (const d of data) {
          options.push({
            label: d.chineseName || `${d.lastName}${d.firstName}`,
            value: d.id,
          });
        }
      }
      yield put({
        type: 'updateState',
        payload: {
          headTeacherOptions: options,
        },
      });
    },

    *onFilterChange({ payload }, { put }) {
      yield put({
        type: 'updateState',
        payload: {
          ...payload,
        },
      });

      yield put({
        type: 'groupData',
      });
    },

    *download(_, { call, select }) {
      const displayUnit = yield select((state) => state.headTeacherStudentChart.displayUnit);
      const intervals = yield select((state) => state.headTeacherStudentChart.intervals);
      const startAt = yield select((state) => state.headTeacherStudentChart.startAt);
      const endAt = yield select((state) => state.headTeacherStudentChart.endAt);

      message.loading({
        key: 'download',
        content: '正在获取文件...',
        duration: 0,
      });
      const params = {
        content: '教务带生情况统计',
        fileType: 'xlsx',
        data: {
          intervals,
          displayUnit,
          startAt,
          endAt,
        },
      };

      const res = yield call(download, params);

      if (res?.status === 200) {
        const tempLink = document.createElement('a');
        tempLink.href = res.data;
        tempLink.click();
        tempLink.remove();
        navigator.clipboard.writeText(res.data);
        message.success({
          key: 'download',
          content:
            '获取文件成功，即将开始下载。如果下载没有自动开始，请在网址导航栏右键粘贴剪贴板的链接，手动跳转下载。',
          duration: 5,
        });
      } else {
        message.error({
          key: 'download',
          content: 'Something went wrong... :(',
          duration: 3,
        });
      }
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default model;
