import { request } from '@@/plugin-request';

export async function createOperationKuaKuaBonus(payload) {
  return request('/api/wechat/operation/kuaKuaBonus', {
    method: 'POST',
    data: payload,
  });
}

//verifyErpUser
export async function verifyErpUser(payload) {
  return request('/api/wechat/verifyErpUser', {
    method: 'POST',
    data: payload,
  });
}

//getHeadTeachers
export async function getHeadTeachers(params) {
  return request('/api/wechat/headTeachers', {
    method: 'GET',
    params,
  });
}

export async function getHeadTeacherStudent(params) {
  return request('/api/wechat/headTeacherStudent', {
    method: 'GET',
    params,
  });
}

export async function getAllSales(params) {
  return request('/api/wechat/sales', {
    method: 'GET',
    params,
  });
}

export async function getConsultants(params) {
  return request('/api/wechat/consultant', {
    method: 'GET',
    params,
  });
}

export async function getUploadParams(params) {
  return request('/api/wechat/upload', {
    method: 'GET',
    params,
  });
}

//生成关联二维码
export async function generateRelationQrCode(params) {
  return request('/api/wechat/generateWechatQrCode', {
    method: 'GET',
    params,
  });
}

export async function sendKuakuaBonusMessageToLark(body) {
  return request('/api/wechat/sendKuakuaBonusMessageToLark', {
    method: 'POST',
    data: body,
  });
}

export async function resendKuakuaBonusMessageToLark(body) {
  return request('/api/wechat/resendKuakuaBonusMessageToLark', {
    method: 'POST',
    data: body,
  });
}

export async function getMonthlyKuakuaBonusData(body) {
  return request('/api/wechat/monthlyKuakuaBonusData', {
    method: 'POST',
    data: body,
  });
}

export async function getSalesByTeam(params) {
  return request('/api/users/salesByTeam', {
    method: 'GET',
    params,
  });
}

export async function createSalesBonus(body) {
  return request('/api/salesBonus', {
    method: 'POST',
    data: body,
  });
}

export function previewMonthlyKuakua(serverAddr, data) {
  return request(`${serverAddr}/monthlyKuakuaTable/preview`, {
    method: 'POST',
    data,
  });
}

export async function generateMonthlyKuakua(serverAddr, data) {
  return request(`${serverAddr}/monthlyKuakuaTable/generate`, {
    method: 'POST',
    data,
  });
}
