import { query as querySchool } from '../School/service';
import { create, query } from './service';

const SemesterModel = {
  namespace: 'semester',
  state: {
    onCreate: false,
    onSubmitLoading: false,
    createdSemester: null,
    semesterData: [],
    schoolData: [],
  },
  effects: {
    *create({ payload }, { call, put }) {
      const response = yield call(create, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            createdSemester: response,
            onSubmitLoading: false,
          },
        });

        yield put({
          type: 'query',
        });
      }
    },

    *query({ payload }, { call, put }) {
      const response = yield call(query, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            semesterData: response,
          },
        });
      }
    },

    *querySchool({ payload }, { call, put }) {
      const response = yield call(querySchool, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            schoolData: response,
          },
        });
      }
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    init(state, { payload }) {
      return {
        ...state,
        onCreate: true,
        createdSemester: null,
        onSubmitLoading: false,
      };
    },

    end(state, { payload }) {
      return {
        ...state,
        onCreate: false,
        createdSemester: null,
        onSubmitLoading: false,
      };
    },
  },
};

export default SemesterModel;
