import dayjs from '@/utils/dayjs.js';
import { useEffect, useState } from 'react';
import { Modal, Button, Badge, Spin, message } from 'antd';
import { isDstDate } from './service';
import { WarningOutlined, FileSearchOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { DSTtimeZones, DSTcountryName } from '@/utils/tz';

const DSTDatesDisplay = () => {
  const [dstDatesDisplay, setDstDatesDisplay] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    //获取现在的时间
    const now = new Date();
    //获取两周后的时间
    const twoWeeksLater = new Date(now.getTime() + 14 * 24 * 60 * 60 * 1000);
    //获取两周后的时间的字符串
    const twoWeeksLaterStr = twoWeeksLater.toISOString();
    const fetchData = async () => {
      //将上面五个国家的时区遍历，获取每个国家的dstDates
      setLoading(true);
      const result = await Promise.all(
        Object.keys(DSTtimeZones).map((country) =>
          isDstDate({
            //精确到秒转成字符串
            //TODO:这里改成now.toISOString(),增加错误处理
            startDate: new Date().toISOString(),
            endDate: twoWeeksLaterStr,
            timeZone: DSTtimeZones[country],
          }),
        ),
      );
      setLoading(false);
      const displayDates = result.filter((item) => item.date);
      setDstDatesDisplay(displayDates);
    };
    //错误处理
    try {
      fetchData();
    } catch (error) {
      console.log(error);
      message.error('获取夏令时/冬令时转化失败');
    }
  }, []);

  return (
    <>
      <Badge
        count={
          dstDatesDisplay && dstDatesDisplay.length > 0 ? (
            <ClockCircleOutlined style={{ color: '#f5222d' }} />
          ) : (
            0
          )
        }
        offset={[-5, 10]}
      >
        <Button onClick={() => setOpen(true)} loading={loading} icon={<FileSearchOutlined />}>
          查看夏令时/冬令时转化
        </Button>
      </Badge>

      <Modal
        title={
          <h2>
            <WarningOutlined
              style={{
                color: '#faad14',
              }}
            />
            以下国家将发生夏令时/冬令时转化：
          </h2>
        }
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width={800}
      >
        {loading && <Spin />}
        {!loading && dstDatesDisplay.length === 0 && <h3>两周内暂无常用国家有夏令时/冬令时转化</h3>}
        {dstDatesDisplay &&
          dstDatesDisplay.length > 0 &&
          dstDatesDisplay.map((item) => {
            const { timeZone, date } = item;
            //用timeZone获取对应timeZone的国家名
            const country = Object.keys(DSTtimeZones).find((key) => DSTtimeZones[key] === timeZone);
            const offset = dayjs.tz(date, timeZone).utcOffset();
            return (
              <div key={timeZone}>
                <h3>
                  <strong>{DSTcountryName[country]}</strong>将于当地时间：
                  {dayjs.tz(date, timeZone).format('YYYY-MM-DD HH:mm')}（<strong>北京时间</strong>：
                  {dayjs
                    .tz(date, timeZone)
                    .utcOffset(offset)
                    .tz('Asia/Shanghai')
                    .format('YYYY-MM-DD HH:mm')}
                  ） 变为<strong>{`${item.status}`}</strong>
                </h3>
              </div>
            );
          })}
      </Modal>
    </>
  );
};

export default DSTDatesDisplay;
