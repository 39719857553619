import { request } from '@@/plugin-request';

export async function queryProduct(params) {
  return request('/api/products', {
    params,
  });
}

export async function queryProductById(id) {
  return request(`/api/products/${id}`);
}

export async function countContract(id) {
  return request(`/api/products/countContract/${id}`);
}

export async function queryProductAttribute(params) {
  return request(`/api/products/attributes`, {
    params,
  });
}

export async function queryProductAttributeById(id) {
  return request(`/api/products/attributes/${id}`);
}

export async function queryProductService(params) {
  return request('/api/products/services', {
    params,
  });
}

export async function queryProductServiceById(id) {
  return request(`/api/products/services/${id}`);
}

export async function createProduct(payload) {
  return request('/api/products', {
    method: 'POST',
    data: payload,
  });
}

export async function createProductService(payload) {
  return request('/api/products/services', {
    method: 'POST',
    data: payload,
  });
}

export async function createProductAttribute(payload) {
  return request('/api/products/attributes', {
    method: 'POST',
    data: payload,
  });
}

export async function updateProductService(payload) {
  return request('/api/products/services', {
    method: 'PUT',
    data: payload,
  });
}

export async function updateProductAttribute(payload) {
  return request('/api/products/attributes', {
    method: 'PUT',
    data: payload,
  });
}

export async function updateProduct(payload) {
  return request('/api/products', {
    method: 'PUT',
    data: payload,
  });
}

export async function deleteProductService(payload) {
  return request('/api/products/services', {
    method: 'DELETE',
    data: payload,
  });
}

export async function deleteProductAttribute(payload) {
  return request('/api/products/attributes', {
    method: 'DELETE',
    data: payload,
  });
}

export async function deleteProduct(payload) {
  return request('/api/products', {
    method: 'DELETE',
    data: payload,
  });
}

export async function addMaterial(payload) {
  return request('/api/products/upload', {
    method: 'POST',
    data: payload,
  });
}

export async function getProductCategory(params) {
  return request('/api/products/category', {
    method: 'GET',
    params,
  });
}

export async function getSaleChannels(params) {
  return request('/api/saleschannel', {
    method: 'GET',
    data: params,
  });
}

export async function updateIsEnable(payload) {
  return request('/api/products/isEnable', {
    method: 'PUT',
    data: payload,
  });
}

export async function getBonusSchemes(params) {
  return request('/api/contractBonus/schemes', {
    method: 'GET',
    params,
  });
}

export async function getBonusSchemesById(id) {
  return request(`/api/contractBonus/schemes/${id}`, {
    method: 'GET',
  });
}
