import { queryHeadTeachers } from '@/services/user';
import { useQuery } from '@umijs/max';

export const useHeadTeachers = (params = {}) => {
  // Function to serialize params object
  const serializeParams = (paramsObj) => {
    return JSON.stringify(paramsObj, Object.keys(paramsObj).sort());
  };
  return useQuery({
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
    queryKey: ['user', 'headteacher', serializeParams(params)],
    queryFn: async ({ queryKey }) => {
      const data = await queryHeadTeachers(params);
      return data;
    },
  });
};
