export const ACADEMIC_LEVELS = {
  初中: '初中',
  高中: '高中',
  本科: '本科',
  硕士: '硕士',
  博士: '博士',
  博士后: '博士后',
};

const degreeOrder = ['博士后', '博士', '硕士', '本科', '高中', '初中'];

export function sortEducationRecords(educations = []) {
  const data = [...educations];
  let newSortFunc = (a, b) => {
    if (degreeOrder.includes(a.level) && degreeOrder.includes(b.level)) {
      if (
        degreeOrder.indexOf(ACADEMIC_LEVELS[a.level]) -
          degreeOrder.indexOf(ACADEMIC_LEVELS[b.level]) >
        0
      ) {
        return 1;
      } else if (
        degreeOrder.indexOf(ACADEMIC_LEVELS[a.level]) -
          degreeOrder.indexOf(ACADEMIC_LEVELS[b.level]) <
        0
      ) {
        return -1;
      }
    }
    if (a.admissionDate && b.admissionDate) {
      return new Date(b.admissionDate) - new Date(a.admissionDate);
    }

    return new Date(b.createdAt) - new Date(a.createdAt);
  };
  data.sort(newSortFunc);
  return data;
}

export function getSchoolNames(educations = []) {
  if (!educations || !educations.length) {
    return '未填写';
  }
  return educations
    .filter((el) => !!el.school)
    .map((el) => el.school.name)
    .join(',');
}

export function getMostRecentProgram(educations = []) {
  if (!educations || !educations.length) {
    return '未填写';
  }
  const sorted = sortEducationRecords(educations);
  return sorted[0].field;
}

export function getMostRecentEducation(educations = []) {
  if (!educations || !educations.length) {
    return {
      school: {
        name: '未填写',
      },
      level: '-',
      grade: '-',
      field: '-',
    };
  }
  const sorted = sortEducationRecords(educations);
  return sorted[0];
}
