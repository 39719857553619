import { Modal, Form, Input, Button, message } from 'antd';
import { useState } from 'react';
import { changeForgetPassword } from '@/services/login';

const ChangeForgottenPasswordModal = ({ currentUser }) => {
  const isForgottenPassword = currentUser?.isForgetPassword;
  const [visible, setVisible] = useState(isForgottenPassword);
  const onFinish = async (values) => {
    try {
      await changeForgetPassword(values);
      setVisible(false);
      message.success('修改密码成功');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      title="修改密码"
      open={visible}
      footer={null}
      onCancel={() => {}}
      destroyOnClose
      closeIcon={<></>}
    >
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          id: currentUser.id,
          username: currentUser.username,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="ID"
          name="id"
          hidden
          rules={[
            {
              required: true,
              message: '请输入ID',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="用户名"
          name="username"
          disabled
          rules={[
            {
              required: true,
              message: '请输入用户名',
            },
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="新密码"
          name="password"
          rules={[
            {
              required: true,
              message: '请输入新密码',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangeForgottenPasswordModal;
