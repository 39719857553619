import provinces from '@/utils/ConstantData/country_to_province.json';
import cities from '@/utils/ConstantData/province_to_city.json';
import counties from '@/utils/ConstantData/city_to_county.json';
import { Input, Select } from 'antd';
import { useState } from 'react';

const Address = ({ value, onChange, useChineseLable }) => {
  if (!value?.country) return <p>Please specify a country</p>;
  if (!provinces[value.country]) return <p>{value.country} is not a supported country</p>;
  const [street, setStreet] = useState(value?.street);
  const [county, setCounty] = useState(value?.county);
  const [city, setCity] = useState(value?.city);
  const [province, setProvince] = useState(value?.province);
  const [country, setCountry] = useState(value?.country);
  const [postalCode, setPostalCode] = useState(value?.postalCode);
  const noCountiesProvince = ['香港特别行政区', '澳门特别行政区'];
  const onStreetChange = (e) => {
    setStreet(e.target.value);
    onChange({ ...value, street: e.target.value });
  };
  const onPostalCodeChange = (e) => {
    setPostalCode(e.target.value);
    onChange({ ...value, postalCode: e.target.value });
  };
  const onProvinceChange = (v) => {
    setProvince(v);
    setCity();
    setCounty();
    onChange({ ...value, province: v, city: null, county: null });
  };
  const onCityChange = (v) => {
    setCity(v);
    setCounty();
    onChange({ ...value, city: v, county: null });
  };

  const onCountyChange = (v) => {
    setCounty(v);
    onChange({ ...value, county: v });
  };

  const onCountryChange = (v) => {
    setCountry(v);
    setProvince();
    setCity();
    setCounty();
    onChange({ ...value, country: v, province: null, city: null, county: null });
  };

  let displayChineseLable = useChineseLable || country === 'CN';

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <span>{displayChineseLable ? '街道:' : 'Street:'}</span>
        <Input
          value={street}
          onChange={onStreetChange}
          style={{ width: '100%', maxWidth: '450px' }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
        }}
      >
        <div style={{ width: '40%' }}>
          <span style={{ marginRight: 5 }}>{displayChineseLable ? '省:' : 'Province:'}</span>
          <Select
            showSearch
            style={{ width: '75%', maxWidth: '160px' }}
            value={province}
            onChange={onProvinceChange}
            options={provinces[country].map((p) => {
              return { label: p.name, value: p.name };
            })}
          />
        </div>
        <div style={{ width: '30%' }}>
          <span style={{ marginRight: 5 }}>{displayChineseLable ? '市:' : 'City:'}</span>
          <Select
            showSearch
            style={{ width: '75%', maxWidth: '160px' }}
            value={city}
            onChange={onCityChange}
            placeholder="Select a province first"
            options={
              !!province ? cities[country]?.[province]?.map((c) => ({ label: c, value: c })) : []
            }
          />
        </div>

        {(displayChineseLable || country === 'CN') && !noCountiesProvince.includes(province) && (
          <div style={{ width: '30%' }}>
            <span style={{ marginRight: 5 }}>县/区:</span>
            <Select
              showSearch
              style={{ width: '70%', maxWidth: '180px' }}
              value={county}
              onChange={onCountyChange}
              placeholder="Select a city first"
              options={!!city ? counties?.[city]?.map((c) => ({ label: c, value: c })) : []}
            />
          </div>
        )}
      </div>
      <div style={{ width: '30%' }}>
        <span
          style={{
            marginRight: 5,
          }}
        >
          {displayChineseLable ? '国家:' : 'Country:'}
        </span>
        <Select
          showSearch
          style={{ width: '50%', maxWidth: '100px' }}
          value={country}
          onChange={onCountryChange}
          options={[
            { label: 'CA', value: 'CA' },
            { label: 'US', value: 'US' },
            { label: 'AU', value: 'AU' },
            { label: 'GB', value: 'GB' },
            { label: 'DE', value: 'DE' },
            { label: 'CZ', value: 'CZ' },
            { label: 'ES', value: 'ES' },
            { label: 'CN', value: 'CN' },
          ]}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <span>{displayChineseLable ? '邮编:' : 'Postal Code:'}</span>
        <Input
          value={postalCode}
          onChange={onPostalCodeChange}
          style={{ width: '20%', minWidth: '150px', maxWidth: '200px' }}
        />
      </div>
    </div>
  );
};

export default Address;
