import { Form, Rate, Input, message, Modal, Button, Flex, Tag } from 'antd';
import { ProForm } from '@ant-design/pro-components';
import { useState } from 'react';
import { createTeacherRate } from './service';
import { EditFilled } from '@ant-design/icons';
import { useModel } from 'umi';

const TeacherEvaluation = ({ teacherId }) => {
  const [form] = Form.useForm();
  const [evaluationRate, setEvaluationRate] = useState({
    teachingLevel: 0,
    attendanceRate: 0,
    workCooperation: 0,
    behaviorInClass: 0,
    beforeClassCommunication: 0,
    afterClassFeedback: 0,
    groupAnswerSpeed: 0,
    studentActualEvaluation: 0,
  });

  const {
    initialState: { currentUser },
  } = useModel('@@initialState');

  let canNotEvaluate = false;
  const notAllowedRolesCombinations = [
    ['teacher'],
    ['qualityInspector'],
    ['teacher', 'qualityInspector'],
  ];

  if (currentUser.roles) {
    const userRolesSet = new Set(currentUser.roles.map((role) => role.name));
    for (const notAllowedCombination of notAllowedRolesCombinations) {
      if (
        userRolesSet.size === notAllowedCombination.length &&
        notAllowedCombination.every((role) => userRolesSet.has(role))
      ) {
        canNotEvaluate = true;
      }
    }
  }

  const [visible, setVisible] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const rateItems = [
    {
      label: '教学水平',
      rateItem: 'teachingLevel',
    },
    {
      label: '出勤率',
      rateItem: 'attendanceRate',
    },
    {
      label: '工作配合度',
      rateItem: 'workCooperation',
    },
    {
      label: '课上行为举止',
      rateItem: 'behaviorInClass',
    },
    {
      label: '课前沟通',
      rateItem: 'beforeClassCommunication',
    },
    {
      label: '课后反馈',
      rateItem: 'afterClassFeedback',
    },
    {
      label: '群答速度',
      rateItem: 'groupAnswerSpeed',
    },
    {
      label: '学生实际评价',
      rateItem: 'studentActualEvaluation',
    },
  ];

  //展示评分项
  const displayRateItems = (item) => {
    return (
      <Form.Item
        label={`${item.label}(${evaluationRate[item.rateItem]}分):`}
        name={item.rateItem}
        rules={[
          {
            required: true,
            message: `请给老师评${item.label}`,
          },
        ]}
      >
        <Rate
          count={5}
          allowHalf
          onChange={(value) => {
            setEvaluationRate((prevState) => ({
              ...prevState,
              [item.rateItem]: value * 2,
            }));
          }}
        />
      </Form.Item>
    );
  };

  const tagsData = [
    '回复慢',
    '认真细致',
    '负责任',
    '回复及时',
    '跟的紧',
    '备课比较认真',
    '不爱备课',
    '喜欢现挂',
    '润色比较详细',
    '逐字逐句修改',
    '润色只改大框架',
    '润色直接在原文里改',
    '态度不好',
    '沟通不顺畅',
    '脾气大',
    '会在学生群里乱说话',
    '经常改上课时间',
    '语言习惯不好',
    '说话喜欢带脏字',
    '喜欢要补贴',
    '会促课',
    '不好约时间',
    '不跟学生沟通',
    '不发课后反馈',
    '不做首次沟通',
    '接课不看匹配信息',
  ];

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
    form.setFieldsValue({ tags: nextSelectedTags });
  };

  const resetForm = () => {
    form.resetFields();
    setEvaluationRate({
      teachingLevel: 0,
      attendanceRate: 0,
      workCooperation: 0,
      behaviorInClass: 0,
      beforeClassCommunication: 0,
      afterClassFeedback: 0,
      groupAnswerSpeed: 0,
      studentActualEvaluation: 0,
    });
    setSelectedTags([]);
  };

  return (
    <>
      {!canNotEvaluate && (
        <Button
          type="link"
          icon={<EditFilled />}
          onClick={() => {
            setVisible(true);
          }}
        >
          填写评价
        </Button>
      )}

      <Modal
        title="填写老师评价"
        open={visible}
        onCancel={() => {
          setVisible(false);
          setEvaluationRate({
            teachingLevel: 0,
            attendanceRate: 0,
            workCooperation: 0,
            behaviorInClass: 0,
            beforeClassCommunication: 0,
            afterClassFeedback: 0,
            groupAnswerSpeed: 0,
            studentActualEvaluation: 0,
          });

          setSelectedTags([]);
          form.resetFields();
        }}
        destroyOnClose
        footer={[]}
        width={700}
      >
        <ProForm
          form={form}
          onFinish={async (values) => {
            try {
              //如果和最新的评分一样，并且在同一天，则让用户确认
              // if (
              //   latestComment &&
              //   Number(latestComment.rate) === rate &&
              //   dayjs(latestComment.createdAt).isSame(dayjs(), 'day')
              // ) {
              //   return Modal.confirm({
              //     title: '确定提交评分吗？',
              //     content: `最新评分为${latestComment.rate}分和您提交的评分一致，由${getUsername(latestComment.createdBy)}于${dayjs(latestComment.createdAt).format('YYYY-MM-DD')}评价`,
              //     onOk: async () => {
              //       await createTeacherRate({
              //         id: teacherId,
              //         tags: selectedTags,
              //         comment: values.comment,
              //       });
              //       message.success('评价成功');
              //       setVisible(false);
              //       resetForm();
              //     },
              //     onCancel() {
              //       setVisible(false);
              //       resetForm();
              //       return false;
              //     },
              //   });
              // } else {
              //   await createTeacherRate({
              //     id: teacherId,
              //     rate: rate,
              //     tags: selectedTags,
              //     comment: values.comment,
              //   });
              //   message.success('评价成功');
              //   setVisible(false);
              //   resetForm();
              //   return;
              // }
              await createTeacherRate({
                teacherId: teacherId,
                tags: selectedTags,
                comment: values.comment,
                ...evaluationRate,
              });
              message.success('评价成功');
              setVisible(false);
              resetForm();
              setEvaluationRate({
                teachingLevel: 0,
                attendanceRate: 0,
                workCooperation: 0,
                behaviorInClass: 0,
                beforeClassCommunication: 0,
                afterClassFeedback: 0,
                groupAnswerSpeed: 0,
                studentActualEvaluation: 0,
              });
              setSelectedTags([]);
            } catch (error) {
              console.error(error);
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
              flexDirection: 'row',
            }}
          >
            {rateItems.map((item) => displayRateItems(item))}
          </div>

          <Form.Item label="标签:" name="tags">
            <Flex
              gap="small"
              wrap
              align="center"
              style={{
                maxWidth: '400',
                flexWrap: 'wrap',
              }}
            >
              {tagsData.map((tag) => (
                <Tag.CheckableTag
                  style={{
                    background: selectedTags.includes(tag) ? '#1890ff' : '#f0f0f0',
                    color: selectedTags.includes(tag) ? 'white' : 'black',
                  }}
                  key={tag}
                  checked={selectedTags.includes(tag)}
                  onChange={(checked) => handleChange(tag, checked)}
                >
                  {tag}
                </Tag.CheckableTag>
              ))}
            </Flex>
          </Form.Item>

          <Form.Item
            label="评价:"
            name="comment"
            style={{
              maxWidth: '100%',
            }}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
        </ProForm>
      </Modal>
    </>
  );
};

export default TeacherEvaluation;
