export function getAmountByIntervalAndCurrency(level, currency, table) {
  const salaryLevel = ['一档', '二档', '三档', '四档', '五档', '六档', '七档', '八档'];
  const currencies = ['RMB', 'CAD', 'USD', 'GBP', 'AUD'];

  const salaryIndex = salaryLevel.indexOf(level);
  const currencyIndex = currencies.indexOf(currency);

  if (salaryIndex === -1) {
    throw new Error('Invalid interval');
  }

  if (currencyIndex === -1) {
    throw new Error('Invalid currency');
  }

  return table[salaryIndex][currencyIndex];
}

export function getTableByType(academicLevel) {
  // 根据学术等级定要使用的表格
  if (academicLevel === '高中') {
    return [
      [150, 30, 30, 20, 30],
      [175, 35, 35, 25, 35],
      [200, 40, 40, 30, 40],
      [225, 45, 45, 35, 45],
      [250, 50, 50, 40, 50],
      [275, 55, 55, 45, 55],
      [300, 60, 60, 50, 60],
      [325, 65, 65, 55, 65],
    ];
  } else if (academicLevel === '大学') {
    return [
      [150, 30, 30, 20, 30],
      [175, 35, 35, 25, 35],
      [200, 40, 40, 30, 40],
      [225, 45, 45, 35, 45],
      [250, 50, 50, 40, 50],
      [275, 55, 55, 45, 55],
      [300, 60, 60, 50, 60],
      [325, 65, 65, 55, 65],
    ];
  } else if (academicLevel === '研究生') {
    return [
      [150, 30, 30, 20, 30],
      [175, 35, 35, 25, 35],
      [200, 40, 40, 30, 40],
      [225, 45, 45, 35, 45],
      [250, 50, 50, 40, 50],
      [275, 55, 55, 45, 55],
      [300, 60, 60, 50, 60],
      [325, 65, 65, 55, 65],
    ];
  }

  // 如果无法确定要使用的表格，则返回 null 或抛出错误
  return null;
}

//根据挡位决定下一档时长
export function getDurationByLevel(level) {
  const timeMap = new Map([
    ['一档', 3000],
    ['二档', 3000],
    ['三档', 3000],
    ['四档', 4800],
    ['五档', 6000],
    ['六档', 7500],
    ['七档', 12000],
  ]);

  return timeMap.get(level);
}
