import CourseMatchInfoModal from '@/components/CourseMatch/CourseMatchInfoModal';
import CreateCourseMatch from '@/components/CourseMatch/CreateCourseMatch';
import { ApiOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useState } from 'react';

const CreateModalForm = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [courseMatchRecord, setCourseMatchRecord] = useState();
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  return (
    <>
      <Button
        icon={<ApiOutlined />}
        onClick={() => {
          setCourseMatchRecord();
          setModalVisible(true);
        }}
      >
        匹配需求
      </Button>
      <Modal
        width={720}
        destroyOnClose
        maskClosable={false}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        footer={null}
      >
        <CreateCourseMatch
          setCourseMatchModalVisible={setModalVisible}
          setCourseMatchInfoModalVisible={setInfoModalVisible}
          setCourseMatchRecord={setCourseMatchRecord}
        />
      </Modal>
      <CourseMatchInfoModal
        title="需求匹配信息"
        record={courseMatchRecord}
        courseMatchInfoModalVisible={infoModalVisible}
        setCourseMatchInfoModalVisible={setInfoModalVisible}
      />
    </>
  );
};
export default CreateModalForm;
