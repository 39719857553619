import { get4User, updateReadStatus } from '@/components/Notice/service';
import { getUsername } from '@/utils/utils';
import { message, notification } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useModel } from 'umi';

const keyOptions = {
  allNotice: 'allNotice',
  readNotice: 'readNotice',
  unreadNotice: 'unreadNotice',
};
export const displayDate = (inputDate, format = 'YYYY-MM-DD HH:mm') => {
  return dayjs(inputDate).format(format);
};
export default function useNoticesModel() {
  const { initialState, initialStateLoading } = useModel('@@initialState', (model) => ({
    initialState: model.initialState,
    initialStateLoading: model.loading,
  }));

  const [requestData, setRequestData] = useState();
  const [intervalId, setIntervalId] = useState(null);
  const [isClosedByUser, setIsClosedByUser] = useState();
  const [lastUnreadNumber, setLastUnreadNumber] = useState(0);
  const [noticeCenterOpen, setNoticeCenterOpen] = useState(false);

  const getNoticeCount = (inputData) => {
    const data = inputData || requestData;
    if (!data) {
      return { allNoticeLength: 0, unreadNoticeLength: 0, readNoticeLength: 0 };
    }
    const allNoticeLength = data.allNotice.length;
    const unreadNoticeLength = data.unreadNoticeId.length;
    return {
      allNoticeLength,
      unreadNoticeLength,
      readNoticeLength: allNoticeLength - unreadNoticeLength,
    };
  };

  const getNotices = async () => {
    try {
      const racePromise = Promise.race([
        get4User(),
        new Promise((_, reject) => {
          setTimeout(() => {
            reject(new Error('request timed out'));
          }, 5000);
        }),
      ]);
      const data = await racePromise;
      setRequestData(data);
    } catch (error) {
      clearInterval(intervalId);
      console.error('get Notices for user failed', error);
    }
  };

  useEffect(() => {
    if (requestData) {
      const noticeCount = getNoticeCount();
      const unreadNoticeLength = noticeCount.unreadNoticeLength;

      if (unreadNoticeLength) {
        if ((!isClosedByUser || lastUnreadNumber < unreadNoticeLength) && !noticeCenterOpen) {
          const CustomNotification = () => (
            <div
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => {
                setNoticeCenterOpen(true);
                notification.destroy('noticeReminder');
              }}
            >
              点击查看
            </div>
          );

          notification.open({
            message: `您有${unreadNoticeLength}条未读通知`,
            description: <CustomNotification />,
            duration: 0,
            key: 'noticeReminder',
            onClose: () => {
              setIsClosedByUser(true);
            },
          });
          if (isClosedByUser) {
            setIsClosedByUser(false);
          }
        }
      }
      setLastUnreadNumber(unreadNoticeLength);
    }
  }, [requestData]);

  const updateStatus = useCallback(async (id, markAsRead) => {
    const res = await updateReadStatus({ id, markAsRead })
      .then(async (res) => {
        await getNotices();
        message.success('更新已读状态成功');
      })
      .catch((err) => {
        message.error('更新已读状态失败', err);
      });
  }, []);

  useEffect(() => {
    if (!initialStateLoading) {
      getNotices();

      // get new notice every 300 seconds
      const intervalId = setInterval(() => {
        getNotices();
      }, 1000 * 300);
      setIntervalId(intervalId);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [initialStateLoading]);

  const getDataSource = (activeKey) => {
    if (!requestData?.allNotice) {
      return;
    }
    let displayData = requestData.allNotice;
    if (activeKey === keyOptions.allNotice) {
    } else if (activeKey === keyOptions.unreadNotice) {
      displayData = displayData.filter((item) => requestData.unreadNoticeId.includes(item.id));
    } else if (activeKey === keyOptions.readNotice) {
      displayData = displayData.filter((item) => !requestData.unreadNoticeId.includes(item.id));
    }

    return displayData.map((item) => ({
      name: item.title,
      content: item.content,
      desc: `创建人: ${getUsername(item.createdBy)}       发布于${displayDate(
        item.createdAt,
      )}       有效期: ${displayDate(item.validFrom)} 至 ${displayDate(item.expireAt)}`,
      id: item.id,
      unread: requestData.unreadNoticeId.includes(item.id),
    }));
  };

  return {
    requestData,
    noticeCount: getNoticeCount(),
    getNotices,
    getDataSource,
    updateStatus,
    noticeCenterOpen,
    setNoticeCenterOpen,
    setIsClosedByUser,
  };
}
