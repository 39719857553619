import { message } from 'antd';
import { downloadData } from './service';

const DataDownloadModel = {
  namespace: 'dataDownload',

  state: {
    studentDownloadLoading: false,
  },

  effects: {
    *downloadStudentData({ payload }, { call, put }) {
      message.loading({
        key: 'studentDownload',
        content: '正在下载学生清单...',
        duration: 0,
      });

      yield put({
        type: 'updateState',
        payload: {
          studentDownloadLoading: true,
        },
      });

      const params = {
        content: 'students',
        fileType: 'csv',
      };

      const res = yield call(downloadData, params);

      if (res?.status === 200) {
        const tempLink = document.createElement('a');
        tempLink.href = res.data;
        tempLink.click();
        tempLink.remove();

        message.success({
          key: 'studentDownload',
          content: '获取学生清单文件成功。',
          duration: 3,
        });
      }

      yield put({
        type: 'updateState',
        payload: {
          studentDownloadLoading: false,
        },
      });
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default DataDownloadModel;
