import { request } from '@@/plugin-request';

export async function getAssignmentById(id) {
  return request(`/api/assignments/${id}`, {
    method: 'GET',
  });
}

export async function updateSubmission(data) {
  return request(`/api/submissions`, {
    method: 'PUT',
    data,
  });
}
