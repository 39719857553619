import { request } from '@@/plugin-request';
export async function query(params) {
  return request('/api/organizations', {
    method: 'get',
    params,
  });
}

export async function getById(id) {
  return request(`/api/organizations/${id}`, {
    method: 'get',
  });
}

export async function create(params) {
  return request('/api/organizations', {
    method: 'post',
    data: params,
  });
}

export async function update(params) {
  return request('/api/organizations', {
    method: 'put',
    data: params,
  });
}

export async function get4Table(params) {
  return request('/api/organizations/table', {
    method: 'get',
    params,
  });
}

export async function getSalesChannels(params) {
  return request('/api/saleschannel', {
    method: 'get',
    params,
  });
}

export async function findAllChannelSource(params) {
  return request('/api/saleschannel/findAllChannelSource', {
    method: 'get',
    params,
  });
}

export async function createSalesChannel(payload) {
  return request('/api/saleschannel', {
    method: 'post',
    data: payload,
  });
}

export async function updateSalesChannel(payload) {
  return request('/api/saleschannel', {
    method: 'put',
    data: payload,
  });
}

export async function getPayers(params) {
  return request('/api/payers', {
    method: 'get',
    params,
  });
}

export async function createPayer(payload) {
  return request('/api/payers', {
    method: 'post',
    data: payload,
  });
}

export async function updatePayer(payload) {
  return request('/api/payers', {
    method: 'put',
    data: payload,
  });
}

export async function getPayerById(id) {
  return request(`/api/payers/${id}`, {
    method: 'get',
  });
}

export async function getOrphanOrganizations(params) {
  return request('/api/organizations/all', {
    method: 'GET',
    params: {
      ...params,
      withoutPayer: true,
    },
  });
}

export async function deletePayerAccountById(id) {
  return request(`/api/payers/${id}`, {
    method: 'delete',
  });
}
