import dayjs from 'dayjs';
import { query } from './service';

export const submitStatus = {
  已及时提交: '已及时提交',
  逾期未提交: '逾期未提交',
  逾期已提交: '逾期已提交',
  尚未提交: '尚未提交',
};

const FirstLessonReviewModel = {
  namespace: 'firstLessonReview',

  state: {
    beforeStatusSearch: null,
    afterStatusSearch: null,
    loading: false,
    tasks: [],
  },

  effects: {
    *query({ payload }, { call, put, select }) {
      yield put({ type: 'updateState', payload: { loading: true } });
      const beforeStatusSearch = yield select(
        (state) => state.firstLessonReview.beforeStatusSearch,
      );
      const afterStatusSearch = yield select((state) => state.firstLessonReview.afterStatusSearch);

      const response = yield call(query, payload);

      for (const record of response) {
        let beforeSubmitStatus;
        const lessonStartDateTime = dayjs(record.lesson.startAt); // Assuming record.lesson.startAt is a valid date string
        const beforeCompletedDate = record.qualityBeforeAt ? dayjs(record.qualityBeforeAt) : null;
        // Calculate the difference between the current date and the lesson start date
        if (beforeCompletedDate) {
          if (beforeCompletedDate.diff(lessonStartDateTime, 'day') > 0) {
            beforeSubmitStatus = submitStatus.逾期未提交;
          } else {
            beforeSubmitStatus = submitStatus.已及时提交;
          }
        } else {
          const currentDateTime = dayjs(); // Get the current date and time using dayjs
          const differenceInDays = currentDateTime.diff(lessonStartDateTime, 'day');
          if (differenceInDays > 0) {
            beforeSubmitStatus = submitStatus.逾期未提交;
          } else {
            beforeSubmitStatus = submitStatus.尚未提交;
          }
        }

        let afterSubmitStatus;
        const taskCompletedDate = dayjs(record.completedAt);
        // Calculate the difference between the current date and the lesson start date
        if (record.status === 'submitted') {
          if (taskCompletedDate.diff(lessonStartDateTime, 'day') > 7) {
            afterSubmitStatus = submitStatus.逾期已提交;
          } else {
            afterSubmitStatus = submitStatus.已及时提交;
          }
        } else {
          const currentDateTime = dayjs(); // Get the current date and time using dayjs
          const lessonStartDateTime = dayjs(record.lesson.startAt); // Assuming record.lesson.startAt is a valid date string
          // Calculate the difference between the current date and the lesson start date
          const differenceInDays = currentDateTime.diff(lessonStartDateTime, 'day');
          if (differenceInDays > 7) {
            afterSubmitStatus = submitStatus.逾期未提交;
          } else {
            afterSubmitStatus = submitStatus.尚未提交;
          }
        }
        record.beforeSubmitStatus = beforeSubmitStatus;
        record.afterSubmitStatus = afterSubmitStatus;
      }

      let formattedResponse = response.map((item) => ({
        ...item,
        matchmakerNotes: item.courseMatch.matchmakerNotes,
        headTeacherNotes: item.courseMatch.headTeacherNotes,
        lessonName: item.lesson.name,
      }));
      if (beforeStatusSearch) {
        formattedResponse = formattedResponse.filter(
          (item) => item.beforeSubmitStatus === beforeStatusSearch,
        );
      }
      if (afterStatusSearch) {
        formattedResponse = formattedResponse.filter(
          (item) => item.afterSubmitStatus === afterStatusSearch,
        );
      }
      yield put({ type: 'updateState', payload: { loading: false, tasks: formattedResponse } });
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};

export default FirstLessonReviewModel;
