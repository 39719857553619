import { request } from '@@/plugin-request';

export async function getAllSalaryIncreaseApprovalList(params) {
  return request('/api/salaryIncrease/getSalaryIncreaseApprovalList', {
    method: 'get',
    params,
  });
}

export async function updateSalaryIncreaseApprovalStatus(params) {
  return request('/api/salaryIncrease/updateSalaryIncreaseApprovalStatus', {
    method: 'put',
    data: params,
  });
}

export async function getSalaryIncreaseApprovalCourseList(params) {
  return request('/api/salaryIncrease/getSalaryIncreaseApprovalCourseList', {
    method: 'get',
    params,
  });
}

export async function updateTeacherSalaryLevel(params) {
  return request('/api/salaryIncrease/updateTeacherSalaryLevel', {
    method: 'put',
    data: params,
  });
}

export async function updateTeacherUpgradeDuration(params) {
  return request('/api/salaryIncrease/updateTeacherUpgradeDuration', {
    method: 'put',
    data: params,
  });
}

export async function isSalaryIncreaseApprovalByTeacherId(params) {
  return request('/api/salaryIncrease/isSalaryIncreaseApprovalByTeacherId', {
    method: 'post',
    data: params,
  });
}

export async function createSalaryIncreaseApproval(payload) {
  return request('/api/salaryIncrease/createSalaryIncreaseApproval', {
    method: 'post',
    data: payload,
  });
}
