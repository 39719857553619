import { Button, message, Modal } from 'antd';
import { htmlToText } from 'html-to-text';
import { useRef } from 'react';
import CourseMatchCopyPasteTemplate from './CourseMatchCopyPasteTemplate';

const CourseMatchInfoModal = ({
  record,
  courseMatchInfoModalVisible,
  setCourseMatchInfoModalVisible,
}) => {
  const textRef = useRef();
  if (!record) return null;
  return (
    <Modal
      open={courseMatchInfoModalVisible}
      width={550}
      onCancel={() => {
        setCourseMatchInfoModalVisible(null);
      }}
      footer={[
        <Button
          key="copy"
          onClick={() => {
            const text = htmlToText(textRef.current.outerHTML);
            navigator.clipboard.writeText(text);
            message.success('已复制');
          }}
          type="primary"
          ghost
        >
          点击复制以上信息
        </Button>,
        <Button
          key="cancel"
          onClick={() => {
            setCourseMatchInfoModalVisible(null);
          }}
          type="primary"
        >
          知道了
        </Button>,
      ]}
    >
      <div ref={textRef} style={{ marginLeft: 20 }}>
        <CourseMatchCopyPasteTemplate record={record} />
      </div>
    </Modal>
  );
};

export default CourseMatchInfoModal;
