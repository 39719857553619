import { request } from '@@/plugin-request';

export async function query(params) {
  return request('/api/finance/receivingAccounts', {
    method: 'get',
    params,
  });
}

export async function create(data) {
  return request('/api/finance/receivingAccounts', {
    method: 'post',
    data,
  });
}

export async function update(data) {
  return request('/api/finance/receivingAccounts', {
    method: 'put',
    data,
  });
}

export async function getAirwallexFormSchema(data) {
  return request('/api/airwallex/getAirwallexFormSchema', {
    method: 'post',
    data,
  });
}

//Validate beneficiary
export async function validateBeneficiary(data) {
  return request('/api/airwallex/validateBeneficiary', {
    method: 'post',
    data,
  });
}
