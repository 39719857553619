import { request } from '@@/plugin-request';

export async function queryCurrent(params) {
  return request(`/api/users/${params.id}`, {
    method: 'get',
    data: params,
  });
}

export async function countUsername(params) {
  return request('/api/users/countUsername', {
    method: 'get',
    params,
  });
}

export async function queryRoles(params) {
  return request('/api/users/queryRoles', {
    method: 'get',
    params,
  });
}
export async function getTeacherReview(params) {
  return request('/api/users/teacherReview', {
    method: 'get',
    params,
  });
}
export async function create(params) {
  return request('/api/users', {
    method: 'post',
    data: params,
  });
}

export async function update(data) {
  return request('/api/users', {
    method: 'put',
    data,
  });
}

export async function getByContactInfo(params) {
  return request('/api/users/contactInfo', {
    method: 'GET',
    params,
  });
}

export async function createSalaryAdjustment(payload) {
  return request('/api/salary/adjustment', {
    method: 'post',
    data: payload,
  });
}

export async function updateSalaryAdjustment(payload) {
  return request('/api/salary/adjustment', {
    method: 'put',
    data: payload,
  });
}

export async function queryPermissions(params) {
  return request('/api/permissions', {
    method: 'get',
    params,
  });
}

export async function createTeacher(data) {
  return request(`/api/onboarding/newTeacher`, {
    method: 'post',
    data,
  });
}

export async function createTeacherGrade(data) {
  return request(`/api/teacherGrade`, {
    method: 'post',
    data,
  });
}

export async function queryTeacherGrade(params) {
  return request(`/api/teacherGrade/teacher/${params}`, {
    method: 'get',
    data: params,
  });
}
