import { useState } from 'react';
import { Button } from 'antd';

const MaxCharacterTextBlock = ({ originalContent, maxCharacters = 20, removeWhiteSpaces = false }) => {
  if (!originalContent) return '';
  if (typeof originalContent !== 'string') return 'Only supports string';

  if (removeWhiteSpaces) {
    originalContent = originalContent.replace(/[\s\n]/g, "")
  }

  const textLength = Array.from(originalContent).length;

  const [displayLength, setDisplayLength] = useState(maxCharacters);

  function sliceString(str, end) {
    const unicodeChars = Array.from(str);
    return unicodeChars.slice(0, end).join('');
  }

  let btn = null;
  if (textLength > displayLength) {
    btn = (
      <Button
        type="link"
        size="small"
        onClick={() => {
          setDisplayLength(textLength);
        }}
      >
        ...
      </Button>
    );
  }

  if (displayLength > maxCharacters) {
    btn = (
      <Button
        type="link"
        size="small"
        onClick={() => {
          setDisplayLength(maxCharacters);
        }}
      >
        收起
      </Button>
    );
  }

  return (
    <div
      style={{
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
      }}
    >
      {sliceString(originalContent, displayLength)}
      {btn}
    </div>
  );
};

export default MaxCharacterTextBlock;
