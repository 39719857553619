import { request } from '@@/plugin-request';

export async function queryProducts(params) {
  return request('/api/products/salesquery', {
    params,
  });
}

export async function getRule(params) {
  return request('/api/products/rule', {
    params,
  });
}

export async function queryStudents(params) {
  return request('/api/student/salesquery', {
    params,
  });
}

export async function createCustomer(payload) {
  return request('/api/customers', {
    method: 'POST',
    data: payload,
  });
}

export async function updateCustomer(payload) {
  return request('/api/customers', {
    method: 'PUT',
    data: payload,
  });
}

export async function createNote(payload) {
  return request(`/api/customers/note`, {
    method: 'POST',
    data: payload,
  });
}

export async function updateIntimacy(id, payload) {
  return request(`/api/consultants/salesRelations/${id}`, {
    method: 'PUT',
    data: payload,
  });
}

export async function getConsultantRelations(params) {
  return request('/api/consultants/salesRelations', {
    params,
  });
}

export async function getCustomerByPhone(payload) {
  return request('/api/customers/getByPhone', {
    method: 'GET',
    params: payload,
  });
}

export async function createAdvisory(payload) {
  return request('/api/advisoryRecords', {
    method: 'POST',
    data: payload,
  });
}

export async function updateAdvisory(payload) {
  return request('/api/advisoryRecords', {
    method: 'PUT',
    data: payload,
  });
}

export async function getSchedule(payload) {
  return request('/api/advisoryRecords/getSchedule', {
    method: 'POST',
    data: payload,
  });
}

export async function getAdvisories(params) {
  return request('/api/advisoryRecords', {
    method: 'GET',
    params,
  });
}

export async function updateRule(payload) {
  return request('/api/products/rule', {
    method: 'PUT',
    data: payload,
  });
}
