import { request } from '@@/plugin-request';

export async function createStudentService(params) {
  return request('/api/student/create', {
    method: 'POST',
    data: { ...params },
  });
}

export async function checkDuplicates(params) {
  return request('/api/student/count', {
    method: 'GET',
    params,
  });
}

export async function query(payload) {
  return request('/api/student/query', {
    method: 'GET',
    params: payload,
  });
}

export async function getHeadTeacherStudent(payload) {
  return request('/api/student/getHeadTeacherStudent', {
    method: 'GET',
    params: payload,
  });
}

export async function afterQuery(params) {
  return request('/api/students/afterQuery', {
    method: 'GET',
    params,
  });
}

export async function disable(payload) {
  return request('/api/student/disable', {
    method: 'POST',
    data: payload,
  });
}

//TODO:用于测试，后续可以删除
//根据学生id获取投诉
export async function getComplaintsByStudentId(payload) {
  return request('/api/teachingComplaints/getComplaintsByStudentId', {
    method: 'GET',
    params: payload,
  });
}

export async function getStudentInfoRecords(payload) {
  return request('/api/student/getStudentInfoRecords', {
    method: 'GET',
    params: payload,
  });
}

export async function getCoursematch4SSC(payload) {
  return request('/api/coursematch/getCoursematches4StudentSemesterCourse', {
    method: 'GET',
    params: payload,
  });
}

export async function connectCoursematchesWithSSC(payload) {
  return request('/api/coursematch/connectCoursematchesWithSSC', {
    method: 'POST',
    data: payload,
  });
}

//新增学生记录
export async function createStudentInfoRecord(payload) {
  return request('/api/student/studentInfoRecord', {
    method: 'POST',
    data: payload,
  });
}

export async function dropCourse(payload) {
  return request('/api/student/dropCourse', {
    method: 'PUT',
    data: payload,
  });
}
