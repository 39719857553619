import { query } from '@/pages/Course/service';
import DebounceSelect from '../DebounceSelect';

async function fetchCourseList({ searchValue, schools, category, setTeacher }) {
  const results = await query({
    searchValue: searchValue,
    limit: 10,
    schools,
    category,
  });
  if (results.length === 0 && setTeacher) setTeacher(null);
  return results.map((c) => {
    // const labelString = `【${c.category || '未知上课方式'} | ${c.type || '未知类型'} | ${
    //   c.academicLevel || '未知学术等级'
    // }】${c.name}`;
    const labelString = `【${c.courseNo || '未知课号'}】${c.name} [学校：${
      c.school?.name || '无'
    }] [学术等级：${c.academicLevel || '未知'}] [课程类型：${c.type || '未知'}]`;
    return {
      label: labelString,
      value: c.id,
      id: c.id,
      type: 'course',
    };
  });
}

export default ({ schools, category, setTeacher, ...rest }) => {
  return (
    <DebounceSelect
      placeholder="请搜索课程名"
      style={{
        width: '100%',
      }}
      {...rest}
      fetchOptions={(searchValue) =>
        fetchCourseList({ searchValue, schools, category, setTeacher })
      }
    />
  );
};
