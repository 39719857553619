import { request } from '@@/plugin-request';

export async function signin(data) {
  return request('/api/auth/signin', {
    method: 'POST',
    data,
  });
}

export async function smsTwoFactor(data) {
  return request('/api/auth/twoFactor', {
    method: 'POST',
    data,
  });
}

export async function twoFactorVerify(data) {
  return request('/api/auth/twoFactorVerify', {
    method: 'POST',
    data,
  });
}

//检查是否关联
export async function checkConnect(data) {
  return request('/api/wechat/connected', {
    method: 'POST',
    data,
  });
}

//更改忘记密码
export async function changeForgetPassword(data) {
  return request('/api/auth/changeForgetPassword', {
    method: 'PUT',
    data,
  });
}
