import dayjs from 'dayjs';
import { sortEducationRecords } from '@/components/Educations/utils'

const CourseMatchCopyPasteTemplate = ({ record }) => {
  if (!record) return null;
  let lastEduRecord = null;
  if (record.student?.educations?.length) {
    const educations = sortEducationRecords(record.student.educations)
    lastEduRecord = educations[0];
  }
  return (
    <div>
      <p>
        <b>###匹配需求信息###</b>
      </p>
      <p>
        <b>负责教务：</b>
        {!!record.headTeacher?.chineseName
          ? `${record.headTeacher?.chineseName}`
          : `${record.headTeacher?.lastName}, ${record.headTeacher?.firstName}`}
      </p>
      <p>
        <b>学员参加项目：</b>
        {record.contract?.[0]?.products?.[0]?.name}
      </p>
      <p>
        <b>最晚匹配时间（北京时间）：</b>
        {!!record.latestMatchTime
          ? `${dayjs(record.latestMatchTime).tz('Asia/Shanghai').format('YYYY/MM/DD, HH:mm')}`
          : '未填写'}
      </p>
      <p>
        <b>学员风险等级：</b>
        {record.student?.riskLevel || '无'}，<b>原因：</b>
        {record.student?.riskLevelNotes || '无'}
      </p>
      <p>
        <b>学员姓名：</b>
        {record.student?.fullName}
      </p>
      <p>
        <b>学生学校：</b>
        {lastEduRecord ? lastEduRecord.school?.name : '系统中未记录该学生学校'}
      </p>
      <p>
        <b>学生水平：</b>
        {record.student?.academicPerformance || '无'}，<b>文字说明：</b>
        {record.student?.academicPerformanceNotes || '无'}
      </p>
      {!!record.course || !!record.courseCustomizedName ? (
        <p>
          <b>需求课程：</b>
          {!!record.course ? (
            <>
              <span>{`【${record.course?.courseNo || '未知课号'}】${record.course?.name} [学校：${
                record.course?.school?.name || '无'
              }] [学术等级：${record.course?.academicLevel || '未知'}] [课程类型：${
                record.course?.type || '未知'
              }]`}</span>
            </>
          ) : (
            <span>{`${
              record.courseCustomizedName ||
              '【无课程信息】（教务指定课程后，因自定义课程信息是选填，之后更改状态时删除了这门匹配好的课，就会变无信息状态）'
            }`}</span>
          )}
        </p>
      ) : null}
      {!!record.serviceItem || !!record.serviceItemCustomizedName ? (
        <p>
          <b>需求服务项：</b>
          {!!record.serviceItem ? (
            <>
              <span>{`【${record.serviceItem?.academicLevel || '无学术等级'}】${
                record.serviceItem?.name
              }`}</span>
            </>
          ) : (
            <span>{`${
              record.serviceItemCustomizedName ||
              '【无服务项信息】（教务指定服务项后，因自定义服务项信息是选填，之后更改状态时删除了这门匹配好的服务项，就会变无信息状态）'
            }`}</span>
          )}
        </p>
      ) : null}
      <p>
        <b>需求类型：</b>
        {record.courseMatchType.map((r) => (
          <>
            <span>【{r.type}】</span>
            {r.type === '考试辅导' ? (
              <>
                {' ['}
                <b>考试时间（北京时间）：</b>
                {!!r.examDate?.timestamp
                  ? dayjs(r.examDate?.timestamp).tz('Asia/Shanghai').format('YYYY/MM/DD, HH:mm')
                  : '未填写'}
                {'] '}
              </>
            ) : null}
            {r.type === '论文辅导' ? (
              <>
                {' ['}
                <b>论文Deadline（北京时间）：</b>
                {!!r.essayDeadline?.timestamp
                  ? dayjs(r.essayDeadline?.timestamp)
                      .tz('Asia/Shanghai')
                      .format('YYYY/MM/DD, HH:mm')
                  : '未填写'}{' '}
                <b>论文字数：</b>
                {r.essayWordCount || '未填写'}
                {'] '}
              </>
            ) : null}
            {r.type === '作业辅导' ? (
              <>
                {' ['}
                <b>作业Deadline（北京时间）：</b>
                {!!r.assignmentDeadline?.timestamp
                  ? dayjs(r.assignmentDeadline?.timestamp)
                      .tz('Asia/Shanghai')
                      .format('YYYY/MM/DD, HH:mm')
                  : '未填写'}
                {'] '}
              </>
            ) : null}
          </>
        ))}
      </p>
      {!!record.courseMatchReason && !!record.courseMatchReason.length ? (
        <p>
          {record.courseMatchReason.map((r) => (
            <>
              {r.category === '新老师匹配' ? (
                <>
                  <b>匹配原因：</b>【{r.category}】
                </>
              ) : (
                <>
                  <b>匹配原因：</b>【{r.category}】 - 【{r.reason}】
                </>
              )}
            </>
          ))}
        </p>
      ) : null}
      {!!record.courseMatchReason && !!record.courseMatchReason.length
        ? record.courseMatchReason.map((r) => (
            <>
              {r.category === '替换原老师' || r.category === '临时代课' ? (
                <p>
                  <b>原老师姓名：</b>
                  {!!r.previousTeacher?.chineseName
                    ? `${r.previousTeacher?.chineseName}`
                    : `${r.previousTeacher?.lastName}${r.previousTeacher?.firstName}`}
                </p>
              ) : null}
            </>
          ))
        : null}
      {!!record.courseMatchReason && !!record.courseMatchReason.length ? (
        <p>
          {record.courseMatchReason.map((r) => (
            <>
              <b>原因文字描述：</b>
              {r.reasonNotes || '无'}
            </>
          ))}
        </p>
      ) : null}
      <p>
        <b>上课频次：</b>
        {record.lessonFrequency || '无'}
      </p>
      <p>
        <b>需第一次上课时间：</b>
        {record.idealFirstLessonDate || '无'}
      </p>
      <p>
        <b>教务备注：</b>
        {record.headTeacherNotes || '未填写'}
      </p>
      <p>
        <b>是否需要过课：</b>
        {record.needQualityCheck ? '是' : '否'}
      </p>
      {record.qualityInspector ? (
        <p>
          <b>过课人：</b>
          {record.qualityInspector
            ? !!record.qualityInspector?.chineseName
              ? `${record.qualityInspector?.chineseName}`
              : `${record.qualityInspector?.lastName}${record.qualityInspector?.firstName}`
            : '未填写'}
        </p>
      ) : null}
      {/* <p>
        <b>###匹配需求状态###</b>
      </p>
      <p>
        <b>是否匹配：</b>【{record.status}】
      </p>
      <p>
        <b>关联课程：</b>
        {record.course ? (
          <span>{`${record.course?.courseNo || '无课号'} - ${
            record.course?.name || '无课程名称'
          }`}</span>
        ) : (
          '未关联课程'
        )}
      </p>
      <p>
        <b>关联老师：</b>
        {record.teacher
          ? !!record.teacher?.chineseName
            ? `${record.teacher?.chineseName}`
            : `${record.teacher?.lastName}${record.teacher?.firstName}`
          : '未关联老师'}
        {record.teacher
          ? ` 用户名: ${record.teacher?.username}
              电话: +${record.teacher?.phonePrefix}-${record.teacher?.phone}
            微信: ${record.teacher?.wechatId}`
          : null}
      </p>
      <p>
        <b>工作备注栏：</b>
        {record.matchmakerNotes || '无'}
      </p>
      <p>
        <b>是否拉对接群：</b>
        {record.needGroupChat ? '是' : '否'}
      </p> */}
    </div>
  );
};

export default CourseMatchCopyPasteTemplate;
