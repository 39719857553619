import { request } from '@@/plugin-request';

export async function createNotice(payload) {
  return request('/api/notice', {
    method: 'POST',
    data: payload,
  });
}
export async function updateReadStatus(payload) {
  return request('/api/notice/readStatus', {
    method: 'PUT',
    data: payload,
  });
}
export async function get4User() {
  return request('/api/notice/user', {
    method: 'GET',
  });
}
