import { request } from '@@/plugin-request';

export async function getContractById(id) {
  return request(`/api/contracts/lessonModal/${id}`, {
    method: 'GET',
  });
}

export async function getLessonCount(params) {
  return request(`/api/lessons/count`, {
    method: 'GET',
    params,
  });
}

export async function getById(id) {
  return request(`/api/lessons/${id}`, {
    method: 'GET',
  });
}

export async function getLessonByTeacher(params) {
  return request(`/api/lessons/teacher`, {
    method: 'GET',
    params,
  });
}

export async function checkConflictLesson(payload) {
  return request(`/api/lessons/checkConflictLesson`, {
    method: 'POST',
    data: payload,
  });
}
