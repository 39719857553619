import { pathToRegexp } from 'path-to-regexp';
import { getAssignmentById, updateSubmission } from './service';

const Model = {
  namespace: 'assignmentMarking',
  state: {
    questionTabKey: 0,
    lesson: {},
    assignment: {},
    questions: [],
    submissions: [],
    selectedSubmissionToMark: {},
    initialValues: {},
    markingModalVisible: false,
  },

  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ location }) => {
        const match = pathToRegexp('/assignment/marking/:id').exec(location.pathname);
        if (match) {
          const id = match[1];
          if (id) {
            dispatch({
              type: 'init',
              payload: {
                id,
              },
            });
          }
        }
      });
    },
  },

  effects: {
    *init({ payload }, { call, put }) {
      if (payload?.id) {
        const assignment = yield call(getAssignmentById, payload.id);
        yield put({
          type: 'updateState',
          payload: {
            assignment,
            questions: assignment.questions,
            lesson: assignment.lesson,
            submissions: assignment.submissions,
          },
        });
      }
    },

    *markingModalOpen({ payload = {} }, { select, put }) {
      const { submissionId } = payload;
      const submissions = yield select((_) => _.assignmentMarking.submissions);
      const questions = yield select((_) => _.assignmentMarking.questions);
      const questionTabKey = yield select((_) => _.assignmentMarking.questionTabKey);
      const selectedSubmissionToMark = submissions.find((s) => s.id === submissionId);
      const emptyArray = questions.map((_) => null);
      selectedSubmissionToMark.markObtained = selectedSubmissionToMark.markObtained || emptyArray;
      selectedSubmissionToMark.comments = selectedSubmissionToMark.comments || emptyArray;
      selectedSubmissionToMark.uploads = selectedSubmissionToMark.uploads || emptyArray;
      const markObtained = selectedSubmissionToMark.markObtained[questionTabKey];
      const comment = selectedSubmissionToMark.comments[questionTabKey];
      const upload = selectedSubmissionToMark.uploads[questionTabKey];
      yield put({
        type: 'updateState',
        payload: {
          selectedSubmissionToMark,
          initialValues: {
            markObtained,
            comment,
            upload,
          },
          markingModalVisible: true,
        },
      });
    },

    *onSubmitMarking({ payload = {} }, { call, put, select }) {
      const { markObtained, comment, upload } = payload;
      const submissions = yield select((_) => _.assignmentMarking.submissions);
      const selectedSubmissionToMark = yield select(
        (_) => _.assignmentMarking.selectedSubmissionToMark,
      );
      const submissionIndex = submissions.findIndex((s) => s.id === selectedSubmissionToMark.id);
      const questionTabKey = yield select((_) => _.assignmentMarking.questionTabKey);
      selectedSubmissionToMark.markObtained[questionTabKey] = markObtained;
      selectedSubmissionToMark.comments[questionTabKey] = comment;
      selectedSubmissionToMark.uploads[questionTabKey] = upload;
      yield call(updateSubmission, selectedSubmissionToMark);
      submissions[submissionIndex] = selectedSubmissionToMark;
      yield put({
        type: 'updateState',
        payload: {
          submissions,
        },
      });
      yield put({
        type: 'closeMarkingModal',
      });
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    closeMarkingModal(state, { payload }) {
      return {
        ...state,
        selectedSubmissionToMark: {},
        initialValues: {},
        markingModalVisible: false,
      };
    },

    switchQuestionTab(state, { payload }) {
      return {
        ...state,
        questionTabKey: payload?.questionTabKey || 0,
      };
    },
  },
};

export default Model;
