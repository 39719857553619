import { create, createLink, query } from './service';

const MaterialModel = {
  namespace: 'material',
  state: {
    // creating
    creating: false,
    folderId: null,
    createdLinks: [],
    createdMaterials: [],
    file2Upload: null,
    fileUploadProgress: 0,

    // data
    materialsData: [],

    // feature
    uploadedFiles: [],
    waitingFiles: [],
  },
  effects: {
    *createMaterial({ payload }, { call, put }) {
      const { createdMaterials } = payload;
      const response = yield call(create, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            createdMaterials: [...createdMaterials, response],
            file2Upload: null,
            fileUploadProgress: 0,
            fileUploading: false,
          },
        });
      }
    },

    *createLink({ payload }, { call, put }) {
      const { createdLinks } = payload;
      const response = yield call(createLink, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            createdLinks: [...createdLinks, response],
            file2Upload: null,
            fileUploadProgress: 0,
            fileUploading: false,
          },
        });
      }
    },

    *query({ payload }, { call, put }) {
      const response = yield call(query, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            materialsData: response,
          },
        });
      }
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    init(state, { payload }) {
      return {
        ...state,
        creating: true,
        folderId: null,
        createdLinks: [],
        createdMaterials: [],
        file2Upload: null,
        fileUploadProgress: 0,
        fileUploading: false,

        waitingFiles: [],
        successFiles: [],
        uploadedFiles: 0,
      };
    },
    end(state, { payload }) {
      return {
        ...state,
        creating: false,
        folderId: null,
        createdLinks: [],
        createdMaterials: [],
        file2Upload: null,
        fileUploadProgress: 0,
      };
    },
  },
};

export default MaterialModel;
