const settings = {
  title: '国际业务ERP',
  navTheme: 'light',
  colorPrimary: '#1677FF',
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  pwa: true,
  logo: 'https://savvyuni-public.oss-accelerate.aliyuncs.com/logos/savvygroup/SavvyGroup%20Logo.svg',
  // iconfontUrl: '//at.alicdn.com/t/c/font_2869091_49ooe349fma.js',
  menu: {
    locale: false,
  },
  token: {
    // 参见ts声明，demo 见文档，通过token 修改样式
    //https://procomponents.ant.design/components/layout#%E9%80%9A%E8%BF%87-token-%E4%BF%AE%E6%94%B9%E6%A0%B7%E5%BC%8F
  },
};
export default settings;
