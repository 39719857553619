import { pathToRegexp } from 'path-to-regexp';
import { query as queryAttendances } from '../../../services/attendance';
import { getByCourse } from '../../Contract/service';
import { getById } from '../service';
import { querySubmissions } from './service';

const Model = {
  namespace: 'courseAndProfile',
  state: {
    courseId: null,
    currentItem: {},
    list: [],
    contracts: [],
    attendances: [],
    lessons: [],
    activeLessonTab: '0',
    submissions: [],
  },

  effects: {
    *fetchCurrent({ payload }, { call, put, select }) {
      let id = payload?.id;
      if (!id) {
        id = yield select((_) => _.courseAndProfile.courseId);
      }
      const course = yield call(getById, id);
      const lessons = course.lessons.sort((a, b) => new Date(b.startAt) - new Date(a.startAt));
      yield put({
        type: 'save',
        payload: {
          currentItem: course,
          lessons,
          activeLessonTab: lessons?.[0]?.id,
        },
      });
    },

    *fetchContracts({ payload }, { call, put, select }) {
      let id = payload?.id;
      if (!id) {
        id = yield select((_) => _.courseAndProfile.courseId);
      }
      const response = yield call(getByCourse, {
        id,
      });
      if (response) {
        yield put({
          type: 'save',
          payload: {
            contracts: response,
          },
        });
      }
    },

    *fetchAttendances({ payload }, { call, put, select }) {
      let id = payload?.id;
      if (!id) {
        id = yield select((_) => _.courseAndProfile.courseId);
      }
      const attendances = yield call(queryAttendances, {
        course: Number(id),
      });
      const studentAttendances = attendances?.filter?.((a) => {
        if (!a.student?.id) return false;
        if (!a.activities?.length) return false;
        if (a.activities.length === 1 && a.activities[0].category === '自动补签') return false;
        return true;
      });
      yield put({
        type: 'save',
        payload: {
          attendances: studentAttendances,
        },
      });
    },

    *fetchSubmissions({ payload }, { call, put, select }) {
      const lesson = yield select((_) => _.courseAndProfile.activeLessonTab);
      const response = yield call(querySubmissions, {
        lesson,
      });
      yield put({
        type: 'save',
        payload: {
          submissions: response,
        },
      });
    },
  },

  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },

    queryList(state, action) {
      return { ...state, list: action.payload };
    },
  },
};
export default Model;
