import { message } from 'antd';
import {
  createAdvisory,
  createCustomer,
  getAdvisories,
  getSchedule,
  updateCustomer,
} from './service';

const Model = {
  namespace: 'sales',
  state: {
    currentCustomer: {},
    customerModalVisible: false,
    selectedSchool: null,
    loading: false,
    reloadCustomerTable: false,
    mode: 'create',
    advisoryModalVisible: false,
    advisoryDrawerVisible: false,
    showAdvisoryTableModal: false,
    showEditVisitModal: false,
    studentModalVisible: false,
    advisoryRecords: [],
  },

  effects: {
    *createCustomer({ payload }, { call, put }) {
      message.loading('正在操作');
      const response = yield call(createCustomer, payload);

      if (response && response.firstName && response.lastName) {
        message.success('操作成功');
      } else {
        message.error('操作失败');
      }

      yield put({
        type: 'save',
        payload: {
          loading: false,
          customerModalVisible: false,
          reloadCustomerTable: true,
        },
      });
      yield put({
        type: 'clear',
      });
    },

    *updateCustomer({ payload }, { call, put }) {
      message.loading('正在操作');
      const response = yield call(updateCustomer, payload);

      if (response && response.firstName && response.lastName) {
        message.success('操作成功');
      } else {
        message.error('操作失败');
      }

      yield put({
        type: 'save',
        payload: {
          loading: false,
          customerModalVisible: false,
          reloadCustomerTable: true,
        },
      });
      yield put({
        type: 'clear',
      });
    },

    *createAdvisory({ payload }, { call, put }) {
      const hide = message.loading('正在操作');
      const response = yield call(createAdvisory, payload);
      if (response.id) {
        hide();
        message.success('操作成功');
      } else message.error('操作失败');
      yield put({
        type: 'clear',
      });
    },

    *createVisit({ payload }, { call, put }) {
      const hide = message.loading('正在操作');
      const response = yield call(createVisit, payload);
      if (response.id) {
        hide();
        message.success('操作成功');
      } else message.error('操作失败');
      yield put({
        type: 'save',
        payload: {
          reloadVisitTable: true,
        },
      });

      yield put({
        type: 'clear',
      });
    },

    *getSchedule({ payload }, { call, put }) {
      const response = yield call(getSchedule, payload);
      yield put({
        type: 'save',
        payload: {
          schedule: response.records,
          schedulesToday: response.schedulesToday,
        },
      });
    },

    *onDrawerOpen({ payload = {} }, { call, put }) {
      const hide = message.loading('正在加载回访记录...');
      const response = yield call(getAdvisories, { student: payload.currentCustomer.id });
      if (response) {
        hide();
        yield put({
          type: 'save',
          payload: {
            currentCustomer: payload.currentCustomer,
            advisoryDrawerVisible: true,
            advisoryRecords: response,
          },
        });
      } else {
        message.error('加载失败');
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },

    onDrawerClose(state, { payload }) {
      return {
        ...state,
        advisoryModalVisible: false,
        advisoryDrawerVisible: false,
        advisoryRecords: [],
      };
    },

    clear() {
      return {
        selectedSchool: null,
        customerModalVisible: false,
        loading: false,
        currentCustomer: {},
        reloadCustomerTable: false,
        mode: 'create',
        advisoryModalVisible: false,
        advisoryDrawerVisible: false,
        showAdvisoryTableModal: false,
        showEditVisitModal: false,
        reloadVisitTable: false,
        advisoryRecords: [],
      };
    },
  },
};

export default Model;
