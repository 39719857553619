import { request } from '@@/plugin-request';
import { stringify } from 'querystring';
import { history } from 'umi';

export async function queryCurrentUser(params) {
  return request('/api/auth/currentUser', {
    method: 'get',
    params,
  });
}

export async function queryNotices() {
  return request('/api/notices');
}

export async function query(params) {
  return request('/api/users', {
    method: 'get',
    params,
  });
}

export async function create(params) {
  return request('/api/users', {
    method: 'post',
    data: params,
  });
}

export async function update(params) {
  return request('/api/users', {
    method: 'put',
    data: params,
  });
}

export function logout() {
  window.localStorage.removeItem('token');
  const urlParams = new URL(window.location.href);
  const loginPath = '/login';
  let queryString = '';
  if (urlParams.pathname !== loginPath) {
    queryString = stringify({
      redirect: window.location.href,
    });
  }
  history.replace({
    pathname: loginPath,
    search: queryString,
  });
  return;
}

export async function queryHeadTeachers(params) {
  return request('/api/users/headTeacher', {
    method: 'GET',
    params,
  });
}

export async function queryUser(params) {
  return request(`/api/users/${params.id}`, {
    method: 'get',
    params,
  });
}
