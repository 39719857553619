import { update as linkToContract } from '@/pages/Contract/service';
import { message } from 'antd';
import { query as querySchool } from '../School/service';
import {
  create,
  getStudyStageReport,
  query,
  queryContract,
  queryTeachers,
  update,
} from './service';
// import { query as querySemester } from '../Semester/service'
export const CourseType = {
  学术辅导: '学术辅导',
  竞赛: '竞赛',
  面试: '面试',
  证书: '证书',
  求职: '求职',
  论文润色: '论文润色',
  文书润色: '文书润色',
};

const CourseModel = {
  namespace: 'course',
  state: {
    // creating
    creating: false,
    queryTeacherLoading: false,
    teacherData: [],
    createdCourse: null,
    schoolData: [],
    querySchoolLoading: false,

    linkingContracts: false,
    contractData: [],

    // edit course
    selectedCourse: null,
    courseToUpdate: null,
    updatedCourse: null,

    // general
    courseData: [],
    categoryFilter: '班课',

    // study stage report
    createReportModalVisible: false,
    targetStudent: null,
    date: null,
    assignmentTime: 'assignment',
    assignment: true,
    attendance: true,
    teacherExpectations: '',
    courseId: null,
    current: 0,
    report: null,
  },

  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ location }) => {
        if (location.pathname === '/course') {
        }
      });
    },
  },

  effects: {
    *create({ payload }, { call, put }) {
      const response = yield call(create, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            createdCourse: response,
          },
        });
      }
      yield put({
        type: 'query',
      });
    },

    *update({ payload }, { call, put }) {
      const response = yield call(update, payload);

      if (response.id) {
        yield put({
          type: 'updateState',
          payload: {
            updatedCourse: response,
          },
        });
      } else {
        yield put({
          type: 'updateState',
          payload: {
            updatedCourse: { error: true },
          },
        });
      }
      yield put({
        type: 'query',
      });
    },

    *query({ payload }, { call, put }) {
      const response = yield call(query, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            courseData: response,
          },
        });
      }
    },

    // *linkToContract

    *queryContract({ payload }, { call, put }) {
      const response = yield call(queryContract, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            contractData: response.contracts,
          },
        });
      }
    },

    *querySchool({ payload }, { call, put }) {
      const { searchValue } = payload;

      const params = {
        searchValue,
        mode: 'basic',
      };

      const response = yield call(querySchool, params);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            schoolData: response.schools,
            querySchoolLoading: false,
          },
        });
      }
    },

    *queryTeacher({ payload }, { call, put }) {
      yield put({
        type: 'updateState',
        payload: {
          fetchingTeacher: true,
        },
      });

      const { searchValue } = payload;

      const params = {
        searchValue,
      };

      const response = yield call(queryTeachers, params);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            teacherData: response,
            queryTeacherLoading: false,
          },
        });
      }
    },

    *linkToContract({ payload }, { call, put }) {
      const response = yield call(linkToContract, payload);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            createdSchool: response,
            createSubmitButtonLoading: false,
          },
        });

        yield put({
          type: 'querySchool',
        });
      }
    },

    *getStudyStageReport({ payload }, { call, put, select }) {
      try {
        const hide = message.loading('正在生成报告...');
        const report = yield call(getStudyStageReport, payload);
        yield put({
          type: 'updateState',
          payload: {
            report,
          },
        });
        hide();
        message.success('生成成功');
        const currentStep = yield select((state) => state.course.current);

        yield put({
          type: 'updateState',
          payload: { current: currentStep + 1 },
        });
      } catch (e) {
        console.log(e);
        message.error('生成失败, 查询的时间段并没有任何课节', 5);
      }
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },

    init(state, { payload }) {
      return {
        ...state,
        creating: true,
        queryTeacherLoading: false,
        teacherData: [],
        createdCourse: null,
        schoolData: [],
        querySchoolLoading: false,
      };
    },

    end(state, { payload }) {
      return {
        ...state,
        creating: false,
        queryTeacherLoading: false,
        teacherData: [],
        createdCourse: null,
        schoolData: [],
        querySchoolLoading: false,
      };
    },

    endEditCourse(state, { payload }) {
      return {
        ...state,
        selectedCourse: null,
        courseToUpdate: null,
        updatedCourse: null,
        schoolData: [],
        teacherData: [],
        queryTeacherLoading: false,
        querySchoolLoading: false,
      };
    },

    clearStudyReportOptions(state, { payload }) {
      return {
        ...state,
        date: null,
        assignmentTime: 'assignment',
        assignment: true,
        attendance: true,
        teacherExpectations: '',
        current: 0,
      };
    },
  },
};

export default CourseModel;
