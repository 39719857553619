import { request } from '@@/plugin-request';

export async function getTeamMembers(params) {
  return request('/api/team/operation/members', {
    method: 'GET',
    params,
  });
}

export async function getDataByOperation(params) {
  return request('/api/team/byOperation', {
    method: 'GET',
    params,
  });
}

export async function getTeamMembersLessons(data) {
  return request('/api/team/operation/members/lessons', {
    method: 'POST',
    data,
  });
}
