import { request } from '@@/plugin-request';

export async function queryProductStats(params) {
  return request('/api/products/contractStats', {
    method: 'GET',
    params,
  });
}

export async function getSalaryHistory(params) {
  return request('/api/finance/salaryHistory', {
    method: 'GET',
    params,
  });
}

export async function getUserPreference() {
  return request('/api/users/userPreference', {
    method: 'GET',
  });
}

export async function setUserPreference(data) {
  return request('/api/users/userPreference', {
    method: 'POST',
    data,
  });
}

export async function updateSalaryHistory(payload) {
  return request('/api/finance/salaryHistory', {
    method: 'PUT',
    data: payload,
  });
}

export async function queryAllLessons(params) {
  return request('/api/lessons/all', {
    method: 'GET',
    params,
  });
}

export async function queryContractDetails(params) {
  return request('/api/contracts/product', {
    method: 'GET',
    params,
  });
}

export async function querySalesAgenda(params) {
  return request('/api/salesAgendas', {
    method: 'GET',
    params,
  });
}

export async function createZoomMeeting(data) {
  return request('/api/lessons/meetings', {
    method: 'POST',
    data,
  });
}

export async function queryReferralBonusApproval(params) {
  return request('/api/hrm/queryReferralBonusApproval', {
    method: 'GET',
    params,
  });
}

export async function updateReferralBonusApproval(params) {
  return request('/api/hrm/updateReferralBonusApproval', {
    method: 'PUT',
    data: params,
  });
}

export async function queryUnapprovedReferralBonusApprovalCount(params) {
  return request('/api/hrm/queryUnapprovedReferralBonusApprovalCount', {
    method: 'GET',
    params,
  });
}

export async function getTeachers(params) {
  return request('/api/users/teachers', {
    method: 'GET',
    params,
  });
}

export async function createReferralBonusApproval(payload) {
  return request(`/api/hrm/createReferralBonusApproval`, {
    method: 'POST',
    data: payload,
  });
}

export async function transferLessonToOtherContract(payload) {
  return request(`/api/lessons/transferLessonToOtherContract`, {
    method: 'POST',
    data: payload,
  });
}
