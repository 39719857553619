import { request } from '@@/plugin-request';

export async function getAllComplaints(params) {
  return request('/api/teachingComplaints', {
    method: 'get',
    params,
  });
}

export async function getHandlers() {
  return request('/api/teachingComplaints/handler', {
    method: 'get',
  });
}

export async function postComplaintsHanding(payload) {
  return request('/api/teachingComplaints/complaintsHanding', {
    method: 'post',
    data: payload,
  });
}
export async function postComplaintReview(payload) {
  return request('/api/teachingComplaints/complaintReview', {
    method: 'post',
    data: payload,
  });
}

export async function getComplaintsHanding(params) {
  return request(`/api/teachingComplaints/getComplaintsHandingById/${params}`, {
    method: 'get',
    data: params,
  });
}
export async function getAllFilters() {
  return request('/api/teachingComplaints/filters', {
    method: 'get',
  });
}
export async function deleteComplaints(payload) {
  return request('/api/teachingComplaints/delete', {
    method: 'put',
    data: payload,
  });
}
export async function getAllGiftCourseAndCreditFromApprovalBylessonId(params) {
  return request('/api/contracts/getAllGiftCourseAndCreditFromApprovalBylessonId', {
    method: 'get',
    params,
  });
}

export async function connectGiftCourseAndCreditFromApproval(payload) {
  return request('/api/contracts/connectGiftCourseAndCreditFromApproval', {
    method: 'put',
    data: payload,
  });
}

export async function createTask(payload) {
  return request('/api/educationalResearchTasks', {
    method: 'post',
    data: payload,
  });
}

export async function updateTask(payload) {
  return request('/api/educationalResearchTasks', {
    method: 'put',
    data: payload,
  });
}

export async function getLessonsDurationAndWordCountByLessons(params) {
  return request('/api/lessons/getLessonsDurationAndWordCountByLessons', {
    method: 'get',
    params,
  });
}
