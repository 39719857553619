import TeamCategories from '@/utils/TeamCategories';
import { message } from 'antd';
import dayjs from 'dayjs';
import lodash from 'lodash';
import {
  getTeamMembers,
  getTeamMembersLessons,
} from '../../DashboardWorkplace/components/OperationAdmin/service';
import {
  createTeam,
  deleteTeam,
  fetchSalesChannelsByUsers,
  getTeamsByCategory,
  getUsersForTeam,
  updateTeam,
} from './service';

const Model = {
  namespace: 'teamPage',
  state: {
    tableLoading: false,
    selectedCategories: [],
    teams: [],
    formVisible: false,
    detailsVisible: false,
    detailsLoading: false,
    teamForm: {},
    submitFormLoading: false,
    teamMembers: [],
    teamOwners: [],
    //销售团队渠道
    salesChannels: [],
    categoryOptions: Object.values(TeamCategories),
    teamDetails: [],
    selectedTeam: null,
    dates: [dayjs().tz('Asia/Shanghai').startOf('day'), dayjs().tz('Asia/Shanghai').endOf('day')],
  },

  effects: {
    *fetchTeams({ payload }, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: { tableLoading: true },
      });

      const categories = yield select((state) => state.teamPage.selectedCategories);

      if (!categories.length) {
        yield put({
          type: 'updateState',
          payload: {
            teams: [],
            tableLoading: false,
          },
        });

        return null;
      }

      const params = {
        categories,
      };

      const teams = yield call(getTeamsByCategory, params);

      if (Array.isArray(teams)) {
        yield put({
          type: 'updateState',
          payload: {
            teams,
          },
        });
      }

      yield put({
        type: 'updateState',
        payload: { tableLoading: false },
      });

      return null;
    },

    *onFetchTeamDetails({ payload }, { put, call, select }) {
      const dates = yield select((state) => state.teamPage.dates);
      const selectedTeam = yield select((state) => state.teamPage.selectedTeam);
      const { team } = payload;

      yield put({
        type: 'updateState',
        payload: {
          detailsVisible: true,
          detailsLoading: true,
        },
      });

      if (team) {
        yield put({
          type: 'updateState',
          payload: {
            selectedTeam: team,
          },
        });
      }

      const teamId = team?.id || selectedTeam?.id;
      const category = team?.category || selectedTeam?.category;

      const timeZone = 'Asia/Shanghai';

      const params = {
        teamId,
        category,
        startAt: dates[0].startOf('month').tz(timeZone).toISOString(),
        endAt: dates[1].endOf('month').tz(timeZone).toISOString(),
      };

      const members = yield call(getTeamMembers, params);

      if (members?.length) {
        const membersLessons = yield call(getTeamMembersLessons, {
          userIds: members.map((m) => m.key),
          startAt: dates[0].startOf('month').tz(timeZone).toISOString(),
          endAt: dates[1].endOf('month').tz(timeZone).toISOString(),
        });

        if (membersLessons?.length) {
          members.forEach((m) => {
            const match = membersLessons.find((user) => user.id === m.key);

            if (match?.operatingLessons) {
              m.operatingLessons = match.operatingLessons;
            }

            if (match?.teachingLessons) {
              m.teachingLessons = match.teachingLessons;
            }
          });
        }
      }

      yield put({
        type: 'updateState',
        payload: {
          teamDetails: members,
          detailsLoading: false,
        },
      });
    },

    *onDetailsViewClose({ payload }, { put }) {
      yield put({
        type: 'updateState',
        payload: {
          detailsLoading: false,
          detailsVisible: false,
          teamDetails: [],
          selectedTeam: null,
          dates: [
            dayjs().tz('Asia/Shanghai').startOf('day'),
            dayjs().tz('Asia/Shanghai').endOf('day'),
          ],
        },
      });
    },

    *onCategoryChange({ payload }, { put }) {
      const { values } = payload;

      yield put({
        type: 'updateState',
        payload: {
          selectedCategories: values,
        },
      });

      yield put({
        type: 'fetchTeams',
      });
    },

    *onSelectAllChange({ payload }, { put, select }) {
      const categories = yield select((state) => state.teamPage.selectedCategories);
      const options = yield select((state) => state.teamPage.categoryOptions);

      if (categories.length) {
        yield put({
          type: 'updateState',
          payload: {
            selectedCategories: [],
            teams: [],
          },
        });
      } else {
        yield put({
          type: 'updateState',
          payload: {
            selectedCategories: options.map((option) => option.value),
          },
        });

        yield put({
          type: 'fetchTeams',
        });
      }
    },

    *onTeamCreate({ payload }, { put }) {
      yield put({
        type: 'updateState',
        payload: {
          teamForm: {},
          formVisible: true,
        },
      });
    },

    *onTeamDelete({ payload }, { put, call }) {
      const { id } = payload;

      const params = {
        id,
      };

      const res = yield call(deleteTeam, params);

      if (res.status === 200) {
        message.success({
          content: '删除团队成功。',
          duration: 5,
        });

        yield put({
          type: 'fetchTeams',
        });
      }
    },

    *onTeamFormEditCancel({ payload }, { put }) {
      yield put({
        type: 'updateState',
        payload: {
          teamForm: {},
          formVisible: false,
          teamOwners: [],
          teamMembers: [],
          submitFormLoading: false,
        },
      });
    },

    *onTeamUpdate({ payload }, { put }) {
      const { team } = payload;

      yield put({
        type: 'updateState',
        payload: {
          teamForm: {
            ...team,
            owners: team.owners?.map((owner) => owner.id),
            members: team.members?.map((member) => member.id),
            //增加销售团队的渠道
            salesChannels: team.salesChannels?.map((channel) => channel.id),
          },
          formVisible: true,
        },
      });

      if (team.category === 'operation') {
        yield put({
          type: 'getUserOptionsForOperationTeam',
          payload: {
            owners: team.owners,
            members: team.members,
          },
        });
      }

      if (team.category === 'sales') {
        yield put({
          type: 'getUserOptionsForSalesTeam',
          payload: {
            owners: team.owners,
            members: team.members,
          },
        });
      }

      if (team.salesChannels.length > 0) {
        //将team的owners和members合并成一个数组，只有id

        yield put({
          type: 'fetchSalesChannelsForSalesTeam',
          payload: {
            teamMembers: [
              ...team.owners?.map((owner) => owner.id),
              ...team.members?.map((member) => member.id),
            ],
          },
        });
      }
    },

    *onTeamFormChange({ payload }, { put, select }) {
      const teamForm = yield select((state) => state.teamPage.teamForm);
      //如果是销售团队，组长只有一位
      if (teamForm.category === 'sales' && payload.owners) {
        //如果组长的id不是一个数组，放入数组中
        if (!Array.isArray(payload.owners)) {
          payload.owners = [payload.owners];
        }
      }

      if (teamForm.goalTimeRange) {
        payload.goalTimeRange = [...teamForm.goalTimeRange, ...payload.goalTimeRange];
      }

      if (teamForm.goalAmount) {
        payload.goalAmount = [...teamForm.goalAmount, ...payload.goalAmount];
      }

      yield put({
        type: 'updateState',
        payload: {
          teamForm: {
            ...teamForm,
            ...payload,
          },
        },
      });

      if (payload.category === 'operation') {
        yield put({
          type: 'getUserOptionsForOperationTeam',
          payload: {
            owners: teamForm.owners,
            members: teamForm.members,
          },
        });
      }

      //销售团队
      if (payload.category === 'sales') {
        yield put({
          type: 'getUserOptionsForSalesTeam',
          payload: {
            owners: teamForm.owners,
            members: teamForm.members,
          },
        });
      }
      if (teamForm.category === 'sales' && teamForm.owners && teamForm.members) {
        yield put({
          type: 'fetchSalesChannelsForSalesTeam',
          payload: {
            teamMembers: [...teamForm.owners, ...teamForm.members],
          },
        });
      }
    },

    *onTeamFormSubmit({ payload }, { put, select, call }) {
      yield put({
        type: 'updateState',
        payload: { submitFormLoading: true },
      });

      const team = yield select((state) => state.teamPage.teamForm);

      const params = { ...team };

      try {
        let res;
        if (team.id) {
          res = yield call(updateTeam, params);
        } else {
          res = yield call(createTeam, params);
        }

        if (!res?.id) {
          yield put({
            type: 'updateState',
            payload: { submitFormLoading: false },
          });

          return message.warn({
            content: '该团队名字已被使用。',
            duration: 5,
          });
        }

        message.success({
          content: `${team.id ? '更新' : '创建'}团队成功。`,
          duration: 5,
        });

        yield put({
          type: 'onTeamFormEditCancel',
        });

        yield put({
          type: 'fetchTeams',
        });
      } catch (err) {
        console.log('submit err', err);
      }

      return yield put({
        type: 'updateState',
        payload: { submitFormLoading: false },
      });
    },

    *getUserOptionsForOperationTeam({ payload }, { put, call }) {
      const { owners, members } = payload;

      const params = {
        ...TeamCategories.operation,
      };

      const res = yield call(getUsersForTeam, params);

      const teamOwners = res.validOwners;
      // const teamMembers = res.validMembers;
      const teamMembers = lodash.uniqBy([...res.validOwners, ...res.validMembers], 'id');

      if (owners?.length) {
        owners.forEach((owner) => {
          if (!teamOwners.find((to) => to.id === owner.id)) {
            teamOwners.push(owner);
          }
        });
      }

      if (members?.length) {
        members.forEach((member) => {
          if (!teamMembers.find((to) => to.id === member.id)) {
            teamMembers.push(member);
          }
        });
      }

      yield put({
        type: 'updateState',
        payload: {
          teamOwners,
          teamMembers,
        },
      });
    },

    *getUserOptionsForSalesTeam({ payload }, { put, call }) {
      const { owners, members } = payload;
      const params = {
        ...TeamCategories.sales,
      };

      const res = yield call(getUsersForTeam, params);

      //合并res.validOwners 和 res.validMembers 为一个数组，去重
      const teamOwners = lodash.uniqBy([...res.validOwners, ...res.validMembers], 'id');
      const teamMembers = lodash.uniqBy([...res.validOwners, ...res.validMembers], 'id');

      if (owners?.length) {
        owners.forEach((owner) => {
          if (!teamOwners.find((to) => to.id === owner.id)) {
            teamOwners.push(owner);
          }
        });
      }

      if (members?.length) {
        members.forEach((member) => {
          if (!teamMembers.find((to) => to.id === member.id)) {
            teamMembers.push(member);
          }
        });
      }

      yield put({
        type: 'updateState',
        payload: {
          teamOwners,
          teamMembers,
        },
      });
    },

    *fetchSalesChannelsForSalesTeam({ payload }, { put, call }) {
      const { teamMembers } = payload;
      const params = {
        teamMembers,
      };
      const res = yield call(fetchSalesChannelsByUsers, params);
      yield put({
        type: 'updateState',
        payload: {
          salesChannels: res,
        },
      });
    },

    // *statusChange({ payload }, { call, put }) {
    //   const response = yield call(update, payload);
    //   yield put({
    //     type: 'fetch',
    //   });
    // },
  },

  reducers: {
    updateState(state, action) {
      return { ...state, ...action.payload };
    },
  },
};
export default Model;
