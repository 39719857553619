import { query as queryOrganizations } from '../../../services/organization';

const Model = {
  namespace: 'consultant',
  state: {
    modalVisible: false,
    current: {},
    organizations: [],
    organizationEditable: true,
  },
  effects: {
    *loadOrganizations({ payload = {} }, { call, put }) {
      if (payload.organization) {
        yield put({
          type: 'save',
          payload: {
            current: {
              organization: payload.organization.id,
            },
            organizations: [payload.organization],
            organizationEditable: false,
          },
        });
      } else {
        const response = yield call(queryOrganizations);
        yield put({
          type: 'save',
          payload: {
            organizations: response,
            organizationEditable: true,
          },
        });
      }
    },
  },

  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },

    showModal(state) {
      return { ...state, modalVisible: true };
    },

    hideModal(state) {
      return {
        ...state,
        modalVisible: false,
        current: {},
        organizations: [],
        organizationEditable: true,
      };
    },
  },
};
export default Model;
