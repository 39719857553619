import { MoonIcon, SunIcon } from '@/components/Icons';
import { useModel } from '@umijs/max';
import { Button } from 'antd';

export const SelectTheme = () => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const currentTheme = initialState?.settings?.navTheme || 'light';
  return (
    <Button
      icon={currentTheme === 'realDark' ? <MoonIcon /> : <SunIcon />}
      onClick={() => {
        const navTheme = currentTheme === 'light' ? 'realDark' : 'light';
        window.localStorage.setItem('navTheme', navTheme);
        setInitialState((preInitialState) => ({
          ...preInitialState,
          settings: {
            ...preInitialState.settings,
            navTheme,
            logo:
              navTheme === 'realDark'
                ? 'https://savvyuni-public.oss-accelerate.aliyuncs.com/logos/savvygroup/SavvyGroup%20Logo%20White.svg'
                : 'https://savvyuni-public.oss-accelerate.aliyuncs.com/logos/savvygroup/SavvyGroup%20Logo.svg',
          },
        }));
      }}
    />
  );
};
