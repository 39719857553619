const UserModel = {
  namespace: 'user',
  state: {
    currentUser: {},
    currentUserRoles: [],
    permissions: {},
    teamMembers: [],
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },

    logout(state, action) {
      window.localStorage.removeItem('token');
      window.location.href = '/login';
      return {
        currentUser: {},
        currentUserRoles: [],
        permissions: {},
        teamMembers: [],
      };
    },
  },
};
export default UserModel;
