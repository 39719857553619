import { getInProgressSubsidy } from '@/components/Salary/service';
import { getAllComplaints } from '@/pages/Complaint/service.js';
import { message, Modal } from 'antd';
import dayjs from 'dayjs';
import { downloadData } from '../../pages/DataCenter/DataDownload/service';
import {
  create as createAttendance,
  query as queryAttendance,
  queryTodayLessons,
} from '../../services/attendance';
import { query as queryContracts } from '../Contract/service';
import { querySalesAgenda } from './service';

const Model = {
  namespace: 'dashboardWorkplace',
  state: {
    currentUser: undefined,
    projectNotice: [],
    activities: [],
    radarData: [],
    dates: [dayjs().startOf('day').toDate(), dayjs().add(15, 'days').endOf('day').toDate()],
    lessons: [],
    lessonPanelKey: [],
    attendances: [],
    contracts: [],
    usageDates: [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()],
    usageLessons: [],
    usageLessonsLoading: false,
    currentItem: {},
    uploadMaterialVisible: false,
    previewMaterialVisible: false,
    currentMaterials: [],
    selectedStudent: [],
    complaintsCounter: 0,
    subsidyCounter: 0,
  },
  effects: {
    *init(_, { put }) {
      yield put({
        type: 'fetchSalesAgenda',
        payload: {
          endAt: dayjs().add(1, 'day').endOf('day').format('YYYY-MM-DD'),
        },
      });

      yield put({
        type: 'onLessonDateChange',
        payload: {
          dates: [dayjs().startOf('day').toDate(), dayjs().add(15, 'days').endOf('day').toDate()],
          initial: true,
        },
      });

      yield put({
        type: 'onLessonDateChange',
        payload: {
          dates: [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()],
          usage: true,
          initial: true,
        },
      });

      yield put({
        type: 'fetchComplaints',
      });

      yield put({
        type: 'fetchInProgressSubsidy',
      });
    },

    *fetchSalesAgenda({ payload }, { call, put, select }) {
      const user = yield select((state) => state.user.currentUser);
      if (
        !user.roles ||
        !user.roles.find((r) => {
          return r === 'sales' || (r.name && r.name === 'sales');
        })
      )
        return;
      const response = yield call(querySalesAgenda, payload);
      const today = [];
      const past = [];
      if (response && response.length) {
        const startOfToday = new Date(dayjs().startOf('day').format('YYYY-MM-DD'));
        response.forEach((r) => {
          const time = new Date(r.scheduledTime);
          if (time < startOfToday) {
            past.push(r);
          } else {
            today.push(r);
          }
        });
      }
      yield put({
        type: 'save',
        payload: {
          salesAgendaToday: today.length,
          salesAgendaPast: past.length,
        },
      });
    },

    *fetchStudents({ payload }, { call, put, select }) {
      const user = yield select((state) => state.user.currentUser);
      const response = yield queryContracts({
        headTeacher: user.id,
      });

      if (response.contracts) {
        const { contracts } = response;
        yield put({
          type: 'save',
          payload: {
            contracts,
          },
        });
      }
    },

    *fetchComplaints({ payload }, { call, put, select }) {
      const complaintsCounter = yield select((state) => state.complaintsCounter);
      const response = yield getAllComplaints({ processingStatus: false });
      if (complaintsCounter !== response.length) {
        yield put({
          type: 'save',
          payload: {
            complaintsCounter: response.length,
          },
        });
      }
    },

    *fetchInProgressSubsidy({ payload }, { call, put, select }) {
      const subsidyCounter = yield select((state) => state.subsidyCounter);
      const permissions = yield select((state) => state.user.permissions);
      const user = yield select((state) => state.user.currentUser);
      let response;
      if (
        permissions &&
        permissions.salary &&
        permissions.salary.includes('query-subsidy') &&
        permissions.salary.length === 1
      ) {
        response = yield getInProgressSubsidy({ createdById: user.id });
      } else {
        response = yield getInProgressSubsidy();
      }
      if (subsidyCounter !== response) {
        yield put({
          type: 'save',
          payload: {
            subsidyCounter: response,
          },
        });
      }
    },

    *onLessonDateChange({ payload }, { call, put, select }) {
      if (payload.usage) {
        yield put({
          type: 'save',
          payload: {
            usageDates: payload.dates,
          },
        });

        if (payload.initial) {
          yield put({
            type: 'fetchUsageLessons',
          });
        }
      } else {
        yield put({
          type: 'save',
          payload: {
            dates: payload.dates,
          },
        });

        if (payload.initial) {
          yield put({
            type: 'fetchTodayLessons',
          });

          yield put({
            type: 'fetchTodayAttendances',
          });
        }
      }
    },

    *fetchTodayLessons(_, { call, put, select }) {
      const user = yield select((state) => state.user.currentUser);
      let dates = yield select((state) => state.dashboardWorkplace.dates);
      if (dates.length === 2) {
        dates = [dates[0].toISOString(), dates[1].toISOString()];
      }

      const lessons = yield queryTodayLessons({
        user: user.id,
        dates,
      });

      yield put({
        type: 'save',
        payload: {
          lessons,
          lessonPanelKey: ['1'],
        },
      });
    },

    *fetchTodayAttendances({ payload }, { call, put, select }) {
      const user = yield select((state) => state.user.currentUser);
      let dates = yield select((state) => state.dashboardWorkplace.dates);
      if (dates.length === 2) {
        dates = [dates[0].toISOString(), dates[1].toISOString()];
      }

      const attendances = yield queryAttendance({
        dates,
        user: user.id,
      });

      yield put({
        type: 'save',
        payload: {
          attendances,
        },
      });
    },

    *fetchUsageLessons(_, { call, put, select }) {
      const user = yield select((state) => state.user.currentUser);
      let dates = yield select((state) => state.dashboardWorkplace.usageDates);
      if (dates.length === 2) {
        dates = [dates[0].toISOString(), dates[1].toISOString()];
      }

      yield put({
        type: 'save',
        payload: {
          usageLessonsLoading: true,
        },
      });

      const roles = user.roles.map((elem) => elem.name);
      const usageLessons = yield queryTodayLessons({
        user: user.id,
        dates,
        usage: true,
        roles,
      });

      yield put({
        type: 'save',
        payload: {
          usageLessons,
          usageLessonsLoading: false,
        },
      });
    },

    *createAttendance({ payload }, { call, put, select }) {
      const user = yield select((state) => state.user.currentUser);
      const attendance = yield createAttendance({
        ...payload,
        user: user.id,
      });

      if (attendance) {
        Modal.success({
          title: '签到成功',
          content:
            '请老师下课后根据真实上课时长在群内反馈，并在课后第一时间发送课程反馈、课程课件以及课程笔记。',
        });
      }

      yield put({
        type: 'fetchTodayAttendances',
      });
    },

    *exportLessonConsumptionTable({ payload }, { call, select }) {
      message.loading({
        key: '课消表单下载',
        content: '正在下载课消表单...',
        duration: 0,
      });

      const { data } = payload;

      const user = yield select((state) => state.user.currentUser);
      const dates = yield select((state) => state.dashboardWorkplace.usageDates);

      const params = {
        content: '课消表单',
        fileType: 'xlsx',
        data: {
          user: user.id,
          dates: [dates[0].toISOString(), dates[1].toISOString()],
          usage: true,
          roles: user.roles.map((elem) => elem.name),
          lessonIds: data.map((item) => item.id),
        },
      };

      const res = yield call(downloadData, params);

      if (res?.status === 200) {
        const tempLink = document.createElement('a');
        tempLink.href = res.data;
        tempLink.click();
        tempLink.remove();

        message.success({
          key: '课消表单下载',
          content: '获取课消表单文件成功。',
          duration: 3,
        });
      }
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },

    clear() {
      return {
        currentUser: undefined,
        projectNotice: [],
        activities: [],
        radarData: [],
        dates: [dayjs().startOf('day').toDate(), dayjs().add(15, 'days').endOf('day').toDate()],
        lessons: [],
        lessonPanelKey: [],
        attendances: [],
        contracts: [],
        usageDates: [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()],
        usageLessons: [],
        usageLessonsLoading: false,
        currentItem: {},
        uploadMaterialVisible: false,
        previewMaterialVisible: false,
        currentMaterials: [],
        selectedStudent: [],
        complaintsCounter: 0,
        subsidyCounter: 0,
      };
    },
  },
};
export default Model;
