import { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

export const useDebounceState = (init, queryFn, timer = 1000) => {
  const [value, setValue] = useState(init);
  const setDebounce = useCallback(
    debounce((params) => {
      queryFn(params).then((result) => {
        if (Array.isArray(result)) {
          if (result.length === 0) {
            setValue([]);
          } else {
            setValue(result.map((el) => el.name));
          }
        } else {
          setValue(result);
        }
      });
    }, timer),
    [queryFn, timer],
  );
  return [value, setDebounce];
};
