import { query as querySchool } from '../School/service';
import { getSemesterCourse4Students } from './Profile/service';
import { afterQuery, checkDuplicates, createStudentService, query } from './service';

const StudentModel = {
  namespace: 'student',
  state: {
    // create student
    onCreate: false,
    createdStudent: null,
    uploadedBackgroundFiles: [],
    createSubmitButtonLoading: false,
    existPhone: false,
    schoolData: [],
    selectedSchool: null,
    searchSchoolValue: null,
    searchSchoolLoading: false,

    // student main page
    fullStudentsData: false,
    searchValue: '',
    showOwnedStudentOnly: false,
    contractStatusFilter: '任意合同状态',
    createStudentSuccess: false,
    studentsData: [],
    studentsDataLoading: false,
    studentsDataTotal: 0,
    page: 1,
    pageSize: 10,
  },

  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ location }) => {
        if (location.pathname === '/student') {
          dispatch({
            type: 'updateState',
            payload: {
              searchValue: '',
            },
          });
        }
      });
    },
  },

  effects: {
    *create({ payload }, { call, put }) {
      const response = yield call(createStudentService, payload);

      if (response && response.firstName && response.lastName) {
        yield put({
          type: 'updateState',
          payload: {
            createSubmitButtonLoading: false,
            createdStudent: response,
          },
        });

        yield put({
          type: 'query',
        });
      }
    },

    *getStudentByPhone({ payload }, { call, put }) {
      const response = yield call(checkDuplicates, payload);

      if (response[0]) {
        yield put({
          type: 'updateState',
          payload: {
            existPhone: true,
          },
        });
      }
    },

    *onSearchChange({ payload }, { put }) {
      const { searchValue, showOwnedStudentOnly, contractStatusFilter } = payload;

      yield put({
        type: 'updateState',
        payload: {
          searchValue,
          showOwnedStudentOnly,
          contractStatusFilter,
          page: 1,
        },
      });

      yield put({
        type: 'query',
      });
    },

    *query(_, { call, put, select }) {
      const searchValue = yield select((state) => state.student.searchValue);
      const showOwnedStudentOnly = yield select((state) => state.student.showOwnedStudentOnly);
      const contractStatusFilter = yield select((state) => state.student.contractStatusFilter);
      const page = yield select((state) => state.student.page);
      const pageSize = yield select((state) => state.student.pageSize);

      yield put({
        type: 'updateState',
        payload: {
          studentsData: [],
          studentsDataLoading: true,
        },
      });

      const params = {
        searchValue: searchValue?.trim(),
        showOwnedStudentOnly: showOwnedStudentOnly ? true : null,
        contractStatusFilter,
        pageSize,
        page,
      };
      const response = yield call(query, params);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            studentsData: response.students,
            studentsDataTotal: response.total,
            studentsDataLoading: false,
            fullStudentsData: false,
          },
        });
      }

      // get full data
      if (response.total && response.total > 0) {
        let studentIds = [];
        for (let s of response.students) {
          studentIds.push(s.id);
        }
        const studentsWithContractData = yield call(afterQuery, {
          studentIds,
        });
        const studentSemesterCourse = yield getSemesterCourse4Students({ studentIds });
        const fullData = response.students.map((s) => {
          const match = studentsWithContractData.find((el) => el.id === s.id) || {};
          const matchSemesterCourse = studentSemesterCourse.find((el) => el.id === s.id) || {};
          return {
            ...s,
            ...match,
            ...matchSemesterCourse,
          };
        });
        yield put({
          type: 'updateState',
          payload: {
            studentsData: fullData,
            fullStudentsData: true,
          },
        });
      }
    },

    *onPageChange({ payload }, { put }) {
      const { page, pageSize } = payload;

      yield put({
        type: 'updateState',
        payload: {
          page,
          pageSize,
        },
      });

      yield put({
        type: 'query',
      });
    },

    *querySchool({ payload }, { call, put }) {
      const { searchValue } = payload;

      const params = {
        searchValue,
        mode: 'basic',
      };

      const response = yield call(querySchool, params);

      if (response) {
        yield put({
          type: 'updateState',
          payload: {
            schoolData: response.schools,
            // searchSchoolLoading: false,
          },
        });
      }
    },
  },

  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    init(state, { payload }) {
      return {
        ...state,
        onCreate: true,
        createdStudent: null,
        createSubmitButtonLoading: false,
      };
    },
    end(state, { payload }) {
      return {
        ...state,
        onCreate: false,
        createdStudent: null,
        createSubmitButtonLoading: false,
      };
    },
  },
};

export default StudentModel;
