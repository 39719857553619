import component from './en-US/component';
import dashboard from './en-US/dashboard';
import globalHeader from './en-US/globalHeader';
import menu from './en-US/menu';
import pwa from './en-US/pwa';
import settingDrawer from './en-US/settingDrawer';
import settings from './en-US/settings';
import userProfile from './en-US/userProfile';

export default {
  lastName: 'Last Name',
  firstName: 'First Name',
  chineseName: 'Chinese Name',
  nickname: 'Nickname',
  username: 'Username',
  password: 'Password',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  nationality: 'Nationality',
  mobile: 'Mobile',
  areaCode: 'Area Code',
  phone: 'Phone',
  wechat: 'Wechat',
  wechatId: 'Wechat ID',
  email: 'Email',
  role: 'Role',
  create: 'Create',
  update: 'Update',
  submit: 'Submit',
  cancel: 'Cancel',
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.preview.down.block': 'Download this page to your local project',
  'app.welcome.link.fetch-blocks': 'Get all block',
  'app.welcome.link.block-list': 'Quickly build standard, pages based on `block` development',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...userProfile,
  ...dashboard,
};
