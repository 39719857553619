import dayjs from 'dayjs';
import { parse } from 'querystring';
/* eslint no-useless-escape:0 import/prefer-default-export:0 */

const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;
export const isUrl = (path) => reg.test(path);

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export const formatFloatDuration = (floatDuration) => {
  let hour = 0;
  let minute = 0;

  if (typeof floatDuration === 'number' && floatDuration > 0) {
    hour = Math.floor(floatDuration);
    minute = Math.floor(floatDuration * 60) % 60;
  }

  return `${hour} 小时 ${minute} 分钟`;
};
export const removeDuplicates = (array, key = 'id') => {
  // if array element doesn't have prop key, it will not be add into the return array
  const x = {};
  const newArray = [];
  array.forEach((elem) => {
    if (elem?.[key] && !x[elem[key]]) {
      x[elem[key]] = true;
      newArray.push(elem);
    }
  });
  return newArray;
};

export const hasCommonValueInArrays = (arr1, arr2) => {
  if (!arr1 || !arr1.length || !arr2 || !arr2.length) return false;

  const commonValue = arr1.find((item1) => arr2.includes(item1));

  return !!commonValue;
};

//秒数转换为分秒
export const formatSecondToMinuteSecond = (data) => {
  let value = data;
  if (typeof data !== 'number') {
    value = Number(data) || 0;
  }
  const minute = Math.floor(value / 60);
  const second = Math.round(value % 60);
  let text = '';
  if (minute > 0) {
    text += `${minute}分`;
  }
  text += `${second}秒`;
  return text;
};

export const formatMinuteIntToHourMinuteText = (data) => {
  let value = data;
  if (typeof data !== 'number') {
    value = Number(data) || 0;
  }
  const hour = Math.floor(value / 60);
  const minute = Math.round(value % 60);
  let text = '';
  if (hour > 0) {
    text += `${hour}小时`;
  }
  text += `${minute}分钟`;
  return text;
};

export const converHourFloatToMinuteInt = (hourFloat) => {
  if (!hourFloat || typeof hourFloat !== 'number' || hourFloat < 0) return 0;

  let minuteInt;

  const minuteNumber = hourFloat * 60;
  const minuteString = minuteNumber.toString();

  if (minuteString.includes('.')) {
    const minuteDecimalString = minuteString.split('.')[1];
    if (Number(minuteDecimalString[0]) < 5) {
      minuteInt = Math.floor(minuteNumber);
    }

    if (Number(minuteDecimalString[0]) >= 5) {
      minuteInt = Math.round(minuteNumber);
    }
  } else {
    minuteInt = minuteNumber;
  }

  return minuteInt;
};

export const formatUTCTimeToString = (utcTime, timeZone) => {
  const tz = timeZone || 'Asia/Shanghai';
  return dayjs(utcTime).tz(tz).format('YY年MM月DD日 hh:mm ');
};

export function getWeeksInDuration(startAt, endAt) {
  const weeks = [];
  const firstDate = dayjs(startAt);
  const lastDate = dayjs(endAt);

  let start = firstDate.startOf('week');
  const end = lastDate.endOf('week');

  while (start.diff(end, 'days') < 0) {
    const startString = start.format();
    const weekEnd = dayjs(startString).add(1, 'week').subtract(1, 'second');
    weeks.push({
      start: start.unix(),
      end: weekEnd.unix(),
      label: `${start.get('month') + 1}/${start.get('date')}-${
        weekEnd.get('month') + 1
      }/${weekEnd.get('date')}`,
    });
    start = start.add(1, 'week');
  }
  return weeks;
}

export function getMonthsInDuration(startAt, endAt) {
  const months = [];
  const firstDate = dayjs(startAt);
  const lastDate = dayjs(endAt);

  let start = firstDate.startOf('month');
  const end = lastDate.endOf('month');

  while (start.diff(end, 'days') < 0) {
    const startString = start.format();
    const monthEnd = dayjs(startString).add(1, 'month').subtract(1, 'second');
    months.push({
      start: start.unix(),
      end: monthEnd.unix(),
      label: `${start.get('month') + 1}月`,
    });
    start = start.add(1, 'month');
  }
  return months;
}

export function dateRangeInTimezone(dateArr = [], timezone) {
  let timeRange = [];
  if (timezone) {
    const start = dateArr[0];
    const end = dateArr[1];
    if (start && end) {
      const startDate = dayjs
        .tz(dayjs(start).format('YYYY-MM-DD'), timezone)
        .startOf('day')
        .utc()
        .format();
      const endDate = dayjs
        .tz(dayjs(end).format('YYYY-MM-DD'), timezone)
        .endOf('day')
        .utc()
        .format();
      timeRange = [startDate, endDate];
    }
  } else {
    timeRange = dateArr;
  }
  return timeRange;
}

export function capitalizeFirstLetter(text) {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function daysToYearMonthDayObject(days) {
  let num = days;
  if (typeof days === 'string') {
    num = Number(days);
  }
  const monthNday = num % 365;
  const year = (num - monthNday) / 365;
  const day = monthNday % 30;
  const month = (monthNday - day) / 30;

  return {
    year,
    month,
    day,
  };
}

export function daysToYearMonthDayString(days) {
  const obj = daysToYearMonthDayObject(days);
  let text = '';
  if (obj.year > 0) {
    text += `${obj.year}年`;
  }
  if (obj.month > 0) {
    text += `${obj.month}个月`;
  }
  if (obj.day > 0) {
    text += `${obj.day}天`;
  }
  return text;
}

//把分钟转化为xx.xx小时
export function formatMinuteToHour(minute) {
  if (!minute) return 0;
  const hour = minute / 60;
  return hour.toFixed(2);
}

export function getErrorMessageFromResponse(e) {
  let text = '操作失败';
  let error = e;
  if (e.response) {
    error = e.response;
  }
  if (error.message) {
    text = error.message;
  }
  if (error.data) {
    if (typeof error.data === 'string') {
      text = error.data;
    } else {
      if (error.data.message) {
        text = error.data.message;
      } else if (error.data.error) {
        if (typeof error.data.error === 'string') {
          text = error.data.error;
        } else if (error.data.error.message) {
          text = error.data.error.message;
        }
      }
    }
  }
  return text;
}

export function getUsername(user) {
  if (!user) return 'N/A';
  let username = 'N/A';
  if (user.fullName) {
    username = user.fullName;
  } else if (user.chineseName) {
    username = user.chineseName;
  } else if (user.firstName && user.lastName) {
    username = `${user.lastName}${user.firstName}`;
  } else if (user.nickname) {
    username = user.nickname;
  } else if (user.name) {
    username = user.name;
  } else if (user.username) {
    username = user.username;
  }
  if (!!user.deletedAt || user.teacherStatus === 'resigned') {
    username = username + '(已离职)';
  }
  return username;
}

//TODO:判断是否是全职教师

export function isFullTimeTeacher(user) {
  if (!user) return false;
  return user?.isFullTimeTeacher ? true : false;
}
