import { request } from '@@/plugin-request';

export async function getSalaryHistory(params) {
  return request('/api/finance/salaryHistory', {
    params,
  });
}

export async function updateSalaryHistory(params) {
  return request('/api/finance/salaryHistory', {
    method: 'put',
    data: params,
  });
}

export async function updateLessonSalary(params) {
  return request('/api/finance/lesson', {
    method: 'put',
    data: params,
  });
}

export async function createSalaryHistory(params) {
  return request('/api/finance', {
    method: 'post',
    data: params,
  });
}

export async function exportSalaryHistory(params) {
  return request('/api/finance/exportSalary', {
    params,
  });
}

export async function getCumulativeSalary(params) {
  return request('/api/finance/getCumulativeSalary', {
    params,
  });
}

export async function querySalaryData(params) {
  return request('/api/finance/querySalaryData', {
    params,
  });
}

export async function getLessons4Salary(params) {
  return request('/api/lessons/salary', {
    method: 'GET',
    params,
  });
}
