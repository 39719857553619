import { request } from '@@/plugin-request';

export async function getUploadParams(params) {
  return request('/api/materials/upload', {
    method: 'GET',
    params,
  });
}

export async function download(data) {
  return request('/api/materials/download', {
    method: 'POST',
    data,
  });
}
