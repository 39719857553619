import { frequentlyUsedTimezones } from '@/utils/tz';
import { DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const CustomDatePicker = ({
  value,
  onChange,
  timezoneFieldProps = {},
  datepickerFieldProps = {},
  ...rest
}) => {
  // const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  // const [time, setTime] = useState(
  //   value ? dayjs(value).tz(Intl.DateTimeFormat().resolvedOptions().timeZone) : null,
  // );

  const [timezone, setTimezone] = useState();
  const [time, setTime] = useState();

  useEffect(() => {
    setTimezone(rest.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone);
    setTime(
      value
        ? dayjs(value).tz(rest.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone)
        : null,
    );
  }, [rest.timeZone]);

  const onTZChange = (tz) => {
    setTimezone(tz);
    if (rest.onTimezoneChange) {
      rest.onTimezoneChange(tz);
    }
    if (time) {
      const timeConverted = dayjs.tz(time.format('YYYY-MM-DD HH:mm'), tz).utc();
      onChange(timeConverted.toISOString());
    }
  };
  const onTimeChange = (t) => {
    setTime(t);
    if (t) {
      const timeConverted = dayjs.tz(t.format('YYYY-MM-DD HH:mm'), timezone).utc();
      onChange(timeConverted.toISOString());
    } else {
      onChange();
    }
  };
  return (
    <Input.Group compact>
      <Select
        showSearch
        filterOption={(input, option) => {
          if (!input) return false;
          return (
            option?.children?.toLowerCase().includes(input.toLowerCase()) ||
            option?.value?.toLowerCase().includes(input.toLowerCase())
          );
        }}
        placeholder="时区"
        value={timezone}
        onChange={onTZChange}
        style={{
          width: '40%',
        }}
        {...rest}
        {...timezoneFieldProps}
      >
        {frequentlyUsedTimezones.map((elem) => {
          return (
            <Select.Option value={elem.value} key={elem.value}>
              {elem.label}
            </Select.Option>
          );
        })}
      </Select>
      <DatePicker
        value={time}
        onChange={onTimeChange}
        showTime
        format="YYYY-MM-DD HH:mm"
        style={{
          width: '40%',
        }}
        {...rest}
        {...datepickerFieldProps}
      />
    </Input.Group>
  );
};

export default CustomDatePicker;
