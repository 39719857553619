import { notification } from 'antd';
import { pathToRegexp } from 'path-to-regexp';
import { history } from 'umi';
import { queryById } from './service';

const Model = {
  namespace: 'contractEditForm',
  state: {
    mode: 'create',
    contract: {},
  },
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ location }) => {
        const match = pathToRegexp('/contract/edit/:id').exec(location.pathname);
        if (match) {
          const id = match[1];
          if (id) {
            dispatch({
              type: 'getById',
              payload: {
                id,
              },
            });
          }
        }
      });
    },
  },
  effects: {
    *getById({ payload }, { call, put }) {
      yield put({
        type: 'save',
        payload: {
          contract: {},
        },
      });
      const response = yield call(queryById, payload.id);
      if (response) {
        const contract = response.data;
        let canEdit = true;
        if (contract.lessonCount) canEdit = false;
        if (contract.contractStatus !== '未开启服务') canEdit = false;
        if (!canEdit) {
          notification.error({
            message: '违规操作',
            description: '该合同已排课，不可修改！',
          });
          history.push('/contract');
        } else {
          yield put({
            type: 'save',
            payload: {
              contract: response.data,
            },
          });
        }
      }
    },
  },
  reducers: {
    save(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
export default Model;
