import { Modal, Image, Spin, Button, message, FloatButton, Result } from 'antd';
import { generateRelationQrCode } from '../service';
import { useEffect, useState } from 'react';
import { checkConnect } from '@/services/login';
import { WechatOutlined } from '@ant-design/icons';

const ConnectWechatQrCodeModal = () => {
  const [qrCode, setQrCode] = useState('');
  const [isSkip, setIsSkip] = useState(false);
  const [connectModalVisible, setConnectModalVisible] = useState(false);
  const [connectSuccess, setConnectSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchQrCode = async () => {
      setLoading(true);
      //先检查是否已经关联
      const connectSuccess = await checkConnect();
      if (Object.keys(connectSuccess).length > 0 && connectSuccess.state === 'success') {
        setConnectSuccess(true);
        return;
      }
      const res = await generateRelationQrCode();
      if (res.errcode) {
        message.error(res.errmsg);
        setConnectModalVisible(false);
        setIsSkip(true);
      }
      setQrCode(res.qrCode);
      setLoading(false);
    };
    if (connectModalVisible) {
      fetchQrCode();
    }
  }, [connectModalVisible]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (qrCode && !isSkip) {
        checkConnect().then((res) => {
          if (Object.keys(res).length > 0 && res.state === 'success') {
            clearInterval(interval);
            setConnectModalVisible(false);
            setConnectSuccess(true);
            message.success('绑定成功');
          }
        });
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [qrCode, isSkip]);

  return (
    <>
      <FloatButton
        icon={<WechatOutlined />}
        tooltip={<div>关联微信</div>}
        onClick={() => {
          setConnectModalVisible(true);
        }}
      >
        关联微信
      </FloatButton>
      <Modal
        title="关联微信"
        open={connectModalVisible}
        footer={null}
        onCancel={() => {
          setIsSkip(true);
          setConnectModalVisible(false);
        }}
      >
        {connectSuccess ? (
          <Result
            status="success"
            title="您的账户已经关联微信"
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  setConnectModalVisible(false);
                }}
              >
                知道了
              </Button>,
            ]}
          />
        ) : (
          <>
            <strong>扫描二维码关联微信：</strong>
            <div
              style={{
                width: '50%',
                margin: 'auto',
              }}
            >
              {loading ? (
                <Spin size="large" />
              ) : (
                <Image src={qrCode} alt="二维码" preview={<Spin size="large" />} />
              )}
            </div>
            <Button
              type="primary"
              onClick={() => {
                setIsSkip(true);
                setConnectModalVisible(false);
              }}
              style={{
                marginTop: 20,
                marginLeft: '80%',
              }}
            >
              下次绑定
            </Button>
          </>
        )}
      </Modal>
    </>
  );
};

export default ConnectWechatQrCodeModal;
